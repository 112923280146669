import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../asset/img/gdl-logo.png";

const AuthNav = props => (
  <div className="md:bg-transparent bg-transparent flex justify-center items-center shadow">
    <header className="py-2 sm:px-4 flex justify-between sm:w-4/5 w-full">
      <nav className="nav nav-fill flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg text-gray-700 bg-white">
        <div className="left-side flex items-center">
          <div className="logo-div md:flex hidden items-center gap-x-3">
            <span>
              <img src={Logo} className="w-10" />
            </span>
            <span className="font-semibold text-[#992333]">CRM</span>
          </div>
          <div className="humburger-icon">
            <svg xmlns="<http://www.w3.org/2000/svg>" id="menu-button" className="h-6 w-6 cursor-pointer md:hidden block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        </div>
        {/* <div className="right-side flex justify-between">
          <AppButton
            name={props.isRegister ? "Get GDEarn" : "Create An Account"}
            className="rounded-md"
          />
        </div> */}
      </nav>
    </header>
  </div>
);

export default AuthNav;
