export const openModal = (type, payload = null) => ({
  type,
  payload,
});

export const showMessage = (type, ...payload) => ({
  type,
  payload,
});

export const fileUploader = (type, ...payload) => ({
  type,
  payload,
});

export const resetFlow = (type, ...payload) => ({
  type,
  payload,
});

export const setTags = (...payload) => ({
  type: "SET_TAGS",
  payload,
});

export const addToggle = (...payload) => ({
  type: "SET_TOGGLE_INPUT",
  payload,
});

export const setRelatedPost = (...payload) => ({
  type: "SET_RELATED_POSTS",
  payload,
});

export const setNewPostContent = (...payload) => ({
  type: "SET_POST_CONTENT",
  payload,
});

export const clearPostContent = () => ({
  type: "CLEAR_POST_CONTENT",
});

export const logOut = () => ({
  type: "LOGOUT",
  payload: true,
});

export const clearState = () => ({
  type: "CLEAR_STATE",
  payload: true,
});
