import { IoImage, IoTrashOutline } from "react-icons/io5";
import { MediaCardDiv } from "./card.style";

export const MediaCard = () => (
  <MediaCardDiv className="w-full p-[0.5rem] relative cursor-default rounded-[0.5rem] flex flex-col">
    <div className="image flex justify-center items-center">
      <img src="https://res.cloudinary.com/gdlapp/image/upload/v1668617096/image/arrow-right.svg.svg" />
    </div>
    <div className="p-2 mt-2">
      <div className="flex items-center justify-start truncate overflow-ellipsis font-normal text-base">
        <div className="mr-1 flex items-center justify-center icon-img">
          <IoImage />
        </div>
        <span title="arrow-right.svg">arrow-right.svg.svg</span>
      </div>
      <div className="text-black text-opacity-50 truncate overflow-ellipsis">
        368.0B
      </div>
      <div className="text-black text-opacity-50 truncate overflow-ellipsis">
        24x24
      </div>
      <div className="text-black text-opacity-50 truncate overflow-ellipsis">
        7 days ago
      </div>
    </div>
    <a className="delete">
      <IoTrashOutline />
    </a>
  </MediaCardDiv>
);
