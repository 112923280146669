import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ADD_PROFILE, GET_PROFILE } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { profileURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { TextField, TextFieldTag } from "../widget/form/FormComponent";

const ProfileComponent = ({ token, getAction, postAction, tags, name, ...props }) => {
  const { formState, register, control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    getAction(GET_PROFILE, profileURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("name", props.data[0].name);
      setValue("description", props.data[0].description);
      setValue("primary_email", props.data[0].primary_email);
      setValue("hotline", props.data[0].hotline);
      setValue("address", props.data[0].address);
    }
  }, [props.data]);

  const submitHandler = data => {
    data.hotline = JSON.stringify(tags[name]);
    // data.hotline = "+2349099976980";
    console.log(data);
    postAction(ADD_PROFILE, profileURL, data);
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };

  console.log(props.loading);

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(submitHandler)}>
      <TextField label="Name*" name="name" error={formState.errors.name} {...register("name", { required: false })} type="text" />
      <TextField label="Description*" name="description" error={formState.errors.description} {...register("description", { required: false })} type="text" />
      <TextField label="Primary Email*" name="primary_email" error={formState.errors["primary_email"]} {...register("primary_email", { required: false })} type="email" />
      <TextFieldTag label="Hotline*" control={control} name="tags" error={formState.errors.hotline} {...register("hotline", { required: false })} />
      <TextField label="Address*" name="address" error={formState.errors.address} {...register("address", { required: false })} type="text" />
      <div className="flex justify-end pt-5">
        <AppButton name={`Save`} loading={props.loading} type="submit" />
      </div>
    </form>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addProfileLinks.loading,
  data: state.settings.profileLinks.data,
  postData: state.settings.addProfileLinks.data,
  error_msg: state.settings.addProfileLinks.error_msg,
  fetch_error_msg: state.settings.profileLinks.error_msg,
  tags: state.ui.tag,
  name: state.ui.tag.name,
});
export default connect(mapStateToProps, { getAction, postAction })(ProfileComponent);
