import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  ADD_MAINTENANCE_MODE,
  GET_MAINTENANCE_MODE,
} from "../../store/actions/action_types/action.types";
import {
  postAction,
  getAction,
} from "../../store/actions/creators/request.actions";
import { securityMaintenaceURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { TextField, Toggler } from "../widget/form/FormComponent";

const Maintenance = ({ token, getAction, postAction, ...props }) => {
  const { formState, register, handleSubmit, control, setValue } = useForm();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    getAction(GET_MAINTENANCE_MODE, securityMaintenaceURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("turned_off", props.data[0].turned_off);
      setValue("message_title", props.data[0].message_title);
      setValue("description", props.data[0].description);
    }
  }, [props.data]);

  const submitHandler = data => {
    // data.hotline = "+2349099976980";
    console.log(data);
    postAction(ADD_MAINTENANCE_MODE, securityMaintenaceURL, data, token);
  };
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <p className="mb-4">
        If you think the security of your site has been compromised, you can put
        the site on maintenance mode. This will make sure no further damages can
        be done.
      </p>
      <div className="flex flex-col gap-1 mb-6">
        <p className="font-light ">Mode</p>
        <Toggler
          name={"turned_off"}
          label={["Turned Off", "Turned On"]}
          control={control}
        />
      </div>

      <TextField
        label="Message Title"
        name="message_title"
        error={formState.errors["message_title"]}
        {...register("message_title", { required: false })}
        type="text"
      />
      <TextField
        label="Message Description"
        name="description"
        error={formState.errors["description"]}
        {...register("description", { required: false })}
        type="textarea"
      />
      <div className="flex justify-end pt-5">
        <AppButton name={`Save`} type="submit" />
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addMaintenanceMode.loading,
  data: state.settings.maintenanceMode.data,
  postData: state.settings.addMaintenanceMode.data,
  error_msg: state.settings.addMaintenanceMode.error_msg,
  fetch_error_msg: state.settings.maintenanceMode.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(Maintenance);
