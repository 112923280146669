import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  ADD_SOCIAL_ACCOUNT,
  GET_SOCIAL_ACCOUNT,
} from "../../store/actions/action_types/action.types";
import {
  getAction,
  postAction,
} from "../../store/actions/creators/request.actions";
import { smSettingsURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { TextField } from "../widget/form/FormComponent";

const SocialsComponent = ({ token, getAction, postAction, ...props }) => {
  const { formState, register, control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    getAction(GET_SOCIAL_ACCOUNT, smSettingsURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("facebook_url", props.data[0]?.facebook_url);
      setValue("iinkedin_url", props.data[0]?.iinkedin_url);
      setValue("instagram_url", props.data[0]?.instagram_url);
      setValue("twitter_url", props.data[0]?.twitter_url);
      setValue("medium_url", props.data[0]?.medium_url);
      setValue("whatsapp_url", props.data[0]?.whatsapp_url);
    }
  }, [props.data, setValue]);
  const submitHandler = data => {
    // data.hotline = "+2349099976980";
    console.log(data);
    postAction(ADD_SOCIAL_ACCOUNT, smSettingsURL, data, token);
  };
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <TextField
        label="Facebook"
        name="facebook_url"
        placeholder="Facebook Link"
        error={formState.errors.facebook_url}
        {...register("facebook_url", { required: false })}
        type="text"
      />
      <TextField
        label="LinkedIn"
        name="iinkedin_url"
        placeholder="LinkedIn Link"
        error={formState.errors.iinkedin_url}
        {...register("iinkedin_url", { required: false })}
        type="text"
      />
      <TextField
        label="Instagram"
        name="instagram_url"
        placeholder="Instagram Link"
        error={formState.errors.instagram_url}
        {...register("instagram_url", { required: false })}
        type="text"
      />
      <TextField
        label="twitter"
        name="twitter_url"
        placeholder="twitter Link"
        error={formState.errors.twitter_url}
        {...register("twitter_url", { required: false })}
        type="text"
      />
      <TextField
        label="Medium"
        name="medium_url"
        placeholder="Medium Link"
        error={formState.errors.medium_url}
        {...register("medium_url", { required: false })}
        type="text"
      />
      <TextField
        label="Whatsapp"
        name="whatsapp_url"
        placeholder="Whatsapp Link"
        error={formState.errors.whatsapp_url}
        {...register("whatsapp_url", { required: false })}
        type="text"
      />
      <div className="flex justify-end pt-5">
        <AppButton name={`Save`} loading={props.loading} type="submit" />
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addSocialsLinks.loading,
  data: state.settings.socialsLinks.data,
  postData: state.settings.addSocialsLinks.data,
  error_msg: state.settings.addSocialsLinks.error_msg,
  fetch_error_msg: state.settings.socialsLinks.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(
  SocialsComponent
);
