import React, { useEffect } from "react";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import AppModal from "../../components/widget/Modal/Modal";
import Table from "../../components/widget/table/Table";
import { deleteAction, getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import DeleteModal from "./DeleteModal";
import { applicationData, applicationOldData } from "./job.data";
import ViewApplication from "./ViewApplication";
import { useDeleteJobApplicationMutation, useGetJobApplicationsQuery } from "../../services/jobApplication.services";
import { isSameDay } from "../../utils/helpers";
import { LoaderII } from "../../components/widget/Loader/Loader";

const JobsTable = ({ tableData, processData, isLoading }) => {
  const data = tableData.map(processData);
  return isLoading ? (
    <div className="flex justify-center">
      <LoaderII />
    </div>
  ) : (
    <Table tableData={data} no_option no_edit view_tooltip_content="View Application" />
  );
};

const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const JobApplications = ({ token, id, application_data, getAction, deleteAction, ...props }) => {
  const { data = [], isLoading } = useGetJobApplicationsQuery();
  const [deleteJobApplication, { isLoading: isDeleting, isSuccess }] = useDeleteJobApplicationMutation();

  useEffect(() => {
    if (isSuccess) {
      props.openModal("CLOSE_MODAL");
    }
  }, [isSuccess]);

  const { todayApplications, yesterdayApplications, olderApplications } = data.reduce(
    (acc, application) => {
      const applicationDate = new Date(application.application_date);
      if (isSameDay(applicationDate, today)) {
        acc.todayApplications.push(application);
      } else if (isSameDay(applicationDate, yesterday)) {
        acc.yesterdayApplications.push(application);
      } else {
        acc.olderApplications.push(application);
      }
      return acc;
    },
    { todayApplications: [], yesterdayApplications: [], olderApplications: [] }
  );
  const ExpandableContent = [
    {
      name: "Today",
      hasCount: todayApplications.length,
      Component: () => <JobsTable isLoading={isLoading} processData={applicationData} tableData={todayApplications} />,
    },
    {
      name: "Yesterday",
      hasCount: yesterdayApplications.length,
      Component: () => <JobsTable isLoading={isLoading} processData={applicationData} tableData={yesterdayApplications} />,
    },
    {
      name: "Older",
      hasCount: olderApplications.length,
      Component: () => <JobsTable isLoading={isLoading} processData={applicationOldData} tableData={olderApplications} />,
    },
  ];

  const deleteApplication = () => {
    if (id) {
      deleteJobApplication(id);
    }
  };
  return (
    <div>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} key={content.name} component={<Component />} />
        ))}
      </main>
      <AppModal open={props.open} onClose={() => props.openModal("CLOSE_MODAL")} content={<ViewApplication id={id} />} />
      <AppModal open={props.openDelete} onClose={() => props.openModal("CLOSE_MODAL")} content={<DeleteModal onClick={() => deleteApplication(id)} loading={isDeleting} />} />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.jobs.jobApplicationList.loading,
  application_data: state.jobs.jobApplicationList.data?.data,
  error_msg: state.jobs.jobApplicationList.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal, deleteAction })(JobApplications);
