import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PasswordField, PinInput, TextField } from "../../components/widget/form/FormComponent";
import { FormContainer } from "./auth.style";
import { connect } from "react-redux";
import { postAction } from "../../store/actions/creators/request.actions";
// import { openModal } from "../../store/actions/creators/ui.actions";
import { showMessage } from "../../store/actions/creators/ui.actions";
import { resetPasswordURL, resetURL } from "../../utils/endpoint";
import { RESET } from "../../store/actions/action_types/action.types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/widget/button/button.style";
import { AppButton } from "../../components/widget/button/Button";

const ResetPage = ({ postAction, showMessage, ...props }) => {
  const { formState, register, setValue, handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const { state } = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    console.log(state?.requestToken);
    setValue("request_token", state?.requestToken);
    setValue("email", state?.email);
  }, []);
  useEffect(() => {
    if (props.error == true && props.error_msg) alert(props.error_msg);
    showMessage("OPEN_MSG_MODAL", props.error_msg);
  }, [props, props.error]);

  useEffect(() => {
    if (props.success == true) {
      alert("Password changed successfully");
      navigate("/auth/login");
    }
    // showMessage("OPEN_MSG_MODAL", props.error_msg);
  }, [props.success]);

  const submitForm = data => {
    console.log(JSON.stringify(data));
    postAction(RESET, resetPasswordURL, data);
    setValue("");
  };
  return (
    <div className="bg-white flex justify-center h-screen items-center">
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <div className="mb-5">
          <img src="/images/logo.png" alt="" className="w-10"></img>
        </div>
        <section className="Form">
          <div className="w-full">
            <h2 className="FormTitle">Reset Password</h2>
            <div>
              <Link to={"/auth/recover-password"} className="cursor-pointer font-thin text-[#AE1931]">
                ← Back
              </Link>
              <div className="mb-6 mt-3">
                <div className="flex w-full flex-col items-start mt-5 mb-5">
                  <label className="mb-1 text-sm">Enter OTP</label>
                  <div className="flex justify-center items-center w-full">
                    <PinInput label="Enter OTP" otp_length={6} name="request_otp" control={control} {...register("request_otp", { required: true })} />
                  </div>
                </div>
                {/* <PasswordField label="Your Old Password" error={formState.errors.old_password} my="mb-6" {...register("old_password", { required: true })} /> */}
                <PasswordField label="Your New Password" error={formState.errors.new_password} {...register("new_password", { required: true })} />
              </div>
              {/* <button className="bg-[#AE1931] py-3 mb-4 rounded-lg text-white px-4 w-full">
                Reset →
              </button> */}
              <AppButton name="Reset →" type="submit" className="bg-[#AE1931] py-3 mb-4 rounded-lg text-white px-4 w-full" />
            </div>
          </div>
        </section>
      </FormContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.login?.reset?.loading,
  success: state.auth?.reset?.successFlag,
  // error_msg: state.login?.reset?.error_msg,
  // error: state.login?.reset?.error,
});

export default connect(mapStateToProps, { postAction, showMessage })(ResetPage);
