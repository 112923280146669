import React, { useState } from "react";
import { PanelContainer } from "./topbar.style";
import { connect } from "react-redux";
import { openModal } from "../../store/actions/creators/ui.actions";
import AppModal from "../widget/Modal/Modal";
import LogoutModal from "./LogoutModal";

export const MainPageTopPanel = ({ content, btnName, ...props }) => (
  <PanelContainer>
    <div className="flex">{content || <p className="text-black text-opacity-50">Click Save to Update this section.</p>}</div>
    <div className="flex justify-end items-center">{/* <AppButton name={`${btnName || "Save"}`} onPress={props.onSubmit} /> */}</div>
  </PanelContainer>
);

const TopBar = ({ user_info }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex justify-end">
      <div className="flex py-5 gap-3">
        <div className="text-secondary w-8 h-8 bg-[#ffd6dc] rounded-full flex justify-center items-center">{user_info.staff_first_name?.substring(0, 1)}</div>{" "}
        <div className="flex gap-5">
          <div>
            <p className="text-primary">Hi, {user_info?.staff_first_name || ""} </p>
            <p className="text-primary text-xs text-opacity-20">Admininstrator</p>
          </div>
          <button onClick={() => setOpen(true)} className="bg-[#ffd6dc] text-secondary px-4 py-2 rounded-lg">
            Logout
          </button>
        </div>
      </div>
      <AppModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content={
          <LogoutModal
            onClose={() => {
              setOpen(false);
            }}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.login.loading,
  user_info: state.user.userDetails,
  open: state.ui.modal?.open,
});

export default connect(mapStateToProps, { openModal })(TopBar);
