import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import { AppButton } from "../../components/widget/button/Button";
import Expandable from "../../components/widget/Expandable/Expandable";
import { TextField } from "../../components/widget/form/FormComponent";
import { BarContent, MetadataContent } from "./Sites";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_PRODCUT_USE_CASE, ADD_SITE_GENERAL, ADD_USE_CASE, GET_PRODUCT, GET_PRODUCT_GENERAL, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { ProductModalContent } from "./SiteHome";
import { productGenURL, productMetaURL, productURL, productUseCaseURL, useCaseURL } from "../../utils/endpoint";
import { useEffect } from "react";
import { ActionView } from "../../components/widget/Actions/Action";
import { IoEye } from "react-icons/io5";

const UseCaseContent = ({ ...props }) => {
  return (
    <>
      <section>
        <header>
          <span className="font-semibold text-lg">Use Case</span>
        </header>
        <UseCase {...props} />
      </section>
      <section>
        <header>
          <span className="font-semibold text-lg">Product Use Case</span>
        </header>
        <UseCaseProduct {...props} />
      </section>
    </>
  );
};

const mapStateToPropsUseCase = state => ({
  token: state.auth.login.token,
  usecase_sending: state.site.product.set_use_case.sending,
  usecase_data: state.site.product.set_use_case.data,
  product_usecase_sending: state.site.product.set_product_use_case.sending,
  product_usecase_data: state.site.product.set_product_use_case.data,
});
const UseCase = connect(mapStateToPropsUseCase, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  const submitForm = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_USE_CASE, useCaseURL, data, props.token);
  };
  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" />
      </div>
      <div className="">
        <TextField label="Page Title" defaultValue={""} type="text" {...register("page_title", { required: false })} />
        <TextField label="Section Title" defaultValue={""} type="text" {...register("section_title", { required: false })} />
        <TextField label="Description" defaultValue={""} type="textarea" {...register("description", { required: false })} />
        <TextField label="Call To Action" defaultValue={""} type="text" {...register("call_to_action", { required: false })} />
      </div>
    </form>
  );
});

const UseCaseProduct = connect(mapStateToPropsUseCase, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  const submitForm = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_PRODCUT_USE_CASE, productUseCaseURL, data, props.token);
  };
  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" />
      </div>
      <div className="">
        <TextField label="Product Name" defaultValue={""} type="text" {...register("product_name", { required: false })} />
        <TextField label="UseCase Title" defaultValue={""} type="text" {...register("usecase_title", { required: false })} />
        <TextField label="UseCase Description" defaultValue={""} name="description" type="textarea" {...register("usecase_description", { required: false })} />
      </div>
    </form>
  );
});

const mapStateToPropsGeneral = state => ({
  token: state.auth.login.token,
  sending_general: state.site.main.set_site_general.sending,
});
export const ProductGeneralContent = connect(mapStateToPropsGeneral, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { general_data } = props.data;
  general_data = general_data && general_data[general_data.length - 1];

  const submitForm = data => {
    data.keywords = "this shouldn't be here";
    props.postAction(ADD_SITE_GENERAL, props.url, data, props.token);
    // alert(JSON.stringify(data));
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_general} />
      </div>
      <div className="">
        <TextField label="Title" defaultValue={general_data?.title} name="title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={general_data?.title} name="description" type="textarea" {...register("description", { required: false })} />
      </div>
    </form>
  );
});

const ProductContainer = ({ ...props }) => {
  let { product_data } = props.data;

  return (
    <>
      {product_data?.length > 0 ? (
        <div className="grid grid-cols-3">
          {product_data.map(content => (
            <div className="flex flex-col bg-gray-100 p-3">
              <div className="flex gap-x-5">
                <img src={content.product_image_url} alt={content.product_name} className="h-[100px] w-[100px] bg-contain bg-no-repeat bg-center"></img>
                <div className="flex flex-col">
                  <div className="flex">
                    <span className="font-medium text-lg">{content.product_name}</span>
                  </div>
                  <div className="flex">
                    <span>{content.description || "some description was described here..."}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <ActionView icon={<IoEye />} id={props.id} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-gray-100 p-5 flex justify-center items-center w-[70%] m-auto">
          <span>Sorry No Data for this call!</span>
        </div>
      )}
    </>
  );
};

const SiteProduct = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={productMetaURL} />,
    },
    {
      name: "General",
      Component: props => <ProductGeneralContent {...props} url={productGenURL} />,
    },
    {
      name: "Products",
      Component: props => <ProductContainer {...props} />,
      hasCount: props.product_data?.length,
      hasPlus: true,
      modalcontent: <ProductModalContent />,
    },
    {
      name: "Use Cases",
      Component: props => <UseCaseContent {...props} />,
      // hasToggle: true,
    },
  ];

  useEffect(() => {
    props.getAction(GET_SITE_META, productMetaURL, token);
    props.getAction(GET_PRODUCT_GENERAL, productGenURL, token);
    props.getAction(GET_PRODUCT, productURL, token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Products" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  product_data: state.site.product.get_product.data,
  product_loading: state.site.product.get_product.loading,
  // header_data: state.site.main.get_header.data,
  // file_name: state.ui.input_file,
  // file_input_name: state.ui.input_file?.name,
  // career_data: state.site.career.get_career_gen.data,
  // intro_data: state.site.career.get_career_intro.data,
  // page_content_data: state.site.career.get_career_content.data,
  // job_data: state.site.career.get_job_open.data,
});
export default connect(mapStateToProps, { getAction })(SiteProduct);
