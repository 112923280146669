import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { useGetPositionQuery } from "../../services/position.services";
import { LoaderII } from "../../components/widget/Loader/Loader";

const ViewPositions = ({ id, openModal }) => {
  const { data, isLoading } = useGetPositionQuery(id, { skip: !id });

  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        {isLoading ? (
          <div className="py-4">
            <LoaderII />
          </div>
        ) : (
          <>
            <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
              <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
              <h1 className="mt-1 font-bold text-xl md:text-3xl mb-1">{data?.title_of_position}</h1>
            </header>
            <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
              <section className="w-full px-6">
                <h5 className="text-[#AE1931]">Description</h5>
                <p>{data?.job_description}</p>
              </section>
              <section className="w-full mt-4 px-6">
                <h5 className="text-[#AE1931]">Responsibilities</h5>
                <p>{data?.job_responsibilities}</p>
              </section>
            </main>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  data: state.jobs.jobPositionDetails.data?.data,
  loading: state.jobs.jobPositionDetails.loading,
  error_msg: state.jobs.jobPositionDetails.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(ViewPositions);
