import { useState } from "react";
import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import { AppButton } from "../../components/widget/button/Button";
import Expandable from "../../components/widget/Expandable/Expandable";
import { SelectField, TextEditor, TextField } from "../../components/widget/form/FormComponent";
import { BarContent, MetadataContent } from "./Sites";
import { IoHelpCircleOutline, IoCubeOutline, IoCloudOfflineOutline, IoPencilOutline, IoTrashBinOutline } from "react-icons/io5";
import { faqsCategory, faqsCategoryURL, faqsGeneralURL, faqsMetaURL, faqURL } from "../../utils/endpoint";
import { connect } from "react-redux";
import { mapStateToPropsFaq } from "../../utils/helpers";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_FAQ, ADD_FAQ_CATEGORY, ADD_FAQ_GENERAL, GET_FAQ, GET_FAQ_CATEGORY, GET_FAQ_GENERAL, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { useEffect } from "react";
import parser from "html-react-parser";
import { ActionView } from "../../components/widget/Actions/Action";
import AppModal from "../../components/widget/Modal/Modal";

const FAQs = props => (
  <div className="cursor-move">
    <div className="mb-[0.75rem] relative flex items-start justify-start rounded-[0.5rem] bg-opacity-100 bg-gray-50">
      <div className="py-4 pr-4 pl-4">
        <div>
          <article className="px-2">
            <div className="text-black">Investments</div>
            <h3 className="mb-[0.25rem] text-[1rem] leading-[1.5rem] font-medium">{props.question}?</h3>
            <div className="mb-[0.75rem]">
              <p>{parser(props.answer)}</p>
            </div>
          </article>
        </div>
        <ActionView />
      </div>
    </div>
  </div>
);

const ModalHeader = ({ title, sub_title, icon }) => (
  <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 py-4">
    <div className="flex flex-col justify-center items-center text-center px-2">
      <div className="text-black text-opacity-20 mt-3 mb-2">{icon || <IoCubeOutline size={50} />}</div>
      <div className="font-bold text-xl md:text-3xl mb-1">{title || "Add"} FAQ Category</div>
      <div className="text-black text-center text-opacity-40 text-sm capitalize">{sub_title || "What will you like to call this category?"}</div>
    </div>
  </header>
);

const ModalFooter = ({ loading }) => (
  <footer className="w-full mb-1">
    <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse mx-2">
      <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
        Cancel
      </button>
      <AppButton name="Save" loading={loading} />
    </div>
  </footer>
);

const ModalBody = ({ children, max_w, ...props }) => (
  <div className={`relative flex items-center justify-center transition-all p-4 duration-300 ${max_w || "max-w-[24rem]"}`}>
    <div className="relative flex flex-col items-center justify-center bg-white shadow-xl rounded-lg w-[520px] max-w-[95vw] h-auto">
      <a></a>
      <ModalHeader {...props} />
      {children}
    </div>
  </div>
);

const AddFaqModalContent = connect(mapStateToPropsFaq, { postAction })(props => {
  const { formState, handleSubmit, register, control } = useForm({ mode: "onChange" });
  const onSubmit = data => {
    data.category_id = data.category_id.id;
    props.postAction(ADD_FAQ, faqURL, data, props.token);
  };
  const selectCategory = props?.category_data.sort((a, b) => a.id - b.id);
  useEffect(() => {
    console.log(selectCategory.sort((a, b) => a.id - b.id));
  }, []);
  return (
    <ModalBody max_w="max-w-[34rem]" title="Add FAQ" sub_title="Provide a question and an answer to aid product understanding for your customers." icon={<IoHelpCircleOutline size={50} />}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <main className="py-3 max-h-[50vh] px-6">
          <div className="w-full px-3">
            <SelectField control={control} mb="2xl:mb-6 lg:mb-3 mb-6" item={selectCategory} label="Category" selected_item="category" name="category_id" {...register("category_id", { required: false })} />
            <TextField label="Question" type="text" {...register("question", { required: false })} />
            <TextEditor label="Answer" name="answer" control={control} {...register("answer", { required: false })} />
          </div>
        </main>
        <ModalFooter loading={props.sending_faq} />
      </form>
    </ModalBody>
  );
});

const FaqCategoryModalContent = connect(mapStateToPropsFaq, { postAction })(props => {
  const { formState, handleSubmit, register } = useForm({ mode: "onChange" });

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_FAQ_CATEGORY, faqsCategoryURL, data, props.token);
  };

  return (
    <ModalBody title={props.defaultValue ? "Edit" : "Add"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <main className="py-3 max-h-[50vh]">
          <div className="w-full px-3">
            <TextField label="Category" defaultValue={props.defaultValue || null} type="text" {...register("category", { required: false })} />
          </div>
        </main>
        <ModalFooter loading={props.sending_faq_cat} />
      </form>
    </ModalBody>
  );
});

const FaqGeneral = connect(mapStateToPropsFaq, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { faq_gen_data } = props;
  faq_gen_data = faq_gen_data && faq_gen_data[faq_gen_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_FAQ_GENERAL, faqsGeneralURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" loading={props.sending_faq_gen} />
      </div>
      <TextField label="Title" defaultValue={faq_gen_data?.title} name="title" type="text" {...register("title", { required: false })} />
      <TextField label="Description" defaultValue={faq_gen_data?.description} name="description" type="textarea" {...register("description", { required: false })} />
    </form>
  );
});

const FaqCategory = props => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="bg-gray-200 bg-opacity-50 relative flex items-center justify-start px-4 pr-2 py-3 rounded-full font-light">
        {props.category}
        <div className="bg-white w-7 h-7 ml-2 flex items-center justify-center justify-self-end rounded-[9999px] transition-all" onClick={() => setOpen(true)}>
          <a className="text-black">
            <IoPencilOutline className="" />
          </a>
        </div>
      </div>
      <AppModal open={open} onClose={() => setOpen(false)} content={<FaqCategoryModalContent defaultValue={props.category} />} />
    </>
  );
};

const SiteFAQ = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={faqsMetaURL} />,
    },
    { name: "General", Component: props => <FaqGeneral {...props} /> },
    {
      name: "Categories",
      Component: props => (
        <div className="flex flex-wrap gap-3">
          {props.category_data.map(content => (
            <FaqCategory {...content} />
          ))}
        </div>
      ),
      hasCount: props.category_data?.length,
      hasPlus: true,
      modalcontent: <FaqCategoryModalContent />,
    },
    {
      name: "Frequently Asked Questions",
      Component: props => props.faq_data.map(content => <FAQs {...content} />),
      hasCount: props.faq_data?.length,
      hasPlus: true,
      modalcontent: <AddFaqModalContent />,
    },
  ];

  useEffect(() => {
    props.getAction(GET_SITE_META, faqsMetaURL, token);
    props.getAction(GET_FAQ_GENERAL, faqsGeneralURL, token);
    props.getAction(GET_FAQ_CATEGORY, faqsCategoryURL, token);
    props.getAction(GET_FAQ, faqURL, token);
  }, []);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="FAQ" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component {...props} checkKeyDown={checkKeyDown} />} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  category_data: state.site.faq.get_faq_category.data,
  faq_data: state.site.faq.get_faq.data,
  faq_gen_data: state.site.faq.get_faq_general.data,
});
export default connect(mapStateToProps, { postAction, getAction })(SiteFAQ);
