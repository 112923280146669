import styled from "styled-components";

export const Fieldset = styled.fieldset`
  input,
  textarea {
    margin-top: 0.25rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #c2cfd633;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 300;
    height: 50px;
    outline: 0;
  }
  textarea {
    height: auto;
    resize: none;
    padding-top: 0.5rem;
  }
  .related_post {
    input {
      background-color: white;
      ${"" /* padding: 0; */}
      font-size: 12px;
      height: auto;
    }
  }
  .tags {
    input {
      background-color: transparent;
      padding: 0;
      font-size: 0.875rem;
      height: auto;
      width: auto;
    }
    .single-tag {
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(38, 38, 38, var(--tw-text-opacity));
    }
  }
`;

export const PassType = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  input {
    width: 100%;
    border: none;
  }
  .p_visible {
    width: 5%;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 0.5em;
    margin-right: 5em;
  }
`;

export const SelectInput = styled.fieldset`
  display: flex;
  /* justify-content: flex-start; */
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 ${props => props.padX || "1em"};
  padding-bottom: ${props => props.padB || "10em"}
  font-size: 13px;
  text-align:left;
  legend {
    color: #000;
    font-size: 12px;
    opacity: 0.7;
    font-weight: 500;
    /* background-color:red */
  }
  input,
  textarea {
    outline: none;
    border: none;
    font-size: 14px;
    background: transparent;
    width: 100%;
    box-sizing: border-box;
    height: ${props => props.height || "38px"};
    padding-bottom: 0.5em;
  }
  textarea {
    min-height: 8em;
    max-height: 8em;
  }
  .list-btn {
    box-sizing: border-box;
    height: 38px;
    padding-bottom: 0.5em;
  }
  input::placeholder {
    font-size: 14px;
  }
  #banks {
    background-color: #fff !important;
    option {
      background-color: #fff !important;
      color: #000;
    }
  }
  .select-field {
    border: none !important;
    /* background-color: red; */
  }
  .css-1s2u09g-control{
    border-width: 0;
    outline: none;
    &&::focus{
      background-color: red;
      outline: none;
    }
  }
`;

export const ToggleContainer = styled.div`
  .toggle-checkbox:checked {
    right: 0;
    ${"" /* border-color: #922333; */}
    border: none;
  }
  .toggle-checkbox:checked + .toggle-label {
    background-color: #992333;
  }
`;

export const Checkboxinput = styled.input`
  #checkbox1:checked {
    ${"" /* border-color: red; */}
    background-color: red;
    border: none;
  }
`;

export const CheckboxDiv = styled.div`
  ${"" /* background-color: #2c3e50; */}
  ${"" /* color: #922333; */}

	font-family: sans-serif;
  /* font-weight: 500; */
  font-size: 14px;
  span.bigcheck-target {
    font-family: FontAwesome; /* use an icon font for the checkbox */
  }
  input[type="checkbox"].bigcheck {
    position: relative;
    display: none;
    ${"" /* left: -999em; */}
  }
  input[type="checkbox"].bigcheck + span.bigcheck-target:after {
  }
  input[type="checkbox"].bigcheck:checked + span.bigcheck-target:after {
  }
  span.bigcheck {
    display: block;
    ${"" /* padding: 0.5em; */}
  }
`;

export const OverlayDiv = styled.div`
  background-color: rgba(31, 26, 23, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.8;
`;

export const MediaFiles = styled.div`
  overflow-y: auto;
  padding: 0.25rem;
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  height: 72.6vh;
  .media {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
    min-height: 270px;
    .Image {
      height: 9rem;
      width: 100%;
      border-radius: 0.5rem;
      background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
      --tw-bg-opacity: 0.05;
      img {
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .text-grey {
    color: rgba(194, 207, 214, var(--tw-text-opacity));
  }
`;

export const FileDiv = styled.div`
  .icon {
    color: rgba(38, 38, 38, var(--tw-text-opacity));
    --tw-text-opacity: 0.5;
  }
`;
export const RadioDiv = styled.div`
  .selected {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 220, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(174, 25, 49, var(--tw-text-opacity));
  }
`;
