import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
};

const getProductReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_PRODUCT}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PRODUCT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setProductReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_PRODUCT}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_PRODUCT:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUseCaseReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_USE_CASE}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USE_CASE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setUseCaseReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_USE_CASE}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_USE_CASE:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getProductUseCaseReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_PRODUCT_USE_CASE}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PRODUCT_USE_CASE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setProductUseCaseReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_PRODCUT_USE_CASE}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_PRODCUT_USE_CASE:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const productReducer = combineReducers({
  get_product: getProductReducer,
  set_product: setProductReducer,
  get_use_case: getUseCaseReducer,
  set_use_case: setUseCaseReducer,
  get_product_use_case: getProductUseCaseReducer,
  set_product_use_case: setProductUseCaseReducer,
});
