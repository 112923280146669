import React from "react";
import { NavLink } from "react-router-dom";
import { SideBarContainer } from "./sidebar.style";
import {
  IoBriefcase,
  IoBrowsers,
  IoCalendar,
  IoChatbubbles,
  IoDocumentText,
  IoFilm,
  IoMail,
  IoPencilSharp,
  IoPieChart,
  IoSettings,
  IoToday,
} from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import appLogo from "../../asset/img/gdl-logo.png";

let activeStyle = {
  backgroundColor: "#fff1f4",
  borderColor: "#ae1931",
};
const List = props => (
  <li className={` ${props.isActive ? "active" : null} mb-1`}>
    <NavLink
      to={props.url}
      className="navbarLink flex justify-center md:justify-start items-center py-2 md:pl-1 lg:pr-3 lg:pl-2 xl:pl-6 md:gap-1 lg:gap-6 border-transparent md:border-l-4"
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      <div className="sidebarIcon text-primary text-opacity-20">
        {props.icon}
      </div>
      <p className="text-primary hidden md:block text-opacity-40 sidebarText">
        {props.list_name}
      </p>
    </NavLink>
  </li>
);

const SideBar = () => {
  return (
    <SideBarContainer>
      <div className="sidebarWrapper w-full">
        <div className="sidebarMenu">
          <div className="flex justify-center py-4 sidebarLogo sm:h-24">
            <img
              src={appLogo}
              alt=""
              className="logo cursor-pointer w-6 md:w-[60px] md:h-[70px] md:p-4"
            />
          </div>

          <ul className="flex flex-col justify-center w-full sidebarList">
            <List
              list_name="Analytics"
              icon={<IoPieChart size={25} />}
              url="/"
            />
            <List
              list_name="Site"
              icon={<IoBrowsers size={25} />}
              url="/site"
            />
            <List
              list_name="Posts"
              icon={<IoPencilSharp size={25} />}
              url="/posts"
            />

            <List list_name="Media" icon={<IoFilm size={25} />} url="/media" />
            <List
              list_name="Jobs"
              icon={<IoBriefcase size={25} />}
              url="/jobs"
            />
            <List
              list_name="Newsletters"
              icon={<IoDocumentText size={25} />}
              url="/newsletters"
            />
            <List
              list_name="Messages"
              icon={<IoMail size={25} />}
              url="/messages"
            />
            <List
              list_name="Attendance"
              icon={<IoCalendar size={25} />}
              url="/attendance"
            />
            <List
              list_name="Appointments"
              icon={<IoToday size={25} />}
              url="/appointments"
            />
            <List
              list_name="Support"
              icon={<IoChatbubbles size={25} />}
              url="/support"
            />
            <List list_name="Users" icon={<FaUsers size={25} />} url="/users" />
            <List
              list_name="Settings"
              icon={<IoSettings size={25} />}
              url="/settings"
            />
          </ul>
        </div>
      </div>
    </SideBarContainer>
  );
};

export default SideBar;
