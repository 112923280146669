import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
};

const getNewsLetterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NEWSLETTERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NEWSLETTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_NEWSLETTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const editNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_NEWSLETTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_NEWSLETTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSubscribersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SUBSCRIBERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const editSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const newsLetterReducer = combineReducers({
  newsLetterList: getNewsLetterListReducer,
  newsLetterDetails: getNewsLetterReducer,
  addNewsLetter: addNewsLetterReducer,
  deleteNewsLetter: deleteNewsLetterReducer,
  editNewsLetter: editNewsLetterReducer,
  subscriberList: getSubscribersListReducer,
  subscriberDetails: getSubscriberReducer,
  addSubscriber: addSubscriberReducer,
  deleteSubscriber: deleteSubscriberReducer,
  editSubscriber: editSubscriberReducer,
});
