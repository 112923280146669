import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Resources, SiteAbout, SiteCareers, SiteContact, SiteDirectors, SiteFAQ, SiteHome, SiteManagement, SitePrivacy, SiteProduct, SiteTerm, SiteTestimonial } from "../../pages";
import Sites from "../../pages/Site/Sites";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";

const tabNames = {
  Home: "",
  About: "",
  Products: "",
  Resources: "",
  Testimonials: "",
  Careers: "",
  FAQs: "",
  Contact: "",
  Management: "",
  Directors: "",
  Privacy: "",
  Terms: "",
};

const SiteRoute = () => {
  return (
    <>
      <Sites />
      <TestTab tabs={tabNames} />
      <Routes>
        <Route path="/" element={<SiteHome />} />
        <Route path="/about" element={<SiteAbout />} />
        <Route path="/products" element={<SiteProduct />} />
        <Route path="resources" element={<Resources />} />
        <Route path="/testimonials" element={<SiteTestimonial />} />
        <Route path="/careers" element={<SiteCareers />} />
        <Route path="/faqs" element={<SiteFAQ />} />
        <Route path="/contact" element={<SiteContact />} />
        <Route path="/management" element={<SiteManagement />} />
        <Route path="/directors" element={<SiteDirectors />} />
        <Route path="/privacy" element={<SitePrivacy />} />
        <Route path="terms" element={<SiteTerm />} />
      </Routes>
    </>
  );
};

export default SiteRoute;
