import React, { useEffect, useState } from "react";
import { MainHeader } from "../../components/Layout/DashboardHeader";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { useForm } from "react-hook-form";
import { FileImageModalOpener, TextEditor, TextField, TextFieldTag, Toggler } from "../../components/widget/form/FormComponent";
import { IoCheckmarkCircle } from "react-icons/io5";
import { AppButton } from "../../components/widget/button/Button";
import { connect } from "react-redux";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { ADD_AD_SECTION, ADD_EBOOK, ADD_HEADER, ADD_HERO, ADD_HOME_BLOG, ADD_HOME_GET_STARTED, ADD_HOME_PRODUCT, ADD_NEWSLETTER, ADD_SITE_FOOTER, ADD_SITE_GET_STARTED, ADD_SITE_META, ADD_STATISTICS, ADD_USP, GET_HEADER, GET_MAIN_SITE_META, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { adSectionURL, blogURL, ebookURL, footerURL, getStartedURL, headerURL, heroURL, homeGetStartedURL, homeProdURL, newsLetterURL, siteMetaURL, siteNewsLetterURL, statisticsURL, uspURL } from "../../utils/endpoint";
import { mapStateToPropsHome } from "../../utils/helpers";

const mapStateToPropsTwo = state => ({
  tags: state.ui.tag,
  name: state.ui.tag.name,
  token: state.auth.login.token,
  sending_getstarted: state.site.main.set_site_getstarted.sending,
  sending_letter: state.site.main.set_newsletter.sending,
  sending_ebook: state.site.main.set_ebook.sending,
  sending_footer: state.site.main.set_footer.sending,
});

export const BarContent = ({ name, type }) => (
  <div className="flex">
    {type == "res" ? (
      <div className="flex gap-5">
        {name.map(content => (
          <a href={`https://gdl.com.ng/${content.toLowerCase() || ""}`} target="_blank" className="text-[1rem] leading-[1.5rem]">
            {content} ↗
          </a>
        ))}
      </div>
    ) : (
      <a href={`https://gdl.com.ng/${name.toLowerCase() || ""}`} target="_blank" className="text-[1rem] leading-[1.5rem]">
        View {name} page ↗
      </a>
    )}
  </div>
);

const ProductItem = ({ name, isChecked, onPress }) => (
  <div className="" onClick={onPress}>
    <div className="relative mr-[0.5rem] mb-3 cursor-pointer bg-red-100 flex items-center justify-start overflow-hidden rounded-[9999px] px-[1rem] py-[0.75rem]">
      {isChecked[name] && <IoCheckmarkCircle className="mr-2 text-red-400 text-xl" />}
      <span className="font-medium">{name}</span>
    </div>
  </div>
);

export const ProductContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });
  const [checked, setChecked] = useState({});

  let { home_prod_data } = props.data;
  home_prod_data = home_prod_data && home_prod_data[home_prod_data.length - 1];

  const onSubmit = data => {
    data.featured_products = JSON.stringify(Object.keys(checked));
    // alert(JSON.stringify(data));
    props.postAction(ADD_HOME_PRODUCT, homeProdURL, data, props.token);
  };

  const handleClear = e => {
    e.preventDefault();
  };

  const product_item = [{ name: "High Yield Note" }, { name: "GDL X Obiwezy" }];

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="w-full flex justify-end items-center">
          <AppButton name="save" type="submit" loading={props.sending_prod} />
        </div>
        <TextField label="Title" defaultValue={home_prod_data?.title} type="text" error={formState.errors.producttitle} {...register("title", { required: false })} />
        <TextField type="textarea" defaultValue={home_prod_data?.description} label="Description" error={formState.errors.productdescription} {...register("description", { required: false })} />
        <div className="mb-4">
          <h3 className="text-lg md:text-xl font-normal">Products</h3>
          <div className="">
            <button onClick={handleClear} className="flex items-center justify-center rounded-[0.5rem] transition-all text-blue-500 text-base font-normal">
              <span>Clear</span>
            </button>
          </div>
          <section className="my-3">
            <div className="flex flex-wrap">
              {product_item.map(content => (
                <ProductItem name={content.name} isChecked={checked} onPress={() => setChecked({ ...checked, [content.name]: !checked[content.name] })} />
              ))}
            </div>
          </section>
        </div>
        <TextField type="text" defaultValue={home_prod_data?.call_to_action} label="Call To Action" error={formState.errors.productaction} {...register("call_to_action", { required: false })} />
      </div>
    </form>
  );
});

export const StatisticContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { stats_data } = props.data;
  stats_data = stats_data && stats_data[stats_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    data.customers = parseInt(data.customers);
    data.businesses = parseInt(data.businesses);
    props.postAction(ADD_STATISTICS, statisticsURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_stats} />
        </div>
        <TextField label="Caption" defaultValue={stats_data?.caption} type="text" name="caption" error={formState.errors.caption} {...register("caption", { required: false })} />
        <TextField label="Customers" defaultValue={stats_data?.customers} type="text" name="customers" error={formState.errors.customers} {...register("customers", { required: false })} />
        <TextField label="Businesses" defaultValue={stats_data?.businesses} type="text" name="businesses" error={formState.errors.businesses} {...register("businesses", { required: false })} />
      </div>
    </form>
  );
});

export const HeroContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { file_name, file_input_name, hero_data } = props.data;
  hero_data = hero_data && hero_data[hero_data.length - 1];

  const onSubmit = data => {
    data.image_url = file_name["hero_logo"]?.image_url;
    // alert(JSON.stringify(data));
    if (!data.call_to_action_link.includes("https")) {
      alert("call to action link must be a url");
    } else {
      props.postAction(ADD_HERO, heroURL, data, props.token);
    }
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_hero} />
        </div>
        <FileImageModalOpener label="Image" defaultValue={hero_data?.image_url} control={control} name="hero_logo" {...register("hero_logo", { required: false })} />
        <div>
          <TextField label="Title" type="text" defaultValue={hero_data?.title} name="title" error={formState.errors.herotitle} {...register("title", { required: false })} />
          <TextField type="textarea" label="Description" defaultValue={hero_data?.description} name="description" error={formState.errors.herodescription} {...register("description", { required: false })} />
          <TextField label="Call To Action (Title)" defaultValue={hero_data?.call_to_action_title} type="text" name="call_to_action_title" error={formState.errors.logo} {...register("call_to_action_title", { required: false })} />
          <TextField label="Call To Action (Link)" defaultValue={hero_data?.call_to_action_link} type="text" name="call_to_action_link" error={formState.errors.logo} {...register("call_to_action_link", { required: false })} />
        </div>
      </div>
    </form>
  );
});

const mapStateToPropsAD = state => ({
  tags: state.ui.tag,
  name: state.ui.tag.name,
  token: state.auth.login.token,
  sending: state.site.main.set_ad_section.sending,
});
export const AdSectionContent = connect(mapStateToPropsAD, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit, getValues } = useForm({
    mode: "onChange",
  });

  const { file_name, file_input_name } = props.data;

  const onSubmit = data => {
    const call_to_action_link = getValues("call_to_action_link");
    data.image_url = file_name["section_logo_small"]?.image_url;
    if (!call_to_action_link.includes("https")) {
      alert("Call To Action Link must be a URL type");
    } else {
      // alert(JSON.stringify(data));
      props.postAction(ADD_AD_SECTION, adSectionURL, data, props.token);
    }
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending} />
        </div>
        {/* {JSON.stringify(file_name[file_input_name])} */}
        <Toggler control={control} name="show_ad_popup" label={["Turning this section ON will turn the 'NewsLetter' section off. This is necessary to create the best experience for users."]} />
        <div className="mt-10">
          <FileImageModalOpener
            // defaultValue="wassa"
            label="Small Image (width: 360px)"
            control={control}
            name="section_logo_small"
            {...register("section_logo_small", { required: false })}
          />
          <FileImageModalOpener label="Medium Image (width: 480px)" control={control} name="section_logo_mid" {...register("section_logo_mid", { required: false })} />
          <FileImageModalOpener label="Large Image (width: 1600px)" control={control} name="section_logo_large" {...register("section_logo_large", { required: false })} />
          <div>
            <TextField type="text" label="Title" name="title" error={formState.errors.title} {...register("title", { required: false })} />
            <TextField type="textarea" label="Description" name="description" error={formState.errors.description} {...register("description", { required: false })} />
            <TextField label="Call To Action" type="text" name="call_to_action_text" {...register("call_to_action_text", { required: false })} />
            <TextField label="Call To Action Link" type="text" name="call_to_action_link" {...register("call_to_action_link", { required: false })} />
          </div>
        </div>
      </div>
    </form>
  );
});

const mapStateToPropsMeta = state => ({
  tags: state.ui.tag,
  name: state.ui.tag.name,
  token: state.auth.login.token,
  sending_meta: state.site.main.set_site_meta.sending,
});
export const MetadataContent = connect(mapStateToPropsMeta, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({
    mode: "onChange",
  });

  let { meta_data } = props.data || props;
  meta_data = meta_data && meta_data[meta_data.length - 1];

  const onSubmit = data => {
    data.keywords = props.isArray ? props.tags[props.name] : props.tags[props.name].toString();
    props.postAction(ADD_SITE_META, props.url, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_meta} />
        </div>
        <TextField type="text" label="Title" defaultValue={meta_data?.title} name="title" error={formState.errors.title} {...register("title", { required: false })} />
        <TextField type="textarea" defaultValue={meta_data?.description} label="Description" name="description" error={formState.errors.description} {...register("description", { required: false })} />
        <div className="mt-8">
          <TextFieldTag label="Keywords" defaultValue={meta_data?.keywords} control={control} name="tags" error={formState.errors.tags} {...register("tags", { required: false })} />
        </div>
      </div>
    </form>
  );
});

export const HomeGetStartedContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { get_started_data } = props.data;
  get_started_data = get_started_data && get_started_data[get_started_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_HOME_GET_STARTED, homeGetStartedURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_getStarted} />
        </div>
        <TextField label="Title" defaultValue={get_started_data?.title} type="text" name="title" error={formState.errors.title} {...register("title", { required: false })} />
        <TextField label="Subtitle" defaultValue={get_started_data?.sub_title} type="text" name="sub_title" error={formState.errors.sub_title} {...register("sub_title", { required: false })} />
        <TextField label="Description" defaultValue={get_started_data?.description} type="textarea" name="description" error={formState.errors.description} {...register("description", { required: false })} />
        <TextField label="Call To Action (Title)" defaultValue={get_started_data?.call_to_action_title} type="text" name="call_to_action_title" error={formState.errors.call_to_action_title} {...register("call_to_action_title", { required: false })} />
        <TextField label="Call To Action (Link)" defaultValue={get_started_data?.call_to_action_link} type="text" name="call_to_action_link" error={formState.errors.call_to_action_link} {...register("call_to_action_link", { required: false })} />
      </div>
    </form>
  );
});

export const UniqueSaleContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { usp_data } = props.data;
  usp_data = usp_data && usp_data[usp_data.length - 1];

  const onSubmit = data => {
    data.usps = JSON.stringify(props.tags["usps"]);
    // alert(JSON.stringify(data));
    props.postAction(ADD_USP, uspURL, data, props.token);
  };
  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_usp} />
      </div>
      <div className="">
        <TextField label="Title" defaultValue={usp_data?.why_gdl} type="text" name="why_gdl" error={formState.errors.why_gdl} {...register("why_gdl", { required: false })} />
        <TextFieldTag defaultValue={usp_data?.usps} label="Unique Selling Propositions" control={control} name="usps" error={formState.errors.usps} {...register("usps", { required: false })} />
      </div>
    </form>
  );
});

export const BlogContent = connect(mapStateToPropsHome, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { blog_data } = props.data;
  blog_data = blog_data && blog_data[blog_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_HOME_BLOG, blogURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_blog} />
      </div>
      <div className="">
        <TextField label="Title" defaultValue={blog_data?.title} type="text" error={formState.errors.title} {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={blog_data?.description} type="textarea" error={formState.errors.description} {...register("description", { required: false })} />
        <TextField label="Call To Action" defaultValue={blog_data?.call_to_action} type="text" error={formState.errors.call_to_action} {...register("call_to_action", { required: false })} />
      </div>
    </form>
  );
});

const mapStateToProps2 = state => ({
  file: state.ui.input_file,
  header_load: state.site.main.set_header?.sending,
  header_add_data: state.site.main.set_header?.data,
  input_name: state.ui.input_file?.name,
});
export const HeaderContent = connect(mapStateToProps2, { postAction })(({ checkKeyDown, input_name, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({
    mode: "onChange",
  });
  const head_data = props?.data?.header_data[0];

  // const { handleSubmit, data } = useSubmit(getValues);
  const onSubmit = data => {
    data.logo_url = props.file.image_url;
    // alert(JSON.stringify(props.file.image_url));
    props.postAction(ADD_HEADER, headerURL, data, props.token);
  };

  useEffect(() => {
    if (props.header_add_data) {
      alert("Header updated successfully");
    }
  }, [props.header_add_data]);

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.header_load} />
        </div>
        <FileImageModalOpener defaultValue={head_data?.logo_url} label="logo" value={props.file[input_name]?.image_title} control={control} name="logo_url" {...register("logo_url", { required: false })} />
        <h5 className="mt-[1.5rem] mb-[0.75rem] text-[1.25rem] leading-[1.75rem] text-opacity-50 text-[#262626] font-light">Login Action</h5>
        <TextField label="Call To Action Title" defaultValue={head_data?.login_call_to_action_title} type="text" name="login_call_to_action_title" error={formState.errors.logo} {...register("login_call_to_action_title", { required: true })} />
        <TextField label="Call To Action Link" type="text" defaultValue={head_data?.login_call_to_action_link} name="login_call_to_action_link" error={formState.errors.login_call_to_action_link} {...register("login_call_to_action_link", { required: true })} />
        <div className="mt-10">
          <h5 className="mt-[1.5rem] mb-[0.75rem] text-[1.25rem] leading-[1.75rem] text-opacity-50 text-[#262626] font-light">Create Account Action</h5>
          <TextField
            label="Call To Action Title"
            type="text"
            name="create_acc_call_to_action_title"
            height="50px"
            defaultValue={head_data?.create_acc_call_to_action_title}
            error={formState.errors.create_acc_call_to_action_title}
            {...register("create_acc_call_to_action_title", {
              required: false,
            })}
          />
          <TextField label="Call To Action Link" type="text" name="logo" height="50px" defaultValue={head_data?.create_acc_call_to_action_link} error={formState.errors.create_acc_call_to_action_link} {...register("create_acc_call_to_action_link", { required: false })} />
        </div>
      </div>
    </form>
  );
});

// get started dropdown
export const GetStartedContent = connect(mapStateToPropsTwo, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, getValues, handleSubmit } = useForm({
    mode: "onChange",
  });
  const { file_name, file_input_name } = props.data;
  const onSubmit = data => {
    data.image_url = file_name["get_started_image"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_SITE_GET_STARTED, getStartedURL, data, props.token);
  };
  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_getstarted} />
        </div>
        <FileImageModalOpener label="Image" name="get_started_image" control={control} {...register("get_started_image", { required: false })} />
        <TextField label="Title" type="text" name="title" {...register("title")} />
        <TextField label="Description" name="description" type="textarea" {...register("description")} />
      </div>
    </form>
  );
});

export const NewsLetter = connect(mapStateToPropsTwo, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, getValues, handleSubmit } = useForm({
    mode: "onChange",
  });

  const { file_name, file_input_name } = props.data;

  const onSubmit = data => {
    data.image_url = file_name["news_letter_image"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_NEWSLETTER, siteNewsLetterURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_letter} />
        </div>
        <FileImageModalOpener label="Image" name="news_letter_image" control={control} {...register("news_letter_image", { required: false })} />
        <TextField label="Title" type="text" name="title" {...register("title")} />
        <TextField label="Description" name="description" type="textarea" {...register("description")} />
      </div>
    </form>
  );
});

export const EbookContent = connect(mapStateToPropsTwo, { postAction })(({ checkKeyDown, ...props }) => {
  const { handleSubmit, register, control, formState } = useForm({ mode: "onChange" });

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_EBOOK, ebookURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <TextField label="Modal Title" type="text" name="modal_title" {...register("modal_title")} />
      <TextEditor label="Book Title" control={control} name="book_title" {...register("book_title", { required: false })} />
      <TextField label="Downloads" type="text" name="downlaod_count" {...register("downlaod_count")} />
      <h5 className="mt-[1.5rem] mb-[0.75rem] text-[1.25rem] leading-[1.75rem] text-opacity-50 text-[#262626] font-light">Action</h5>
      <TextField label="Proceed Action" type="text" name="proceed_action" {...register("proceed_action")} />
      <TextField label="Cancel Action" type="text" name="cancel_action" {...register("cancel_action")} />
      <div className="flex mt-3 justify-end items-center">
        <AppButton name="Update" type="submit" loading={props.sending_ebook} className="bg-green-500 text-white h-[50px] px-5 flex items-center justify-center rounded-[0.5rem] transition-all" />
      </div>
    </form>
  );
});

export const FooterSection = connect(mapStateToPropsTwo, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  const { file_name, file_input_name } = props.data;

  const onSubmit = data => {
    data.logo_url = file_name["footer_image"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_SITE_FOOTER, footerURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_footer} />
        </div>
        <FileImageModalOpener label="Image" name="footer_image" control={control} {...register("footer_image", { required: false })} />
        <TextEditor label="Footer Statement" control={control} name="footer_statement" {...register("footer_statement", { required: false })} />
      </div>
    </form>
  );
});

const ExpandableContent = [
  {
    name: "Header",
    Component: props => <HeaderContent {...props} />,
  },
  {
    name: "General Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={siteMetaURL} />,
  },
  {
    name: "Ad Section",
    hasToggle: true,
    Component: props => <AdSectionContent {...props} />,
  },
  {
    name: "Get Started Section",
    Component: props => <GetStartedContent {...props} />,
  },
  {
    name: "Newsletter Section",
    Component: props => <NewsLetter {...props} />,
  },
  {
    name: "Free Ebook Section",
    Component: props => <EbookContent {...props} />,
    hasToggle: true,
  },
  {
    name: "Footer",
    Component: props => <FooterSection {...props} />,
  },
];

const Sites = ({ token, ...props }) => {
  useEffect(() => {
    // call get actions
    props.getAction(GET_HEADER, headerURL, token);
    props.getAction(GET_MAIN_SITE_META, siteMetaURL, token);
  }, []);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <>
      <MainHeader title="Site" />
      <div className="mt-10">
        <MainPageTopPanel />
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  header_data: state.site.main.get_header.data,
  file_name: state.ui.input_file,
  file_input_name: state.ui.input_file?.name,
  meta_data: state.site.main.get_main_meta.data,
});

export default connect(mapStateToProps, { getAction, postAction })(Sites);
