import dayjs from "dayjs";

export const formatTime = data => {
  let H = `${data.getHours()}`;
  let i = `${data.getMinutes()}`;
  if (H.length < 2) H = `0${H}`;
  if (i.length < 2) i = `0${i}`;
  return [H, i].join(":");
};

export const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const isValidDate = date => {
  return Boolean(new Date(date).toString() !== "Invalid Date");
};

export const formatDate = (date, format = "MMM DD, YYYY") => {
  if (date && isValidDate(date)) return dayjs(date).format(format);
  return date;
};

export function calculateReadTime(text) {
  const wordsPerMinute = 200;
  const words = text.split(" ").length;
  const readTimeMinutes = Math.round(words / wordsPerMinute);
  return `${readTimeMinutes} min`;
}

export const getISODate = data => {
  const [onlyD] = data.split("T");
  const newDate = new Date(onlyD);
  return newDate;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const mapStateToPropsHome = state => ({
  token: state.auth.login.token,
  sending_hero: state.site.home.set_hero.sending,
  sending_stats: state.site.home.set_statistics.sending,
  sending_getStarted: state.site.home.set_home_started.sending,
  sending_usp: state.site.home.set_usp.sending,
  sending_blog: state.site.home.set_blog.sending,
  sending_prod: state.site.home.set_home_product.sending,
  tags: state.ui.tag,
});

export const mapStateToPropsAbout = state => ({
  token: state.auth.login.token,
  sending_vision: state.site.about.set_vision.sending,
  sending_mission: state.site.about.set_mission.sending,
  sending_abt: state.site.about.set_abt_gen.sending,
  file_name: state.ui.input_file,
  sending_member: state.site.about.set_member.sending,
});

export const mapStateToPropsTesti = state => ({
  token: state.auth.login.token,
  sending_testi_gen: state.site.testimonial.set_testi_general.sending,
});

export const mapStateToPropsCareer = state => ({
  token: state.auth.login.token,
  sending_career_gen: state.site.career.set_career_gen.sending,
  sending_career_intro: state.site.career.set_career_intro.sending,
  sending_career_content: state.site.career.set_career_content.sending,
  sending_job_open: state.site.career.set_job_open.sending,
});

export const mapStateToPropsFaq = state => ({
  token: state.auth.login.token,
  category_data: state.site.faq.get_faq_category.data,
  sending_faq_gen: state.site.faq.set_faq_general.sending,
  sending_faq_cat: state.site.faq.set_faq_category.sending,
  sending_faq: state.site.faq.set_faq.sending,
});

export const mapStateToPropsContact = state => ({
  token: state.auth.login.token,
  sending_contact: state.site.contact.set_contact.sending,
  sending_contact_form: state.site.contact.set_contact_form.sending,
});

export const mapStateToPropsPrivacy = state => ({
  token: state.auth.login.token,
  sending_policy: state.site.privacy.set_privacy_policy.sending,
});

export const mapStateToPropsTerms = state => ({
  token: state.auth.login.token,
  sending_terms: state.site.term.set_terms.sending,
});
