import ReactDOM from "react-dom/client";
import React from "react";
import MediaFrame from "./frame";

export default function media(context) {
  const container = document.createElement("div");
  const root = ReactDOM.createRoot(container);
  root.render(React.createElement(MediaFrame, { editorContext: context }));
  return {
    toolbarItems: [
      {
        groupIndex: 3,
        itemIndex: 0,
        item: {
          name: "media",
          tooltip: "Media",
          className: "toastui-editor-custom-toolbar-icon media__popupBtn",
          popup: {
            body: container,
            style: { width: "350px" },
          },
        },
      },
    ],
    markdownCommands: {
      insertImage: (payload, state, dispatch) => {
        const link = `![${payload.name}](${payload.url})`;
        const { from, to } = state.selection;
        const tr = state.tr.insertText(link, from, to);
        dispatch(tr.scrollIntoView());
        return true;
      },
    },
    wysiwygCommands: {
      insertImage: (payload, state, dispatch) => {
        const img = state.config.schema.nodes.image.createAndFill({
          imageUrl: payload.src,
          altText: payload.alt,
        });
        dispatch(state.tr.replaceSelectionWith(img).scrollIntoView());
        return true;
      },
    },
  };
}
