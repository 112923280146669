import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  content: "",
  excerpt: "",
  author: "",
  category: "",
  featured_image: "",
  media: [],
};
const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    addMedia(state, action) {
      if (action.payload) {
        const newState = {
          ...state,
          media: [...state.media, action.payload],
        };
        return newState;
      }

      return state;
    },
    removeMedia(state, action) {
      if (action.payload) {
        const updateMedia = state.media.filter(media => media.id !== action.payload.id);

        const newState = { ...state, media: updateMedia };
        return newState;
      }
      return state;
    },
  },
});

export default postSlice.reducer;
