import React from "react";
import { IoBarChartOutline, IoChatboxOutline, IoEarthOutline, IoFlagOutline, IoFlameOutline, IoGitMergeOutline, IoHomeOutline, IoInformationCircleOutline, IoMagnetOutline, IoOpenOutline, IoPricetagOutline, IoSettingsOutline, IoTimeOutline, IoVideocamOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const GoogleAnalytics = [
  {
    title: "Overview",
    icons: <IoHomeOutline size={20} />,
  },
  {
    title: "Realtime",
    icons: <IoTimeOutline size={20} />,
  },
  {
    title: "Acquisition",
    icons: <IoGitMergeOutline size={20} />,
  },
  {
    title: "Engagement",
    icons: <IoPricetagOutline size={20} />,
  },
  {
    title: "Retention",
    icons: <IoMagnetOutline size={20} />,
  },
  {
    title: "Conversions",
    icons: <IoFlagOutline size={20} />,
  },
  {
    title: "Demographics",
    icons: <IoEarthOutline size={20} />,
  },
];

const hotjar = [
  {
    title: "Overview",
    icons: <IoHomeOutline size={20} />,
  },
  {
    title: "Recordings",
    icons: <IoVideocamOutline size={20} />,
  },
  {
    title: "Heatmaps",
    icons: <IoFlameOutline size={20} />,
  },
  {
    title: "Surveys",
    icons: <IoBarChartOutline size={20} />,
  },
  {
    title: "Incoming Feedback",
    icons: <IoChatboxOutline size={20} />,
  },
  {
    title: "Site Information",
    icons: <IoInformationCircleOutline size={20} />,
  },
  {
    title: "Settings",
    icons: <IoSettingsOutline size={20} />,
  },
];

const Analytics = () => {
  const { staff_first_name } = useSelector(state => state.user.userDetails);

  return (
    <div>
      <h1 className="pageTitle">Analytics</h1>
      {staff_first_name && <h3 className="font-medium text-lg md:text-2xl">Hello, {staff_first_name}!</h3>}
      <div className="flex mt-10 gap-6 w-full md:flex-row flex-col">
        <div className="bg-white flex-1 rounded-lg">
          <div className="p-6 mb-3">
            <img src="/Images/google-analytics.png" alt="" className="h-12" />
          </div>
          {GoogleAnalytics.map(item => (
            <div className="flex justify-between py-3 px-4  items-center border-t" key={item.title}>
              <div className="flex items-center gap-3">
                {item.icons}
                <span>{item.title}</span>
              </div>
              <IoOpenOutline size={15} className="text-[#3B82f6]" />
            </div>
          ))}
        </div>
        <div className="bg-white flex-1 rounded-lg">
          <div className="p-6 mb-3">
            <img src="/Images/hotjar.svg" alt="" className="h-12" />
          </div>
          {hotjar.map(item => (
            <div className="flex justify-between py-3 px-4 items-center border-t" key={item.title}>
              <div className="flex items-center gap-3">
                {item.icons}
                <span>{item.title}</span>
              </div>
              <IoOpenOutline size={15} className="text-[#3B82f6]" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
