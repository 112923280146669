import styled from "styled-components";

export const ContentViewDiv = styled.div`
  .content.mid {
    max-width: 80vw;
  }
  .content {
    height: auto;
  }
  main {
    margin-top: 1.5rem;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  @media (max-width: 768px) {
    .content.mid {
      width: 520px;
    }
  }
`;
