import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { connect } from "react-redux";
import { FileImageModalOpener, SelectField, TextField } from "../../components/widget/form/FormComponent";
import { editAction, getAction } from "../../store/actions/creators/request.actions";
import { useGetJobQuery, useUpdateJobMutation } from "../../services/job.services";
import { useGetPositionsQuery } from "../../services/position.services";
import { formatDate } from "../../utils/helpers";
import { toast } from "sonner";

const employment = ["Full Time", "Remote", "Contract"];
const EditJobs = ({ token, getAction, editAction, ...props }) => {
  const { data: position = [] } = useGetPositionsQuery();
  const { state } = useLocation();
  const [updateJob, { isLoading, isSuccess }] = useUpdateJobMutation();
  const { data: job_data = {} } = useGetJobQuery(state?.id, { skip: !state?.id });
  const { handleSubmit, formState, register, control, setValue, watch } = useForm({
    mode: "onChange",
  });
  const [value] = useState(new Date());

  useEffect(() => {
    if (isSuccess) {
      toast.success("Job Updated Successfully");
    }
  }, [isSuccess]);

  useEffect(() => {
    setValue("location", job_data?.location);
    setValue("overview", job_data?.overview);
    setValue("position", job_data?.position);
    setValue("type_of_employment", job_data?.type_of_employment);
  }, [job_data, setValue]);

  let navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [toggle] = useState(false);
  const onSubmit = async data => {
    data.lasts_till = value.toISOString();
    // console.log(JSON.stringify(data));

    updateJob({ id: state?.id, ...data });
    // console.log(value.toISOString());
  };

  const jobLastTillDate = watch("date", null);
  const jobLastTillTime = watch("time", null);

  return (
    <div className="mt-10">
      <div onClick={() => navigate("/jobs/jobs")} className="flex gap-1 cursor-pointer items-center">
        <IoChevronBackOutline className="text-secondary" />
        <span className="text-secondary">Back</span>
      </div>
      <h1 className="subTitle">Edit Job</h1>
      <div>
        <p className="my-1">Publish Job?</p>
        <div className="flex gap-2">
          <Switch checked={enabled} onChange={setEnabled} disabled={toggle} className={`${enabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 mb-6 items-center ${toggle && "cursor-not-allowed"} rounded-full`}>
            <span className="sr-only">Mode</span>
            <span className={`${enabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
          </Switch>
          <p className="mt-[2px]">
            This Job Opening will show on the <span className="text-blue-400 cursor-pointer">Job Openings</span> page.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
        <SelectField item={position} label="Position" control={control} mb="2xl:mb-6 lg:mb-3 mb-6" selected_item="title_of_position" name="position" error={formState.errors.position} {...register("position", { required: false })} />
        <FileImageModalOpener label="Image" control={control} name="Image" value="gdllogo.svg.svg" {...register("Image", { required: false })} />
        <SelectField item={employment} label="Type Of Employment" control={control} mb="2xl:mb-6 lg:mb-3 mb-6" name="type_of_employment" error={formState.errors.type_of_employment} {...register("type_of_employment", { required: false })} />
        <TextField label="Location" name="location" error={formState.errors["location"]} {...register("location", { required: false })} type="text" />
        <TextField label="Overview" name="overview" error={formState.errors["overview"]} {...register("overview", { required: false })} type="textarea" />
        <div>
          <p className="text-black text-opacity-80 mb-3">Vacancy Lasts till</p>

          {/* <DateTimePicker onChange={onChange} value={value} /> */}
          <div className="grid grid-cols-2 gap-8">
            <TextField label="Date" name="date" error={formState.errors.date} {...register("date", { required: false })} type="date" className="" />
            <TextField label="Time" name="time" error={formState.errors.time} {...register("time", { required: false })} type="time" />
          </div>
        </div>
        {jobLastTillDate && jobLastTillTime && (
          <div className="flex gap-2 mt-5">
            <Switch checked={enabled} onChange={setEnabled} disabled={toggle} className={`${enabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 mb-6 items-center ${toggle && "cursor-not-allowed"} rounded-full`}>
              <span className="sr-only">Mode</span>
              <span className={`${enabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
            </Switch>
            <p className="mt-[2px]">
              Automatically Unpublish job after {formatDate(jobLastTillDate)} at {jobLastTillTime}
            </p>
          </div>
        )}

        <div className="flex justify-end pt-10">
          <AppButton name={`Save`} type="submit" loading={isLoading} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.jobs.jobDetails.loading,
  job_data: state.jobs.jobDetails.data,
  edit_data: state.jobs.editJob.data,
  loading_edit: state.jobs.editJob.loading,
  error_edit: state.jobs.editJob.error_msg,
  error_msg: state.jobs.jobDetails.error_msg,
});

export default connect(mapStateToProps, { editAction, getAction })(EditJobs);
