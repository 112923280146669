import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { ProductGeneralContent as ContactGeneral } from "./SiteProduct";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";
import { contactFormURL, contactGeneralURL, contactMetaURL, contactURL } from "../../utils/endpoint";
import { connect } from "react-redux";
import { mapStateToPropsContact } from "../../utils/helpers";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_CONTACT, ADD_CONTACT_FORM, GET_CONTACT, GET_CONTACT_FORM, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { useEffect } from "react";

export const ContactInfo = connect(mapStateToPropsContact, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { contact_data } = props.data;
  contact_data = contact_data && contact_data[contact_data.length - 1];

  const submitForm = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_CONTACT, contactURL, data, props.token);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_contact} />
      </div>
      <TextField label="Title" defaultValue={contact_data?.title} name="title" type="text" {...register("title", { required: false })} />
    </form>
  );
});

export const ContactForm = connect(mapStateToPropsContact, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { contact_form_data } = props.data;
  contact_form_data = contact_form_data && contact_form_data[contact_form_data.length - 1];

  const submitForm = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_CONTACT_FORM, contactFormURL, data, props.token);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_contact_form} />
      </div>
      <TextField label="Caption" defaultValue={contact_form_data?.caption} name="caption" type="textarea" {...register("caption", { required: false })} />
    </form>
  );
});

const ExpandableContent = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={contactMetaURL} />,
  },
  { name: "General", Component: props => <ContactGeneral {...props} url={contactGeneralURL} /> },
  { name: "Contact Information", Component: props => <ContactInfo {...props} /> },
  { name: "Form", Component: props => <ContactForm {...props} /> },
];

const SiteContact = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getAction(GET_SITE_META, contactMetaURL, token);
    // props.getAction(GET_);
    props.getAction(GET_CONTACT, contactURL, token);
    props.getAction(GET_CONTACT_FORM, contactFormURL, token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Contact" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  contact_data: state.site.contact.get_contact.data,
  contact_form_data: state.site.contact.get_contact_form.data,
});
export default connect(mapStateToProps, { getAction })(SiteContact);
