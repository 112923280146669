import { useState } from "react";
import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { TabDiv } from "./details.style";
import { connect } from "react-redux";
import AppModal from "../widget/Modal/Modal";
import { openModal } from "../../store/actions/creators/ui.actions";

const TestTab = ({ tabs, onPress, name, content, defaultIndex, ...props }) => {
  const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <TabDiv className={`${!content ? "mt-16" : "relative"}`}>
        <h3 className={`${content ? "text-[2.25rem] leading-[2.5rem] mt-[1.25rem] mb-[1.5rem] font-semibold" : "mb-[0.75rem] text-[1.875rem] leading-[2.25rem] font-medium"}`}>{name || "Pages"}</h3>
        {content}
        {!props.hideTabs && (
          <Tab.Group defaultIndex={defaultIndex}>
            <Tab.List className={`relative mb-[2rem] ${content ? "mt-10" : "mt-[0.5rem]"} flex flex-wrap items-center justify-start overflow-x-auto`}>
              {Object.keys(tabs).map((content, index) => (
                <Tab
                  key={index}
                  onClick={() => navigate(`${index != 0 ? content.toLowerCase() : ""}`)}
                  className={({ selected }) => classNames("relative outline-none mr-[0.5rem] mb-[0.75rem] tab-default block w-auto cursor-pointer rounded-[0.5rem] px-[1.5rem] py-[0.75rem] shadow-none text-center transition-all duration-[300ms]", selected ? "bg-red-100 text-secondary selected font-normal" : "text-gray-400 font-light")}
                >
                  {content}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        )}
      </TabDiv>
      {/** this modal is for tabs with extra link that might need to open a modal */}
      {content ? <AppModal open={props.open} onClose={() => props.openModal("CLOSE_MODAL")} content={props.modal_content} /> : null}
    </>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal?.open,
});

export default connect(mapStateToProps, { openModal })(TestTab);
