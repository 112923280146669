import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PasswordField, TextField } from "../../components/widget/form/FormComponent";
import { FormContainer } from "./auth.style";
import { connect, useDispatch } from "react-redux";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { Link, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { openModal } from "../../store/actions/creators/ui.actions";
import AppModal from "../../components/widget/Modal/Modal";
import ChangePassword from "./ChangePassword";
import AuthNav from "../../components/widget/Nav/Nav";
import { useAuthLoginMutation, useValidateTokenMutation } from "../../services/auth.services";
import { addUser, setUserToken } from "../../store/slices/user.slice";
import { toast } from "sonner";

const AuthPage = ({ token, postAction, showMessage, getAction, openModal, ...props }) => {
  const { formState, register, setValue, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [authLogin, { error }] = useAuthLoginMutation();
  const [validateToken, { error: validError }] = useValidateTokenMutation();
  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.data?.message === "Your First Time Login? Please Reset Your Password") {
        setValue("");
        openModal("OPEN_MODAL");
      }
    }
    if (validError) {
      toast.error(validError?.data?.message);
    }
  }, [error, openModal, setValue, validError]);

  const [email, setEmail] = useState();

  const submitForm = async formData => {
    setEmail(formData.email);
    // postAction(LOGIN, loginURL, data);
    try {
      setLoading(true);
      const { data } = await authLogin(formData);
      if (data?.data?.access_token) {
        const valid_data = {
          token: data?.data?.access_token,
          ip: props.ip_data?.ip || "41.204.236.114",
          long: props.ip_data?.longitude.toString() || "3.3903",
          lat: props.ip_data?.latitude.toString() || "6.4474",
        };
        dispatch(setUserToken(valid_data?.token));
        const { data: userData } = await validateToken(valid_data);
        dispatch(addUser(userData));
        toast.success("Login Successful");
        navigate("/");
      }
    } catch (error) {
      console.log(error?.message);
    }
    // getAction(GET_IP, ipURL, null, ipRequest);
    setValue("");
    setLoading(false);
  };

  return (
    <div className="h-screen flex flex-col justify-center bg-white">
      <AuthNav {...props} />
      <div className="bg-white flex justify-center h-[94vh] items-center">
        <FormContainer onSubmit={handleSubmit(submitForm)}>
          <div className="mb-5">
            <img src="/images/logo.png" alt="" className="w-10"></img>
          </div>
          <section className="Form">
            <div className="w-full">
              <h2 className="FormTitle">Sign In</h2>
              <div>
                <div className="mb-6">
                  <TextField label="Your Company ID" name="email" error={formState.errors["email"]} {...register("email", { required: true })} type="text" />
                  <PasswordField label="Your Password" name="password" error={formState.errors.password} {...register("password", { required: true })} />
                </div>
                <AppButton type="submit" className="bg-[#AE1931] py-3 mb-4 rounded-lg text-white px-4 w-full" name="Continue →" loading={loading} />
                <div className="flex justify-between">
                  <Link to={"/auth/recover-password"} className="cursor-pointer font-thin text-[#AE1931]">
                    Recover My Password →
                  </Link>
                  {/* <Link to={"/auth/reset-password"} className="cursor-pointer font-thin text-[#AE1931]">
                  Reset Password →
                </Link> */}
                </div>
              </div>
            </div>
          </section>
          <AppModal open={props.open} onClose={() => openModal("CLOSE_MODAL")} content={<ChangePassword email={email} />} />
        </FormContainer>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.open,
  modalMsg: state.ui.open_msg?.response_msg,
  error_msg: state.auth.login.error_msg,
  ip_data: state.auth.get_ip.data,
  error: state.auth.login.error,
  ip_loading: state.auth.get_ip.loading,
});

export default connect(mapStateToProps, { postAction, getAction, openModal })(AuthPage);
