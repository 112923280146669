import axios from "axios";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { RiCloseLine, RiImageLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

const DropZone = ({ onClose }) => {
  const post = useSelector(state => state.postSlice);
  const dispatch = useDispatch();
  const saveImage = async (image, size) => {
    const formData = new FormData();

    formData.append("upload_preset", "rstorkdu");
    formData.append("file", image);

    try {
      const { data } = await axios.post("https://api.cloudinary.com/v1_1/dbkiemdqb/image/upload", formData);
      dispatch({
        type: "post/addMedia",
        payload: {
          id: data.public_id,
          name: image.name,
          url: data.secure_url,
          size,
        },
      });
      return data.secure_url;
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = new Uint8Array(reader.result);

        const fileSize = binaryStr.byteLength / 1024 ** 2;
        if (fileSize > 10) {
          console.log("File size is larger than 10mb");
          return;
        }

        saveImage(file, fileSize.toFixed(2));
      };

      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "image/jpeg": [".jpeg", ".jpg"], "image/png": [".png"], "image/webp": [".webp"] },
  });

  const deleteMedia = mediaId => {
    dispatch({
      type: "post/removeMedia",
      payload: {
        id: mediaId,
      },
    });
  };

  return (
    <div className="mt-2">
      <h4 className="text-base font-semibold">Uploads</h4>
      {post?.media?.length > 0 && (
        <div className="grid grid-cols-2 gap-5 max-h-[180px] border rounded-lg overflow-y-scroll p-3">
          {post?.media.map(item => {
            return (
              <div key={item.id} className="flex gap-4 w-full items-center">
                <img src={item.url} alt={item.name} className="rounded-md bg-contain w-10 h-10" />
                <div className="flex flex-col justify-between w-full">
                  <div className="flex justify-between w-full gap-4">
                    <span>{item.name}</span>
                    <RiCloseLine size={"14"} color="red" cursor={"pointer"} onClick={() => deleteMedia(item.id)} />
                  </div>
                  <span>{item.size || "0"}mb</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="py-2 mt-2 px-4 border border-primary/50 border-dashed flex flex-col justify-center items-center h-full w-full rounded-md hover:border-secondary" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <p>Drag and drop a image or click to select</p>
          <p>(10mb max)</p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex items-center gap-5 mt-5">
          <button onClick={onClose} className="px-4 rounded-lg h-10 bg-secondary border-secondary border text-white font-medium capitalize">
            save
          </button>
          <button onClick={onClose} className="px-4 rounded-lg h-10 border border-secondary text-secondary font-medium capitalize">
            cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
