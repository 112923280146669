import { DateTime } from "luxon";
import { formatTime } from "../../utils/helpers";

export const appointmentProcessData = (data, idx) => ({
  "#": idx + 1,
  ID: data.id,
  Visitor: data.visitor_name,
  Visitee: data.visitee,
  Time: formatTime(new Date(data.time_of_appointment)),
});

export const appointmentOldProcessData = (data, idx) => ({
  "#": idx + 1,
  ID: data.id,
  Visitor: data.visitor_name,
  Visitee: data.visitee,
  Date: DateTime.fromISO(data.time_of_appointment).toLocaleString(
    DateTime.DATE_FULL
  ),
  Time: formatTime(new Date(data.time_of_appointment)),
});
