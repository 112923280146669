import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
};

const getHeaderReducer = (state = intitialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HEADER:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setHeaderReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_HEADER}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_HEADER:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getMainSiteMetaReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_MAIN_SITE_META}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_MAIN_SITE_META:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setMainSiteMetaReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_MAIN_SITE_META}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_MAIN_SITE_META:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSiteMetaReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_SITE_META}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SITE_META:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setSiteMetaReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_SITE_META}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_SITE_META:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSiteGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_SITE_GENERAL}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SITE_GENERAL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setSiteGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_SITE_GENERAL}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_SITE_GENERAL:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSiteAdSectionReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_AD_SECTION}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_AD_SECTION:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setSiteAdSectionReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_AD_SECTION}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_AD_SECTION:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSiteGetStartedReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_SITE_GET_STARTED}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SITE_GET_STARTED:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setSiteGetStartedReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_SITE_GET_STARTED}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_SITE_GET_STARTED:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getNewsLetterReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_NEWSLETTER}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NEWSLETTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setNewsLetterReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_NEWSLETTER}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_NEWSLETTER:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getEbookReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_EBOOK}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_EBOOK:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setEbookReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_EBOOK}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_EBOOK:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSiteFooterReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_SITE_FOOTER}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SITE_FOOTER:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setSiteFooterReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_SITE_FOOTER}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_SITE_FOOTER:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const mainReducer = combineReducers({
  get_header: getHeaderReducer,
  set_header: setHeaderReducer,
  get_main_meta: getMainSiteMetaReducer,
  set_main_meta: setMainSiteMetaReducer,
  get_site_meta: getSiteMetaReducer,
  set_site_meta: setSiteMetaReducer,
  get_ad_section: getSiteAdSectionReducer,
  set_ad_section: setSiteAdSectionReducer,
  get_site_getstarted: getSiteGetStartedReducer,
  set_site_getstarted: setSiteGetStartedReducer,
  get_newsletter: getNewsLetterReducer,
  set_newsletter: setNewsLetterReducer,
  get_ebook: getEbookReducer,
  set_ebook: setEbookReducer,
  get_footer: getSiteFooterReducer,
  set_footer: setSiteFooterReducer,
  get_site_general: getSiteGeneralReducer,
  set_site_general: setSiteGeneralReducer,
});
