import { combineReducers } from "redux";

const initialState = {
  open: false,
  open_view: false,
  open_edit: false,
  open_delete: false,
  tags: null,
  toggle_input: null,
  new_post: "",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...state,
        open: true,
        data: action.payload,
      };
    case "VIEW_MODAL":
      return {
        ...state,
        open_view: true,
        data: action.payload,
      };
    case "EDIT_MODAL":
      return {
        ...state,
        open_edit: true,
        data: action.payload,
      };
    case "DELETE_MODAL":
      return {
        ...state,
        open_delete: true,
        data: action.payload,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        open: false,
        open_view: false,
        open_edit: false,
        open_delete: false,
      };
    default:
      return state;
  }
};

const showMessageModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MSG_MODAL":
      return {
        ...state,
        msg_modal: true,
        response_msg: action.payload[0],
        msg_type: action.payload[1],
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        msg_modal: false,
        callback: action.payload[0],
        loading: false,
      };
    default:
      return state;
  }
};

const fileUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_IMG_URL":
      return {
        ...state,
        [action.payload[0]]: action.payload[1],
        name: action.payload[0],
      };
    default:
      return state;
  }
};
const relatedPostReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_RELATED_POSTS":
      return {
        ...state,
        [action.payload[0]]: action.payload[1],
        name: action.payload[0],
      };
    default:
      return state;
  }
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TAGS":
      return {
        ...state,
        [action.payload[0]]: action.payload[1],
        name: action.payload[0],
      };
    default:
      return state;
  }
};

const togglerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOGGLE_INPUT":
      return {
        ...state,
        toggle_input: action.payload,
      };
    default:
      return state;
  }
};

const createNewPostBody = (state = initialState, action) => {
  switch (action.type) {
    case "SET_POST_CONTENT":
      return {
        ...state,
        new_post: action.payload[0],
      };
    case "CLEAR_POST_CONTENT":
      return {
        ...state,
        new_post: "",
      };
    default:
      return state;
  }
};

export const uiReducer = combineReducers({
  modal: modalReducer,
  open_msg: showMessageModalReducer,
  input_file: fileUploadReducer,
  toggler: togglerReducer,
  tag: tagsReducer,
  relatedPost: relatedPostReducer,
  createNewPost: createNewPostBody,
});
