import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoCloseOutline, IoPersonOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";
import { ADD_JOB_POSITION, GET_JOB_POSITIONS } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { positionURL } from "../../utils/endpoint";

const AddPositions = ({ postAction, data, openModal, getAction, ...props }) => {
  const { formState, register, handleSubmit } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (data) {
      getAction(GET_JOB_POSITIONS, positionURL);
      openModal("CLOSE_MODAL");
    }
  }, [getAction, data, openModal]);

  const onSubmit = data => {
    console.log(JSON.stringify(data));
    postAction(ADD_JOB_POSITION, positionURL, data);
  };

  console.log(props.error);
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex flex-col items-center justify-center bg-white rounded-lg h-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-white w-full rounded-lg md:w-full">
          <div className="relative flex items-center gap-2 flex-col my-4 px-6 pt-12">
            <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
            <IoPersonOutline className="text-[#D4D4D4]" size={50} />
            <h2 className="font-semibold text-xl md:text-3xl mt-2 mb-1">Add Position</h2>
            <p className="text-center">What would you like to call the new position?</p>
          </div>
          <main>
            <div className="px-8">
              <TextField label="Title of Position" name="title_of_position" error={formState.errors["title_of_position"]} {...register("title_of_position", { required: false })} type="text" />
              <TextField label="Job Description" name="job_description" error={formState.errors["job_description"]} {...register("job_description", { required: true })} type="textarea" />
              <TextField label="Job Responsibilities" name="job_responsibilities" error={formState.errors["job_responsibilities"]} {...register("job_responsibilities", { required: false })} type="textarea" />
            </div>
          </main>
          <footer>
            <div className="flex justify-end p-4">
              <div className="flex gap-2">
                <AppButton loading={props.loading} name="Save" type="submit" />
                <AppButton name="Cancel" onPress={() => openModal("CLOSE_MODAL")} className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" />
              </div>
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal?.open,
  token: state.auth.login.token,
  loading: state.jobs.addJobPosition.loading,
  data: state.jobs.addJobPosition.data,
  error_msg: state.jobs.addJobPosition.error_msg,
  error: state.jobs.addJobPosition.error,
});

export default connect(mapStateToProps, { openModal, postAction, getAction })(AddPositions);
