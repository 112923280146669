import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  ADD_NEWSLETTER_TEMPLATE,
  GET_NEWSLETTER_TEMPLATE,
} from "../../store/actions/action_types/action.types";
import {
  getAction,
  postAction,
} from "../../store/actions/creators/request.actions";
import { newsLetterURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { TextField } from "../widget/form/FormComponent";

const NewTemplate = ({ token, getAction, postAction, ...props }) => {
  const { handleSubmit, setValue, getValues, formState, register } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    getAction(GET_NEWSLETTER_TEMPLATE, newsLetterURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("template", props.data[0]?.template);
    }
  }, [props.data, setValue]);

  const submitHandler = data => {
    console.log(data);
    postAction(ADD_NEWSLETTER_TEMPLATE, newsLetterURL, data, token);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <TextField
          label="Template*"
          name="template"
          error={formState.errors.template}
          {...register("template", { required: false })}
          type="textarea"
        />
        <div className="flex justify-end pt-5">
          <AppButton name={`Save`} loading={props.loading} type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addNewsletterTemplate.loading,
  data: state.settings.newsletterTemplate.data,
  postData: state.settings.addNewsletterTemplate.data,
  error_msg: state.settings.addNewsletterTemplate.error_msg,
  fetch_error_msg: state.settings.newsletterTemplate.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(NewTemplate);
