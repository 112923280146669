import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
  success: null,
};

const getHeroReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_HERO}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HERO:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setHeroReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_HERO}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_HERO:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getStatisticsReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_STATISTICS}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_STATISTICS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setStatisticsReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_STATISTICS}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_STATISTICS:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHomeStartedReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_HOME_GET_STARTED}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HOME_GET_STARTED:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setHomeStartedReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_HOME_GET_STARTED}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_HOME_GET_STARTED:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUSPReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_USP}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USP:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setUSPReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_USP}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_USP:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getTestimonialReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_TESTIMONIAL}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_TESTIMONIAL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.data.filter(content => content.show_testimonial == true).length,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setTestimonialReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_TESTIMONIAL}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_TESTIMONIAL:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const updateTestimonialReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.UPDATE_TESTIMONIAL}`:
      return {
        ...state,
        sending: true,
      };
    case type.UPDATE_TESTIMONIAL:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHomeBlogReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_HOME_BLOG}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HOME_BLOG:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setHomeBlogReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_HOME_BLOG}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_HOME_BLOG:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHomeProductReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_HOME_PRODUCT}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HOME_PRODUCT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setHomeProductReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_HOME_PRODUCT}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_HOME_PRODUCT:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const homeReducer = combineReducers({
  get_hero: getHeroReducer,
  set_hero: setHeroReducer,
  get_statistics: getStatisticsReducer,
  set_statistics: setStatisticsReducer,
  get_home_started: getHomeStartedReducer,
  set_home_started: setHomeStartedReducer,
  get_usp: getUSPReducer,
  set_usp: setUSPReducer,
  get_testimonials: getTestimonialReducer,
  set_testimonials: setTestimonialReducer,
  update_testimonials: updateTestimonialReducer,
  get_blog: getHomeBlogReducer,
  set_blog: setHomeBlogReducer,
  get_home_product: getHomeProductReducer,
  set_home_product: setHomeProductReducer,
});
