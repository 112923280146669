import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "../../components/widget/form/FormComponent";
import { FormContainer } from "./auth.style";
import { connect } from "react-redux";
import { postAction } from "../../store/actions/creators/request.actions";
import { Link, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import AuthNav from "../../components/widget/Nav/Nav";
import { useResetEmailMutation } from "../../services/auth.services";
import { toast } from "sonner";

const RecoverPassword = ({ postAction, resetSuccess, loading, ...props }) => {
  const { formState, register, setValue, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [email, setEmail] = useState("");
  const [resetEmail, { error, isLoading }] = useResetEmailMutation();
  let navigate = useNavigate();
  useEffect(() => {
    if (resetSuccess) {
      console.log(resetSuccess);
      navigate("/auth/reset-password", { state: { requestToken: resetSuccess?.data, email: email } });
    }
  }, [resetSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(error.data?.message);
    }
  }, [error]);

  const submitForm = async data => {
    setEmail(data.email);
    // postAction(RESETEMAIL, resetEmailURL, data);
    const response = await resetEmail(data);
    console.log(response);
    setValue("");
  };
  return (
    <div className="h-screen flex flex-col justify-center bg-white">
      <AuthNav {...props} />
      <div className="bg-white flex justify-center h-[94vh] items-center">
        <FormContainer onSubmit={handleSubmit(submitForm)}>
          <div className="mb-5">
            <img src="/images/logo.png" alt="" className="w-10"></img>
          </div>
          <section className="Form">
            <div className="w-full">
              <h2 className="FormTitle">Recover My Password</h2>
              <div>
                <Link to={"/auth/login"} className="cursor-pointer font-thin text-[#AE1931]">
                  ← Back to Sign In
                </Link>
                <p className="cursor-pointer font-light my-3 text-opacity-80 text-black">We will send you a reset link with which you can reset your password.</p>
                <div className="mb-6">
                  <TextField label="Your Company Email" error={formState.errors["email"]} {...register("email", { required: true })} type="text" />
                </div>
                <AppButton name="Continue →" type="submit" className="bg-[#AE1931] py-3 rounded-lg text-white px-4 w-full" loading={isLoading} />
              </div>
            </div>
          </section>
        </FormContainer>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.resetEmail.loading,
  resetSuccess: state.auth.resetEmail.successFlag,
  token: state.admin.token,
  error_msg: state.admin.error_msg,
  error: state.admin.error,
});

export default connect(mapStateToProps, { postAction })(RecoverPassword);
