import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { FileImageModalOpener, TextEditor, TextField } from "../../components/widget/form/FormComponent";
import { useEffect, useState } from "react";
import { IoEye, IoPersonAddOutline, IoStarHalfOutline } from "react-icons/io5";
import { aboutGenURL, aboutMetaURL, boardAbtURL, managementAbtURL, missionURL, visionURL } from "../../utils/endpoint";
import { AppButton } from "../../components/widget/button/Button";
import { connect } from "react-redux";
import { postAction } from "../../store/actions/creators/request.actions";
import { ADD_ABOUT_GEN, ADD_MEMBER, ADD_MISSION, ADD_VISION, GET_ABOUT_GEN, GET_BOARD_MEMBER, GET_MANAGEMNT_MEMBER, GET_MEMBER, GET_MISSION, GET_SITE_GENERAL, GET_SITE_META, GET_VISION } from "../../store/actions/action_types/action.types";
import { mapStateToPropsAbout } from "../../utils/helpers";
import { ActionView } from "../../components/widget/Actions/Action";
import { getAction } from "../../store/actions/creators/request.actions";

const AddMember = connect(mapStateToPropsAbout, { postAction })(props => {
  const { register, control, formState, handleSubmit, getValues } = useForm({ mode: "onChange" });

  // useEffect(() => {
  //   if (props.resp != null && getValues("name") != "") {
  //     alert("successful");
  //   }
  // }, [props.resp]);

  const onSubmit = data => {
    data.show_testimonial = data.show_testimonial == "" ? false : data.show_testimonial;
    data.image_url = props.file_name["img_url"]?.image_url;

    // alert(JSON.stringify(data));
    props.postAction(ADD_MEMBER, props.url, data, props.token);
  };
  return (
    <div className="relative flex items-center justify-center transition-all p-4 duration-300">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex flex-col items-center justify-center bg-white shadow-xl rounded-lg w-[520px] max-w-[95vw] h-auto">
          <a></a>
          <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
            <div className="flex flex-col justify-center items-center text-center px-2">
              <div className="text-6xl text-gray-300 mt-3 mb-2">
                <IoPersonAddOutline />
              </div>
              <div className="font-semibold text-xl md:text-3xl mb-1">Add {props.name} Member</div>
            </div>
          </header>
          <main className="py-3 max-h-[50vh] mt-[1.5rem] h-[100%] w-[100%] overflow-y-auto overflow-x-hidden px-[1.5rem] transition-all">
            <div className="w-full px-3">
              <FileImageModalOpener label="Image" control={control} name="img_url" {...register("img_url", { required: false })} />
              <TextField label="Name" type="text" error={formState.errors.name} {...register(props.name !== "Board" ? "staff_name" : "director_name", { required: false })} />
              <TextField label="Portfolio" type="text" error={formState.errors.portfolio} {...register("porfolio", { required: false })} />
              <TextEditor label="Profile" control={control} {...register("profile", { required: false })} />
            </div>
          </main>
          <footer className="w-full">
            <div className="bg-gray-50 w-full px-4 py-4 sm:px-9 sm:flex sm:flex-row-reverse">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Cancel
              </button>
              <AppButton name="Save" type="submit" loading={props.sending_member} />
            </div>
          </footer>
        </div>
      </form>
    </div>
  );
});

const TeamContent = ({ image_url, ...props }) => (
  <div className="relative flex items-start justify-start rounded-[0.5rem] bg-opacity-[1] bg-gray-100" key={props.id}>
    <div className="w-[5rem] p-[1rem]">
      <img src={image_url || "https://res.cloudinary.com/gdlapp/image/upload/v1623434245/image/6dd2c85d-2788-46a8-839b-7f1c8b528d2c.jpg"} />
    </div>
    <div className="py-4 pr-4">
      <div className="">
        <div className="font-medium text-lg">{props.staff_name || props.director_name}</div>
      </div>
      <div className="mt-4">
        <ActionView icon={<IoEye />} id={props.id} />
      </div>
    </div>
  </div>
);

const Team = props => {
  const [open, setOpen] = useState(false);
  const { m_member_data, board_member_data } = props.data;
  const member_data = props.type !== "board" ? m_member_data : board_member_data;

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="pb-[1rem] transition-all">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">{member_data && member_data.map(content => <TeamContent {...content} />)}</div>
      </div>
      {/* <AppModal open={open}  onClose={() => setOpen(false)} content={<RightModalContent />} /> */}
    </>
  );
};

const AboutGeneralContent = connect(mapStateToPropsAbout, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { about_gen_data } = props.data;
  about_gen_data = about_gen_data && about_gen_data[about_gen_data.length - 1];

  const onSubmit = data => {
    data.bg_image_url = props.file_name["about_img"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_ABOUT_GEN, aboutGenURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" loading={props.sending_abt} type="submit" />
        </div>
        <FileImageModalOpener defaultValue={about_gen_data?.bg_image_url} label="Background Image" control={control} name="about_img" {...register("about_img", { required: false })} />
        <TextField label="Title" defaultValue={about_gen_data?.title} type="textarea" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={about_gen_data?.description} name="description" type="textarea" {...register("description", { required: false })} />
      </div>
    </form>
  );
});

const AboutVisionContent = connect(mapStateToPropsAbout, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { vision_data } = props.data;
  vision_data = vision_data && vision_data[vision_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_VISION, visionURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_vision} />
        </div>
        <TextField label="Title" defaultValue={vision_data?.title} type="text" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={vision_data?.description} type="textarea" {...register("description", { required: false })} />
      </div>
    </form>
  );
});
const AboutMissionContent = connect(mapStateToPropsAbout, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  let { mission_data } = props.data;
  mission_data = mission_data && mission_data[mission_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_MISSION, missionURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_mission} />
        </div>
        <TextField defaultValue={mission_data?.title} label="Title" type="text" {...register("title", { required: false })} />
        <TextField defaultValue={mission_data?.description} label="Description" type="textarea" {...register("description", { required: false })} />
      </div>
    </form>
  );
});

const SiteAbout = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={aboutMetaURL} />,
    },
    {
      name: "General",
      Component: props => <AboutGeneralContent {...props} />,
    },
    {
      name: "Our Vision",
      Component: props => <AboutVisionContent {...props} />,
    },
    {
      name: "Our Mission",
      Component: props => <AboutMissionContent {...props} />,
    },
    {
      name: "Management Team",
      Component: props => <Team {...props} />,
      hasCount: props.m_member_data?.length,
      hasPlus: true,
      modalcontent: <AddMember name="Team" url={managementAbtURL} />,
    },
    {
      name: "Board of Directors",
      Component: props => <Team {...props} type="board" />,
      hasCount: props.board_member_data?.length,
      hasPlus: true,
      modalcontent: <AddMember name="Board" url={boardAbtURL} />,
    },
  ];

  useEffect(() => {
    props.getAction(GET_SITE_META, aboutMetaURL, token);
    props.getAction(GET_ABOUT_GEN, aboutGenURL, token);
    props.getAction(GET_VISION, visionURL, token);
    props.getAction(GET_MISSION, missionURL, token);
    props.getAction(GET_MANAGEMNT_MEMBER, managementAbtURL, token);
    props.getAction(GET_BOARD_MEMBER, boardAbtURL, token);
  }, []);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="About" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_loading: state.site.main.get_site_meta.loading,
  meta_data: state.site.main.get_site_meta.data,
  about_gen_data: state.site.about.get_abt_gen.data,
  vision_data: state.site.about.get_vision.data,
  mission_data: state.site.about.get_mission.data,
  m_member_data: state.site.about.get_member.data,
  board_member_data: state.site.about.get_member.board_data,
});
export default connect(mapStateToProps, { getAction })(SiteAbout);
