import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { RadioGroup, Switch } from "@headlessui/react";
import { CheckboxDiv, Fieldset, OverlayDiv, PassType, SelectInput, FileDiv, ToggleContainer, MediaFiles, RadioDiv } from "./form.style";
import { Controller } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { IoAlertCircleOutline, IoCheckmark, IoCloudUploadOutline, IoEye, IoEyeOff, IoImage } from "react-icons/io5";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck, HiSelector } from "react-icons/hi";
import AppModal from "../Modal/Modal";
import ReactDOM from "react-dom/client";
import { fileUploader, openModal, setTags, setRelatedPost, addToggle, setNewPostContent, clearPostContent } from "../../../store/actions/creators/ui.actions";
import { getBase64 } from "../../../utils/helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { postAction, getAction } from "../../../store/actions/creators/request.actions";
import { ADD_MEDIA, GET_MEDIA, UPLOAD_CLOUDINARY } from "../../../store/actions/action_types/action.types";
import { fileUpload } from "../../../api/request.api";
import { mediaURL } from "../../../utils/endpoint";
import OtpInput from "react-otp-input";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useController } from "react-hook-form";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { Editor } from "@toast-ui/react-editor";
import { PasteListener } from "../../../utils/editor";
import media from "../../editor-plugins/media";
import DropZone from "../DropZone";

export const EditorContentOverride = "%OVERRIDE@EDITOR@MARKDOWN%";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const PinInput = React.forwardRef(({ label, name, error, otp_length, height, control }, ref) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, ...props }) => (
        <OtpInput
          value={value}
          onChange={onChange}
          numInputs={6}
          // OTPLength={otp_length || 4}
          // secure
          separator={<span></span>}
          inputStyle={{
            width: "2.5rem",
            height: "3rem",
            margin: "0 0.5rem",
            fontSize: "1rem",
            borderRadius: 4,
            border: "1px solid rgba(0,0,0,0.3)",
            background: "#F3F6F7",
          }}
        />
      )}
    />
  );
});

export const CustomEditor = ({ name, control, defaultValue }) => {
  const {
    field: { ref, onChange, value },
  } = useController({ name, control, defaultValue });
  const [quill, setQuill] = useState(null);
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = new Quill(editorRef.current, {
      theme: "snow",
      modules: {
        toolbar: [[{ header: [1, 2, 3, false] }], ["bold", "italic", "underline"], ["image", "link", "video"]],
      },
    });

    editor.on("text-change", () => {
      onChange(editor.root.innerHTML);
    });

    editor.root.innerHTML = value;
  }, [onChange, value]);

  return (
    <div>
      <div ref={editorRef} />
    </div>
  );
};

export const postEditorRef = {
  current: null,
};

export const TextEditor = React.forwardRef(({ label, name, control, defaultValue = "", getText }, ref) => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const postDetails = useSelector(state => state.post.postDetails.data?.data);
  const [open, setOpen] = useState(false);

  // insert undo redo buttons to editor
  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.getInstance();
      const undoBtn = document.createElement("div");
      ReactDOM.createRoot(undoBtn).render(
        <button
          type="button"
          className="toastui-editor-custom-toolbar-icon undo__toolbarBtn"
          onClick={() => {
            const wikiContent = postDetails?.body.replace(/ {2}\n/gm, "").trim();
            const editorText = editorInstance.getMarkdown().replaceAll("\n", "").trim();
            if (wikiContent !== editorText) editorInstance.exec("undo");
          }}
        >
          {" "}
        </button>
      );
      const actions = ["undo", "redo"];
      actions.forEach((e, i) => {
        editorInstance.removeToolbarItem(e);
        editorInstance.insertToolbarItem(
          {
            groupIndex: 0,
            itemIndex: i,
          },
          {
            name: e,
            command: e,
            className: `toastui-editor-custom-toolbar-icon ${e}__toolbarBtn`,
            tooltip: e.charAt(0).toUpperCase() + e.slice(1),
            el: e === "undo" ? undoBtn : undefined,
          }
        );
      });
    }
  }, [postDetails]);

  // when markdown changes, update the editor
  const updateEditorText = useCallback(text => {
    const editorInstance = editorRef.current?.getInstance();
    if (editorInstance && editorInstance.getMarkdown() !== text) {
      editorInstance.setMarkdown(text, false);
    }
  }, []);

  useEffect(() => {
    updateEditorText(defaultValue);
  }, [defaultValue, updateEditorText]);

  // when there is a change in the editor, update the markdown
  const handleOnEditorChange = useCallback(() => {
    const onChange = (val, isInitSet) => {
      if (isInitSet) {
        // dispatch();
        console.log({ val });
        dispatch(clearPostContent());
      } else {
        dispatch(setNewPostContent(val));
      }
    };
    const currentMd = editorRef.current?.getInstance().getMarkdown().toString();
    if (defaultValue !== currentMd) {
      if (currentMd && currentMd !== "Write\nPreview\n\nMarkdown\nWYSIWYG") {
        onChange(currentMd);
      } else {
        onChange(" ");
      }
    }
  }, [editorRef, defaultValue]);

  useEffect(() => {
    const editorWrapper = document.querySelector("div.ProseMirror.toastui-editor-contents");
    editorWrapper?.addEventListener("paste", PasteListener, true);

    return () => editorWrapper?.removeEventListener("paste", PasteListener);
  }, []);

  return (
    <Fieldset className="mb-7">
      <div className="flex justify-between mb-2 items-center">
        <label htmlFor={name} className="text-black font-light text-opacity-80">
          {label}
        </label>
        <button type="button" onClick={() => setOpen(true)} className="bg-secondary px-3 py-1 rounded-md text-white">
          Add Images
        </button>
      </div>
      <Editor
        ref={editorRef}
        previewStyle="vertical"
        onChange={handleOnEditorChange}
        plugins={[media]}
        initialValue={defaultValue}
        height="500px"
        autofocus={false}
        initialEditType="wysiwyg"
        toolbarItems={[
          ["heading", "bold", "italic", "strike"],
          ["hr", "quote"],
          ["ul", "ol", "indent", "outdent"],
          ["table", "code"],
        ]}
      />
      <AppModal open={open} onClose={() => setOpen(false)} content={<EmbedImageModal onClose={() => setOpen(false)} />} />
    </Fieldset>
  );
});

const EmbedImageModal = ({ onClose }) => {
  return (
    <div className="bg-white min-w-[400px] h-full p-5 flex flex-col rounded-lg">
      <h3 className="text-xl font-medium">Add Image to Media Gallery</h3>
      <p>You can upload jpg, png and webp files</p>
      <DropZone onClose={onClose} />
    </div>
  );
};

export const TextField = React.forwardRef(({ label, type, name, control, defaultValue = "", onChange, value, className, error, placeholder }, ref) => {
  return (
    <Fieldset className="mb-7">
      <label htmlFor={name} className="text-black font-light text-opacity-80">
        {label}
      </label>
      {type === "textarea" && <textarea name={name} rows={5} defaultValue={defaultValue} value={value} id={name} ref={ref} onChange={onChange} />}
      {["email", "text", "number", "file", "date", "time"].includes(type) &&
        (type === "number" ? (
          <Controller name={name} render={({ field: { onChange } }) => <input type={type || "text"} placeholder={placeholder} onChange={e => onChange(parseInt(e.target.value, 10))} />} control={control} />
        ) : (
          <input type={type} name={name} id={name} placeholder={placeholder} defaultValue={defaultValue} onChange={onChange} ref={ref} value={value} className={`text-sm ${error && "border border-red-500"} placeholder:text-sm ${className}`} />
        ))}
    </Fieldset>
  );
});

export const PasswordField = React.forwardRef(({ label, name, defaultValue, onChange, error, my, placeholder }, ref) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fieldset className={`w-full ${my}`}>
      <div className="flex items-center pr-3 justify-between">
        <legend>{label}</legend>
        <span className="p_visible" onClick={() => setVisible(!visible)}>
          {!visible ? <IoEye size={20} /> : <IoEyeOff size={20} />}
        </span>
      </div>
      <PassType>
        <input type={visible ? "text" : "password"} name={name} id={name} placeholder={placeholder} defaultValue={defaultValue} onChange={onChange} ref={ref} className={`border ${error && "border-red-500"}`} />
      </PassType>
    </Fieldset>
  );
});

export const SelectField = React.forwardRef(({ label, name, item, action, value, error, mb, selected_item, control, defaultValue, className, disabled, onPress, color }, ref) => {
  const [selected, setSelected] = useState(defaultValue || "Select One");
  const [selectvalue, setSelectvalue] = useState("");

  return (
    <Fieldset className={`${mb || "mb-5"}`}>
      <label htmlFor={name} className="text-black font-light text-opacity-80">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, ...props }) => {
          return (
            <Listbox
              value={selectvalue || value}
              className="outline-none"
              onChange={e => {
                onChange(e);
                setSelected(e[selected_item] || e);
                setSelectvalue(e[value] || e);
                action && action(e);
              }}
            >
              {({ open }) => (
                <>
                  <div onClick={onPress} className={`pt-3 ${error && "border-red-500"}  ${disabled ? `bg-gray-100 ${color || "text-gray-400"}` : "bg-[#c2cfd633]"} rounded-lg mt-1 relative w-full ${className}`}>
                    <Listbox.Button className="relative w-full pb-3.5 px-3 text-right cursor-pointer font-light text-sm text-gray-400 outline-none">
                      <span className="flex items-center">
                        <span className="block truncate text-primary text-lg">{disabled ? defaultValue : value ? value[selected_item] || value : String(selected).replace(/_/g, " ")}</span>
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-start pr-0 pointer-events-none">
                        <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
                    {!disabled && (
                      <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-28 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {item?.map((content, index) => (
                            <Listbox.Option key={index} className={({ active }) => classNames(active ? "text-white bg-gray-400" : "text-gray-900", "cursor-default select-none relative py-2 pl-3 pr-9 outline-none hover:text-white")} value={content}>
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected ? "font-semibold" : "font-normal"
                                        // "block truncate"
                                      )}
                                    >
                                      {content[selected_item] || content}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span className={classNames(active ? "text-white" : "text-red-800", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                                      <HiCheck className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    )}
                  </div>
                </>
              )}
            </Listbox>
          );
        }}
      />
    </Fieldset>
  );
});

export const Checkbox = React.forwardRef(({ label, name, onPress, isChecked, handleClick, id, control, value, size, type }, ref) => {
  return (
    <ToggleContainer className="my-4">
      <CheckboxDiv className="">
        <span className="">
          <label className="cursor-pointer flex">
            <input
              type="checkbox"
              className="bigcheck"
              name={name}
              // value={value}
              onChange={handleClick}
              checked={isChecked}
              id={id}
              // ref={ref}
            />
            <div className={`w-6 h-6 rounded flex items-center justify-center ${isChecked ? "bg-red-100" : "bg-gray-200"} text-primary`}>{isChecked && <IoCheckmark size={15} className="text-red-400" />}</div>
            <label htmlFor={name} className="text-sm text-black font-light mx-2 cursor-pointer" onClick={onPress}>
              {label}
            </label>
          </label>
        </span>
      </CheckboxDiv>
    </ToggleContainer>
  );
});

let UploadedImage = ({ image_url, image_title, name, ...props }) => (
  <div
    className="w-full lg:w-1/2 px-2 mb-4"
    onClick={() => {
      props.fileUploader("GET_IMG_URL", name, { image_url, image_title });
      props.openModal("CLOSE_MODAL");
    }}
  >
    <div className="relative cursor-default rounded-[0.5rem] bg-opacity-100 p-[0.5rem] media">
      <div className="Image">
        <img src={image_url} alt="" />
      </div>
      <div className="p-2 mt-2">
        <div className="flex items-center font-normal text-base overflow-ellipsis overflow-hidden whitespace-nowrap">
          <div className="mr-1 flex items-center justify-center text-opacity-100 text-grey">
            <IoImage />
          </div>
          <span title="Building Wealth in Your 9-5 (1).jpg">{image_title}</span>
        </div>
        <div className="text-black text-opacity-50 overflow-hidden whitespace-nowrap overflow-ellipsis">{"285.3KB"}</div>
        <div className="text-black text-opacity-50 overflow-hidden whitespace-nowrap overflow-ellipsis">{"1750x1166"}</div>
        {/* <div className="text-black text-opacity-50 overflow-hidden whitespace-nowrap overflow-ellipsis">{"11 days ago"}</div> */}
      </div>
    </div>
  </div>
);

UploadedImage = connect(null, { fileUploader, openModal })(UploadedImage);

let ModalFileContent = ({ token, name, ...props }) => {
  const fileRef = useRef();
  const [image, setImage] = useState("");

  const onFileChange = useCallback(async event => {
    if (event.target.files[0]) {
      const base64 = await getBase64(event.target.files[0]);
      uploadImage(event.target.files[0]);
      setImage(base64);
    }
  }, []);

  useEffect(() => {
    if (props.add_img) {
      props.getAction(GET_MEDIA, mediaURL, token);
    }
  }, [props.add_img]);

  useEffect(() => {
    props.getAction(GET_MEDIA, mediaURL, token);
  }, []);

  useEffect(() => {
    if (props.upload_img_data) {
      // data to send to media endpoint
      const data = {
        image_url: props.upload_img_data.url,
        image_title: props.upload_img_data.original_filename,
        alt_text: props.upload_img_data.original_filename,
      };
      // call post action to send url to media endpoint
      props.postAction(ADD_MEDIA, mediaURL, data);
      alert("Image uploaded successfully");
    }
  }, [props.upload_img_data]);

  const uploadImage = img => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "rstorkdu");

    props.postAction(UPLOAD_CLOUDINARY, null, formData, null, fileUpload);
  };

  const handleCloudUpload = () => {
    fileRef.current.click();
  };
  return (
    <section className="relative w-full h-full md:w-96 transition-all duration-300 p-4">
      <div className="bg-white shadow-xl rounded-lg h-full">
        <header className="relative rounded-tl-lg rounded-tr-lg px-4 pt-4 pb-1">
          <div className="flex items-center justify-between">
            <div className="font-normal text-lg">Select Media</div>
          </div>
        </header>
        <hr className="border-opacity-30 mt-2 border-[rgba(194, 207, 214, var(--tw-border-opacity))]" />
        <main className="overflow-y-auto pb-8 overscroll-contain">
          <div className="w-full shadow-md py-2 px-3">
            <span>
              <div>
                <label className="text-black text-opacity-80" />
                <input type="text" placeholder="Search Media" className="text-[0.875rem] outline-none leading-[1.25rem] mt-[0.25rem] w-full rounded-[0.5rem] bg-[#c2cfd633] bg-opacity-[0.2] px-[1rem] font-medium h-[50px]" />
                <div className="mt-1 text-xs text-red-400 flex items-center gap-1 opacity-0">
                  <IoAlertCircleOutline /> Bad Input Request
                </div>
              </div>
            </span>
            <div className="">
              <a className="flex items-center justify-start text-blue-500 font-normal capitalize gap-2" onClick={handleCloudUpload}>
                Upload New Media <IoCloudUploadOutline /> {props.loading ? "loading..." : ""}
              </a>
              <input type="file" ref={fileRef} onChange={onFileChange} hidden accept={[".jpg", ".png", ".jpeg", ".svg"]} />
            </div>
          </div>
          <MediaFiles className="media-files overflow-x-hidden">
            <section className="flex flex-wrap -mx-2 p-2">
              {props.image_load ? (
                <div className="w-full h-[500px] flex justify-center items-center">loading...</div>
              ) : (
                <>
                  {props.images?.map((content, idx) => (
                    <UploadedImage key={idx} {...content} name={name} />
                  ))}
                </>
              )}
            </section>
          </MediaFiles>
        </main>
      </div>
    </section>
  );
};
const mapStateToProps = state => ({
  upload_img_data: state.settings.img_upload.data,
  loading: state.settings.img_upload?.loading,
  token: state.auth.login.token,
  images: state.media.get_media.data,
  image_load: state.media.get_media.loading,
  add_img: state.media.add_media.data,
});

ModalFileContent = connect(mapStateToProps, { postAction, getAction, openModal })(ModalFileContent);

const mapStateToPropsFile = state => ({
  open: state.ui.modal.open,
  img_file: state.ui.input_file,
  file_name: state.ui.input_file?.name,
});

export const FileImageModalOpener = connect(mapStateToPropsFile, { openModal })(
  React.forwardRef(({ label, type, name, control, value, defaultValue, ...props }, ref) => {
    const [open, setOpen] = useState(false);
    return (
      <FileDiv>
        <div className="mb-6">
          <label className="text-opacity-80 text-gray-500 font-light">{label}</label>
          <div className="relative mt-1 mb-5 flex items-start justify-start rounded-[0.5rem] bg-[#c2cfd633] p-[0.5rem] pr-[1rem]">
            <div className="flex items-center justify-start w-full">
              <div className="">
                <div className="w-12 h-12 relative flex items-center justify-center shadow-md rounded-md overflow-hidden">{(props.isEmpty || props.img_file[name] == null) && defaultValue == null ? <IoImage className="text-[2.25rem] leading-[2.5rem] icon" /> : <img src={props.img_file[name]?.image_url || defaultValue} alt="" />}</div>
              </div>
              <div className="relative flex w-full ml-3 items-center justify-between">
                <div className="w-11/12">
                  <div className="text-base font-normal">
                    <Controller name={name} render={({ field: { onChange } }) => <input ref={ref} type={type} defaultValue={defaultValue} onChange={onChange} disabled className="bg-transparent outline-none w-full" value={props.isEmpty ? "No Media" : props.img_file[name]?.image_title} />} control={control} />
                  </div>
                  {!props.isEmpty && <a className="text-red-400 capitalize">remove</a>}
                </div>
                <div className="flex justify-end items-center text-sm">
                  <a className="font-medium" onClick={() => setOpen(true)}>
                    Select
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppModal open={props.open || open} end onClose={() => setOpen(false)} content={<ModalFileContent name={name} />} />
      </FileDiv>
    );
  })
);

export const RelatedPost = connect(null, { setRelatedPost })(
  React.forwardRef(({ label, name, control, error, placeholder, postsData, relatedPostData, ...props }, ref) => {
    const [tags, setTags] = useState([]);
    const [relatedPost, setRelatedPost] = useState(relatedPostData);
    const [searchWord, setSearchWord] = useState("");

    const handleTags = word => {
      setTags(postsData.filter(item => item.title.toLowerCase().includes(word.toLowerCase())));
      console.log(postsData.filter(item => item.title.toLowerCase().includes(word.toLowerCase())));
    };

    useEffect(() => {
      props.setRelatedPost(
        name,
        relatedPost.map(item => item.id)
      );
    }, []);

    return (
      <div className="mb-7">
        <label htmlFor={name} className="text-black font-light text-opacity-80">
          {label}
        </label>
        <Controller
          name={name}
          render={({ field: { onChange } }) => (
            <div className="flex flex-wrap mt-2 -mx-2">
              <div className="w-full lg:w-1/2 px-2 mb-6">
                <div className="bg-white flex flex-col divide-y rounded-lg">
                  <div className="flex px-4 bg-white justify-between rounded-lg w-full items-center">
                    <input
                      type="text"
                      placeholder="Start typing to search and press 'ENTER'..."
                      value={searchWord}
                      className=" outline-none py-2 w-full text-sm px-0"
                      onChange={event => {
                        setSearchWord(event.target.value);
                        if (searchWord.length > 0) handleTags(searchWord);
                      }}
                    />
                    <MdClose
                      onClick={() => {
                        setSearchWord("");
                        setTags([]);
                      }}
                      className="text-black text-lg cursor-pointer"
                    />
                  </div>
                  {tags.length > 0 ? (
                    tags.map((item, index) => (
                      <div key={tags.id} className="flex px-4 py-2 justify-between items-center">
                        <p className="">{item.title}</p>
                        <AiOutlinePlus
                          onClick={() => {
                            setRelatedPost(prevState => [...prevState, { ...item, id: String(Number(prevState.length) + 1) }]);
                            props.setRelatedPost(name, [...relatedPost.map(item => item.id), String(Number(relatedPost?.length) + 1)]);
                          }}
                          className="text-blue-400 cursor-pointer text-lg"
                        />
                      </div>
                    ))
                  ) : (
                    <span className="px-4 py-2 text-textgrey">Nothing to show yet</span>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2 mb-6">
                <div className="bg-white flex flex-col divide-y rounded-lg">
                  <h4 className="font-bold px-4 py-2">Related post</h4>
                  {relatedPost?.length > 0 ? (
                    relatedPost.map(item => (
                      <div key={item.id} className="flex px-4 py-2 justify-between items-center">
                        <p className="">{item.title}</p>
                        <AiOutlineMinus
                          onClick={() => {
                            const tempData = relatedPost.filter(elem => elem.id !== item.id);
                            setRelatedPost(tempData);
                            props.setRelatedPost(
                              name,
                              [...relatedPost.filter(elem => elem.id !== item.id)].map(item => item.id)
                            );
                          }}
                          className="text-blue-400 cursor-pointer text-lg"
                        />
                      </div>
                    ))
                  ) : (
                    <span className="px-4 py-2 text-textgrey">Nothing to show</span>
                  )}
                </div>
              </div>
            </div>
          )}
          control={control}
        />
      </div>
    );
  })
);
export const TextFieldTag = connect(null, { setTags })(
  React.forwardRef(({ label, name, control, error, placeholder, defaultValue, ...props }, ref) => {
    const [tags, setTags] = useState([]);

    const handleTags = event => {
      if (event.key === "Enter" && event.target.value != "") {
        setTags([...tags, event.target.value]);
        // set tags in action and set the keys
        props.setTags(name, [...tags, event.target.value]);

        event.target.value = "";
      } else if (event.key === "Backspace" && tags.length && event.target.value == 0) {
        const tagsCopy = [...tags];
        tagsCopy.pop();
        event.preventDefault();
        setTags(defaultValue || tagsCopy);
      }
    };

    const removeTag = index => {
      setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
    };

    return (
      <Fieldset className="mb-7">
        <label htmlFor={name} className="text-black font-light text-opacity-80">
          {label}
        </label>
        <Controller
          name={name}
          render={({ field: { onChange } }) => (
            <>
              <div className="tags bg-[#c2cfd633] mt-1 relative flex w-full rounded-[0.5rem] flex-wrap flex-col px-[0.75rem] py-[0.5rem] text-[0.875rem] leading-[1.25rem] min-h-[50px]">
                <span className="flex flex-wrap">
                  {tags.map((tag, index) => (
                    <div className="single-tag gap-2 flex items-center mr-[0.5rem] mb-[0.25rem] justify-start rounded-[0.375rem] px-[0.75rem] py-[0.5rem] text-[0.875rem] leading-[1.25rem] font-normal" key={index}>
                      <span>{tag}</span>
                      <i>
                        <MdClose className="text-red-600 flex justify-center items-center" onClick={() => removeTag(index)} />
                      </i>
                    </div>
                  ))}
                </span>
                <input type="text" placeholder="Press Enter" className="py-1 px-0" onKeyDown={event => handleTags(event)} />
                <div className="absolute right-0 bottom-0 mx-2 my-2 py-2 px-2 rounded-lg text-xs bg-[#c2cfd633]">{tags.length} enteries</div>
              </div>
              <div className="">{error}</div>
            </>
          )}
          control={control}
        />
      </Fieldset>
    );
  })
);

export const Toggler = connect(null, { addToggle })(
  React.forwardRef(({ name, label, control, disabled, ...props }, ref) => {
    const [toggle, setToggle] = useState(false);
    const [open, setOpen] = useState(false);
    // console.log(disabled);
    return (
      <div className="mr-5">
        <Controller
          name={name}
          render={({ field: { onChange, value, ref } }) => (
            <label className="flex items-center gap-x-2">
              <Switch
                id="switcher"
                ref={ref}
                name={name}
                value={toggle}
                // value={value}
                disabled={disabled}
                checked={toggle}
                onChange={() => {
                  setToggle(!toggle);
                  onChange(!toggle);

                  // call action here
                  // console.log(toggle);
                  if (toggle === false) {
                    props.addToggle(name);
                  } else {
                    props.addToggle(null);
                  }
                }}
                className={`${toggle ? "bg-red-300" : "bg-[#f2f2f2]"}
          relative inline-flex w-[2.5rem] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${toggle ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-[1.25rem] w-[1.25rem] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <span className="font-light text-black text-opacity-80">{label.length > 1 ? (toggle ? label[0] : label[1]) : label[0]}</span>
            </label>
          )}
          control={control}
        />
      </div>
    );
  })
);

export const DateField = React.forwardRef(({ error, label, control, name, placeholder }, ref) => {
  return (
    <Fieldset className="mb-7">
      <label className="text-black font-light text-opacity-80">{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, ...props }) => (
          <div>
            <input
              type="date"
              name={name}
              id={name}
              placeholder={placeholder}
              onChange={onChange}
              ref={ref}
              value={value}
              // defaultValue={defaultValue}
            />
          </div>
        )}
      />
    </Fieldset>
  );
});

export const RadioFieldOne = React.forwardRef(({ error, label, control, name, data }, ref) => {
  const [selected, setSelected] = useState(data[0]);
  return (
    <Fieldset className="mb-6">
      <label className="text-black font-light text-opacity-80">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            {/* <input hidden value={value} /> */}
            <RadioGroup value={value} defaultValue={data[0]} onChange={onChange}>
              <RadioDiv className="flex mt-1">
                {data.map(content => (
                  <RadioGroup.Option
                    key={content.id || content}
                    value={content}
                    className={({ active, checked }) =>
                      `${active ? "" : ""} first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg
                    ${checked ? "selected bg-opacity-75 text-white" : "bg-gray-100"}
                      relative flex cursor-pointer px-4 py-2 focus:outline-none`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label as="p" className={`font-light text-[0.875rem] leading-[1.25rem] uppercase  ${checked ? "text-white selected" : "text-gray-900"}`}>
                                {content.name || content}
                              </RadioGroup.Label>
                            </div>
                          </div>
                          {checked && <div className="shrink-0 text-white">{/* <CheckIcon className="h-6 w-6" /> */}</div>}
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioDiv>
            </RadioGroup>
          </div>
        )}
      />
    </Fieldset>
  );
});

// export const FileInput = React.forwardRef({ label, name });
