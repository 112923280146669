import { Disclosure, Tab } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { IoEye, IoLockClosed, IoPencilSharp } from "react-icons/io5";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import AppModal from "../../components/widget/Modal/Modal";
import MyTable from "../../components/widget/table/myTable";
import Table from "../../components/widget/table/Table";
import { DELETE_USERS, GET_USERS } from "../../store/actions/action_types/action.types";
import { deleteAction, getAction } from "../../store/actions/creators/request.actions";
import { adminURL } from "../../utils/endpoint";
import DeleteModal from "../Jobs/DeleteModal";
import { userProcessData } from "./user.data";
import ViewUsers from "./ViewUsers";
import { openModal } from "../../store/actions/creators/ui.actions";

export const tableInfo = [
  {
    id: 1,
    name: "Aliu",
    user: "asalaudeen",
    role: "Administrator",
    created: "31/10/2022",
  },
  {
    id: 2,
    name: "Aliu",
    user: "asalaudeen",
    role: "Administrator",
    created: "31/9/2022",
  },
  {
    id: 3,
    name: "Aliu",
    user: "asalaudeen",
    role: "Administrator",
    created: "21/9/2022",
  },
  {
    id: 4,
    name: "Aliu",
    user: "asalaudeen",
    role: "Administrator",
    created: "21/10/2022",
  },
];

export const userTableHeaders = [
  { title: "id", render: row => `${row.id}` },
  { title: "First Name", render: row => `${row.name}` },
  { title: "Last Name", render: row => `${row.user}` },
  { title: "Email", render: row => `${row.role}` },
  {
    title: "Created At",
    render: row => `${row.role}`,
  },
  {
    title: "Action",
    render: row => (
      <div className="flex justify-center items-center rounded-lg border border-gray-400">
        <button className="pr-3">
          <IoEye size={20} />
        </button>
        <button className="px-3 py-2 border-x border-gray-400">
          <IoPencilSharp size={20} />
        </button>
        <button className="pl-3"></button>
        <IoLockClosed size={20} />
      </div>
    ),
  },
];

const UserTable = ({ tableData }) => {
  const data = tableData.map(userProcessData);
  return <Table tableData={data} no_option />;
};

const Users = ({ users_data, id, getAction, deleteAction, token, ...props }) => {
  const [userData, setUserData] = useState([]);
  const ExpandableContent = [
    {
      name: "All Users",
      hasCount: userData.length,
      Component: () => <UserTable tableData={userData} />,
    },
  ];

  useEffect(() => {
    getAction(GET_USERS, adminURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (users_data) {
      setUserData(users_data);
    }
  }, [users_data]);

  const deleteUsers = () => {
    if (id) {
      const URL = `${adminURL}/${id}`;
      deleteAction(DELETE_USERS, URL, props.token);
    }
  };

  return (
    <div>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} key={content.name} component={<Component />} />
        ))}
      </main>
      <AppModal open={props.open} onClose={() => props.openModal("CLOSE_MODAL")} content={<ViewUsers id={id} />} />
      <AppModal open={props.openDelete} onClose={() => props.openModal("CLOSE_MODAL")} content={<DeleteModal onClick={deleteUsers} id={id} />} />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.admin.getUsers?.loading,
  users_data: state.admin.getUsers?.data,
  error_msg: state.admin.getUsers?.error_msg,
});

export default connect(mapStateToProps, { getAction, deleteAction, openModal })(Users);
