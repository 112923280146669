import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../api/request.api";
import { loginURL, resetEmailURL, resetPasswordURL, resetURL, validateTokenURL } from "../utils/endpoint";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseURL}` }),
  endpoints: builder => ({
    authLogin: builder.mutation({
      query: body => ({ url: `${loginURL}`, method: "POST", body }),
      transformResponse: response => {
        return response;
      },
    }),
    validateToken: builder.mutation({
      query: body => ({ url: `${validateTokenURL}`, method: "POST", body }),
      transformResponse: response => {
        return response?.data;
      },
    }),
    resetEmail: builder.mutation({
      query: body => ({ url: `${resetEmailURL}`, method: "POST", body }),
      transformResponse: response => {
        return response?.data;
      },
    }),
    createNewPassword: builder.mutation({
      query: body => ({ url: `${resetPasswordURL}`, method: "POST", body }),
      transformResponse: response => {
        return response?.data;
      },
    }),
    resetPassword: builder.mutation({
      query: body => ({ url: `${resetURL}`, method: "POST", body }),
      transformResponse: response => {
        return response.data;
      },
    }),
  }),
});

export const { useAuthLoginMutation, useValidateTokenMutation, useResetEmailMutation, useCreateNewPasswordMutation, useResetPasswordMutation } = authApi;

export const { authLogin, validateToken, resetEmail, resetPassword, createNewPassword } = authApi.endpoints;
