import React from "react";
import { MediaCard } from "../../components/widget/Card/Card";

const Media = () => {
  return (
    <div className="my-10">
      <div className="">
        <section className="grid grid-cols-4 gap-x-6">
          <MediaCard />
          <MediaCard />
          <MediaCard />
          <MediaCard />
        </section>
      </div>
    </div>
  );
};

export default Media;
