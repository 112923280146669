import React, { useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import { ArticleImage } from "./ArticleImage";
import AspectRatio from "./AspectRatio";
import { store } from "../../../../store/redux.store";

const WIKI_IMAGE_ASPECT_RATIO = 4 / 3;

const MediaFrame = ({ editorContext }) => {
  const [media, setMedia] = React.useState();
  const { eventEmitter } = editorContext;

  function handleImageClick(m) {
    eventEmitter.emit("command", "insertImage", {
      src: `${m.url}`,
      alt: m.name,
    });
  }

  const deleteMedia = mediaId => {
    store.dispatch({
      type: "post/removeMedia",
      payload: {
        id: mediaId,
      },
    });
  };

  const fetchReferences = () => {
    const fetchedMedia = store.getState().postSlice.media;
    setMedia(fetchedMedia);
  };

  useEffect(() => {
    fetchReferences();
    store.subscribe(fetchReferences);
  }, []);

  return (
    <div>
      {media?.length ? (
        <div>
          <h4 className="text-lg mb-[2px]">Insert Media</h4>
          <p className="mb-2">All media files will be displayed here. Click on them to insert to wiki.</p>
          <div className="grid grid-cols-3 gap-4">
            {media.map(m => {
              return (
                <div className="relative h-full" key={m.id}>
                  <AspectRatio ratio={WIKI_IMAGE_ASPECT_RATIO}>
                    <ArticleImage w="100%" h="100%" alt={m.name || "Image"} className="rounded cursor-pointer bg-cover h-full w-full overflow-hidden" src={m.url} key={m.id} onClick={() => handleImageClick(m)} cursor="pointer" imageURL={m} />
                  </AspectRatio>
                  <div className="flex justify-center items-center absolute -top-2 -right-2 text-white bg-[#1a202c] w-6 h-6 z-[3] rounded-full">
                    <RiCloseLine cursor="pointer" onClick={() => deleteMedia(m.id)} color="inherit" size="14" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-[120px]">
          <h4 className="text-lg mb-[2px]">No Media Found</h4>
          <p className="mb-2 text-center max-w-[300px]">To add media files, click on the &quot;Add new image and video&quot; button at the right of editor</p>
        </div>
      )}
    </div>
  );
};

export default MediaFrame;
