import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { FileImageModalOpener, RadioFieldOne, SelectField, TextField } from "../../components/widget/form/FormComponent";
import { ADD_JOB, GET_JOB_POSITIONS } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { jobURL, positionURL } from "../../utils/endpoint";
import moment from "moment";

const NewJobs = ({ postAction, token, input_name, getAction, positionList, ...props }) => {
  const { handleSubmit, formState, register, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: { type_of_employment: "Full-Time" },
  });
  const [value, onChange] = useState(new Date());
  let navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [positionListData, setPositionListData] = useState([]);

  useEffect(() => {
    getAction(GET_JOB_POSITIONS, positionURL);
  }, [getAction]);

  useEffect(() => {
    if (positionList) {
      console.log(positionList);
      setPositionListData(positionList.map(item => item.title_of_position));
    }
  }, [positionList]);

  const onSubmit = async data => {
    data.image_url = props.file.image_url.image_url;

    const date_time = new Date(`${data.date} ${data.time}`);
    data.lasts_till = moment(date_time).toISOString(true);
    console.log(moment(date_time).toISOString());
    // alert(JSON.stringify(data));
    // console.log(data);
    postAction(ADD_JOB, jobURL, data);
  };

  // console.log(props.loading);
  return (
    <div className="mt-10">
      <div onClick={() => navigate("/jobs/jobs")} className="flex gap-1 cursor-pointer items-center">
        <IoChevronBackOutline className="text-secondary" />
        <span className="text-secondary">Back</span>
      </div>
      <h1 className="subTitle">New Job</h1>
      <div>
        <p className="my-1">Publish Job?</p>
        <div className="flex gap-2">
          <Switch checked={enabled} onChange={setEnabled} disabled={toggle} className={`${enabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 mb-6 items-center ${toggle && "cursor-not-allowed"} rounded-full`}>
            <span className="sr-only">Mode</span>
            <span className={`${enabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
          </Switch>
          <p className="mt-[2px]">
            This Job Opening will show on the <span className="text-blue-400 cursor-pointer">Job Openings</span> page.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
        <SelectField
          item={positionListData}
          label="Position"
          control={control}
          mb="2xl:mb-6 lg:mb-3 mb-6"
          // selected_item="penalty_name"
          name="position"
          error={formState.errors.position}
          {...register("position", { required: false })}
        />
        <TextField label="Job ID" name="job-id" error={formState.errors["job-id"]} {...register("job-id", { required: true })} type="text" />
        <FileImageModalOpener label="Image" control={control} value={props.file[input_name]?.image_title} name="image_url" {...register("image_url", { required: false })} />
        <RadioFieldOne control={control} name="type_of_employment" data={["Full-Time", "Contract", "Remote"]} label="Type Of Employment" />
        <TextField label="Overview" name="overview" error={formState.errors["overview"]} {...register("overview", { required: false })} type="textarea" />
        <TextField label="Location" name="location" error={formState.errors["location"]} {...register("location", { required: false })} type="text" />
        <p className="text-black text-opacity-80 mb-3">Vacancy Lasts till</p>

        <div className="grid grid-cols-2 gap-8">
          <TextField label="Date" name="date" error={formState.errors.date} {...register("date", { required: false })} type="date" className="" />
          <TextField label="Time" name="time" error={formState.errors.time} {...register("time", { required: false })} type="time" />
        </div>

        {/* <DateTimePicker onChange={onChange} value={value} /> */}
        <div className="flex gap-2 mt-5">
          <Switch checked={enabled} onChange={setEnabled} disabled={toggle} className={`${enabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 mb-6 items-center ${toggle && "cursor-not-allowed"} rounded-full`}>
            <span className="sr-only">Mode</span>
            <span className={`${enabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
          </Switch>
          <p className="mt-[2px]">Automatically Unpublish job after due date.</p>
        </div>
        <div className="flex justify-end pt-10">
          <AppButton loading={props.loading} name={`Save`} type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  file: state.ui.input_file,
  loading: state.jobs.addJob.loading,
  job_data: state.jobs.addJob?.data,
  input_name: state.ui.input_file?.name,
  error_msg: state.jobs.addJob.error_msg,
  positionList: state.jobs.jobPositionList.data?.data,
  addJob_data: state.jobs.addJob.data,
});
export default connect(mapStateToProps, { postAction, getAction })(NewJobs);
