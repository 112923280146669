import React from "react";
import SideBar from "../sideBar/SideBar";
import TopBar from "../topBar/TopBar";
import { Main, PageContent, PageLayout } from "./dashboard.style";

const DashboardLayout = ({ children, token, ...props }) => {
  return (
    <PageLayout>
      <SideBar />
      <Main className="w-full ml-12 md:ml-60 px-4 md:px-8">
        <TopBar />
        <PageContent className="min-h-full overflow-hidden relative mb-20">{children}</PageContent>
      </Main>
    </PageLayout>
  );
};

export default DashboardLayout;
