import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../api/request.api";
import { positionURL } from "../utils/endpoint";

export const positionApi = createApi({
  reducerPath: "positionApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseURL}` }),
  tagTypes: ["Positions", "Position"],
  endpoints: builder => ({
    getPositions: builder.query({
      query: () => `${positionURL}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Positions"],
    }),
    getPosition: builder.query({
      query: id => `${positionURL}/${id}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Position"],
    }),
    updatePosition: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${positionURL}/${id}`,
        method: "PATCH",
        body: patch,
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["Positions"],
    }),
    deletePosition: builder.mutation({
      query: id => ({
        url: `${positionURL}/${id}`,
        method: "DELETE",
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["Positions"],
    }),
  }),
});

export const { useGetPositionsQuery, useGetPositionQuery, useUpdatePositionMutation, useDeletePositionMutation } = positionApi;

export const { getPositions, getPosition, updatePosition, deletePosition } = positionApi.endpoints;
