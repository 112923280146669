import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { reducers as rootReducer } from "./index.reducer";
import { configureStore } from "@reduxjs/toolkit";
import { authApi, postApi, jobApi, positionApi, jobApplicationApi } from "../services";

let persistConfig = {
  key: "root",
  storage,
  blacklist: ["settings", "ui", "site", "jobs", "newsletter", "appointments"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/REGISTER"],
        // Ignore these paths in state
        ignoredPaths: ["register"],
      },
    })
      .concat(thunk)
      .concat(postApi.middleware)
      .concat(authApi.middleware)
      .concat(jobApi.middleware)
      .concat(positionApi.middleware)
      .concat(jobApplicationApi.middleware),
});

export const persistor = persistStore(store);
