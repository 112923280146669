import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
};

const getAppointmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_APPOINTMENTS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_APPOINTMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_APPOINTMENT:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_APPOINTMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_APPOINTMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const editAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_APPOINTMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const appointmentsReducer = combineReducers({
  appointmentList: getAppointmentListReducer,
  appointmentDetails: getAppointmentReducer,
  addAppointment: addAppointmentReducer,
  deleteAppointment: deleteAppointmentReducer,
  editAppointment: editAppointmentReducer,
});
