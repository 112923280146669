import { DateTime } from "luxon";

export const userProcessData = (data, idx) => ({
  "#": idx + 1,
  id: data.staff_id,
  Name: data.staff_first_name,
  User: data.staff_last_name,
  Role: data.role,
  Created: DateTime.fromISO(data.createdAt).toLocaleString(DateTime.DATE_FULL),
});
