export { default as SiteHome } from "./Site/SiteHome";
export { default as SiteAbout } from "./Site/SiteAbout";
export { default as SiteProduct } from "./Site/SiteProduct";
export { default as SiteTestimonial } from "./Site/SiteTestimonial";
export { default as SiteCareers } from "./Site/SiteCareers";
export { default as SiteContact } from "./Site/SiteContact";
export { default as SiteManagement } from "./Site/SiteManagement";
export { default as SiteDirectors } from "./Site/SiteDirectors";
export { default as SitePrivacy } from "./Site/SitePrivacy";
export { default as SiteTerm } from "./Site/SiteTerm";
export { default as SiteFAQ } from "./Site/SiteFAQ";
export { default as PostPage } from "./Posts/Posts";
export { default as AddPostPage } from "./Posts/AddPost";
export { default as MediaPage } from "./Media/Media";
export { default as PostCategory } from "./Posts/PostCategory";
export { default as Login } from "./Auth/Login";
export { default as Resources } from "./Site/SiteResource";
