import { combineReducers } from "redux";
import { aboutReducer } from "./about.reducer";
import { careerReducer } from "./careers.reducer";
import { contactReducer } from "./contact.reducer";
import { faqReducer } from "./faq.reducer";
import { homeReducer } from "./home.reducer";
import { mainReducer } from "./main.reducer";
import { privacyReducer } from "./privacy.reducer";
import { productReducer } from "./product.reducer";
import { resourceReducer } from "./resource.reducer";
import { termReducer } from "./terms.reducer";
import { testimonialReducer } from "./testimonial.reducer";

export const siteReducer = combineReducers({
  main: mainReducer,
  home: homeReducer,
  about: aboutReducer,
  testimonial: testimonialReducer,
  career: careerReducer,
  faq: faqReducer,
  contact: contactReducer,
  privacy: privacyReducer,
  terms: termReducer,
  product: productReducer,
  resource: resourceReducer,
});
