import React from "react";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";

const AddAttendance = () => {
  const { handleSubmit, formState, register, control } = useForm({
    mode: "onChange",
  });
  let navigate = useNavigate();
  const onSubmit = async data => {
    alert(JSON.stringify(data));
  };
  return (
    <div className="mt-10">
      <div
        onClick={() => navigate("/appointments")}
        className="flex gap-1 cursor-pointer items-center"
      >
        <IoChevronBackOutline className="text-secondary" />
        <span className="text-secondary">Back</span>
      </div>
      <h1 className="subTitle">New Visit</h1>
      <form className="mt-8">
        <TextField
          label="Visitor Name"
          name="visitor_name"
          error={formState.errors.visitor_name}
          {...register("visitor_name", { required: false })}
          type="text"
        />
        <TextField
          label="Company"
          name="company_name"
          error={formState.errors.company_name}
          {...register("company_name", { required: false })}
          type="text"
        />
        <TextField
          label="Visitee"
          name="visitee"
          error={formState.errors.visitee}
          {...register("visitee", { required: false })}
          type="text"
        />
        <TextField
          label="Purpose of Visit"
          name="purpose_of_visit"
          error={formState.errors["purpose_of_visit"]}
          {...register("purpose_of_visit", { required: false })}
          type="textarea"
        />
        <TextField
          label="Address"
          name="address"
          error={formState.errors["address"]}
          {...register("address", { required: false })}
          type="textarea"
        />
        <TextField
          label="Phone"
          name="phone"
          control={control}
          error={formState.errors.phone}
          {...register("phone", { required: false })}
          type="number"
        />
        <div className="flex justify-between">
          <TextField
            label="Date"
            name="date"
            error={formState.errors.date}
            {...register("date", { required: false })}
            type="date"
          />
          <TextField
            label="Time In"
            name="time_in"
            error={formState.errors.time_in}
            {...register("time_in", { required: false })}
            type="time"
          />
          <TextField
            label="Time Out"
            name="time_out"
            error={formState.errors.time_out}
            {...register("time_out", { required: false })}
            type="time"
          />
        </div>
      </form>
      <div className="flex justify-end pt-10">
        <AppButton name="Sumbit" onPress={onSubmit} />
      </div>
    </div>
  );
};

export default AddAttendance;
