import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as reducer from "./reducers";
import { authApi, postApi, jobApi, positionApi, jobApplicationApi } from "../services";
import { postSlice, userSlice } from "./slices";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["token"],
};

const postPersistConfig = {
  key: "post",
  storage: storage,
  blacklist: ["loading", "data"],
};

const userPersistConfig = {
  key: "admin",
  storage: storage,
  blacklist: ["loading"],
};

const reducers = combineReducers({
  ui: reducer.uiReducer,
  admin: persistReducer(userPersistConfig, reducer.userReducer),
  auth: persistReducer(authPersistConfig, reducer.authReducer),
  jobs: reducer.jobsReducer,
  settings: reducer.settingsReducer,
  appointments: reducer.appointmentsReducer,
  site: reducer.siteReducer,
  media: reducer.mediaReducer,
  post: persistReducer(postPersistConfig, reducer.postReducer),
  newsletter: reducer.newsLetterReducer,
  postSlice: postSlice,
  user: userSlice,
  [postApi.reducerPath]: postApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [jobApi.reducerPath]: jobApi.reducer,
  [positionApi.reducerPath]: positionApi.reducer,
  [jobApplicationApi.reducerPath]: jobApplicationApi.reducer,
});

export { reducers };
