import { useForm } from "react-hook-form";
import { IoGridOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { useEffect } from "react";
import { categoryURL } from "../../utils/endpoint";
import { EDIT_CATEGORY } from "../../store/actions/action_types/action.types";
import { TextField } from "../../components/widget/form/FormComponent";
import { AppButton } from "../../components/widget/button/Button";
import { postAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";

const EditCategoryModal = ({ postAction, editData, openModal }) => {
  const { handleSubmit, formState, register, setValue } = useForm({ mode: "onChange" });

  useEffect(() => {
    setValue("category_name", editData?.name || "");
    console.log(editData?.name);
  }, []);

  const onSubmit = data => {
    console.log(JSON.stringify(data));
    postAction(EDIT_CATEGORY, categoryURL, data);
  };
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex flex-col items-center justify-center bg-white rounded-lg h-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
            <div className="flex flex-col items-center justify-center text-center px-2">
              <IoGridOutline className="text-6xl text-black text-opacity-20 mb-2 mt-3 font-light" />
              <div className="font-bold text-xl md:text-3xl mb-1">Edit Category</div>
              <div className="text-black text-center text-opacity-50 text-sm">What name would you like to call this category?</div>
            </div>
          </header>
          <main className="border-t border-[rgba(194, 207, 214, var(--tw-border-opacity))] py-3 border-opacity-0 max-h-[50vh] mt-[1.5rem] h-full w-full overflow-y-auto overflow-x-hidden px-[1.5rem] transition-all">
            <div className="w-full px-3">
              <TextField label="Category" type="text" name="category" error={formState.errors.category_name} {...register("category_name", { required: true })} />
            </div>
          </main>
          <footer className="">
            <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" onClick={() => openModal("CLOSE_MODAL")} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Cancel
              </button>
              <AppButton name="Save" type="submit" />
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  // category_data: state.post.categoryList.data,
  error_msg: state.post.addCategory.error_msg,
});

export default connect(mapStateToProps, { postAction, openModal })(EditCategoryModal);
