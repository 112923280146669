import Loader from "../Loader/Loader";
import { Button } from "./button.style";

export const AppButton = ({ onPress, loading, ...props }) => (
  <Button disabled={props.disabled} type={props.type || "button"} className={`rounded-lg transition flex justify-center ease-in-out duration-700 ${props.className || "bg-[#992333] px-5 capitalize py-2 text-white"}`} onClick={onPress}>
    <div className="flex items-center justify-between font-light">
      <span className={`${props.textSize || "md:text-sm text-sm"}`}>{loading !== true ? props.name : <Loader loading={loading} />}</span>
      {props.icon && <div className="ml-2">{props.icon}</div>}
    </div>
  </Button>
);

export const LinkButton = ({ onPress, name, ...props }) => (
  <button className="mr-4 text-opacity-80 text-blue-500 text-base font-medium flex items-center justify-center transition-all" onClick={onPress}>
    <span className="font-light">{name}</span>
  </button>
);

export const FaqButton = ({ name, type, icon, ...props }) => (
  <button onClick={props.onPress} className={`text-black ${type !== "first" ? "border-l border-l-gray-200" : ""} first:rounded-tl-[0.5rem] first:rounded-bl-[0.5rem] relative flex w-auto items-center justify-center border-gray-100 px-[0.75rem] py-[0.5rem] text-[1.125rem] leading-[1.75rem]`}>
    <span className="font-light flex items-center gap-2">
      {props.icon && <div className="">{props.icon}</div>}
      {name}
    </span>
  </button>
);
