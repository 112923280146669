import styled from "styled-components";

export const MediaCardDiv = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  min-height: 270px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  .image {
    height: 9rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.05;
    img {
      height: 100%;
      width: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
      object-position: center;
    }
  }
  .icon-img {
    --tw-text-opacity: 1;
    color: rgba(194, 207, 214, var(--tw-text-opacity));
  }
  .delete {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0.75rem;
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
    font-size: 1.125rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
`;
