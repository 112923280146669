import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../components/Layout/DashboardLayout";
import Analytics from "../pages/Analytics/Analytics";
import Messages from "../pages/Messages/Messages";
import Settings from "../pages/Settings/Settings";
import Support from "../pages/Support/Support";
import UserRoute from "./UserRoute/user.route";
import AppointmentRoute from "./AppointmentRoute/appointment.route";
import AttendanceRoute from "./AttendanceRoute/attendance.route";
import NewsletterRoute from "./NewsletterRoute/newsletter.route";
import JobsRoute from "./JobsRoute/jobs.route";
import SiteRoute from "./SiteRoute/site.route";
import PostRoute from "./PostRoute/post.route";
import MediaRoute from "./MediaRoute/media.route";

const AppRoutes = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Analytics />} />
        <Route path="/site/*" element={<SiteRoute />} />
        <Route path="/posts/*" element={<PostRoute />} />
        <Route path="/media/*" element={<MediaRoute />} />
        <Route path="/jobs/*" element={<JobsRoute />} />
        <Route path="/newsletters/*" element={<NewsletterRoute />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/attendance/*" element={<AttendanceRoute />} />
        <Route path="/appointments/*" element={<AppointmentRoute />} />
        <Route path="/support" element={<Support />} />
        <Route path="/users/*" element={<UserRoute />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </DashboardLayout>
  );
};

export default AppRoutes;
