import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
};

const getContactReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_CONTACT}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTACT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setContactReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_CONTACT}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_CONTACT:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getContactFormReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_CONTACT_FORM}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTACT_FORM:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setContactFormReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_CONTACT_FORM}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_CONTACT_FORM:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const contactReducer = combineReducers({
  get_contact: getContactReducer,
  set_contact: setContactReducer,
  get_contact_form: getContactFormReducer,
  set_contact_form: setContactFormReducer,
});
