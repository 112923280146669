import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { AppButton } from "../widget/button/Button";
import { nullifyToken } from "../../store/slices/user.slice";

const LogoutModal = ({ onClose }) => {
  const dispatch = useDispatch();
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={onClose} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <h1 className="my-2 font-bold text-xl">Are you sure you want to Logout ?</h1>
        </header>
        <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
          <div className="flex justify-between px-6">
            <AppButton onPress={onClose} className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" name={"Cancel"} />
            <AppButton
              name={"Yes, Logout"}
              onPress={() => {
                dispatch(nullifyToken());
              }}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default LogoutModal;
