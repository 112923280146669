import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../api/request.api";
import { postURL } from "../utils/endpoint";

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseURL}` }),
  tagTypes: ["Posts", "Post"],
  endpoints: builder => ({
    getPosts: builder.query({
      query: () => `${postURL}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Posts"],
    }),
    getPost: builder.query({
      query: id => `${postURL}/${id}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Post"],
    }),
    updatePost: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${postURL}/${id}`,
        method: "PATCH",
        body: patch,
      }),
      transformResponse: responseData => {
        console.log({ responseData });
        return responseData?.data;
      },
      invalidatesTags: ["Posts"],
    }),
    deletePost: builder.mutation({
      query: id => ({
        url: `${postURL}/${id}`,
        method: "DELETE",
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["Posts"],
    }),
  }),
});

export const { useGetPostsQuery, useDeletePostMutation, useGetPostQuery, useUpdatePostMutation } = postApi;

export const { getPosts, deletePost, getPost, updatePost } = postApi.endpoints;
