import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const AspectRatio = ({ ratio, className, children }) => {
  const padding = (1 / ratio) * 100;

  return (
    <div className={clsx("relative overflow-hidden w-full h-0", className)} style={{ paddingBottom: `${padding}%` }}>
      <div className="absolute inset-0 w-full h-full">{children}</div>
    </div>
  );
};

AspectRatio.propTypes = {
  ratio: PropTypes.number.isRequired, // e.g., 16/9 for a 16:9 ratio
  className: PropTypes.string,
  children: PropTypes.node,
};

AspectRatio.defaultProps = {
  className: "",
  children: null,
};

export default AspectRatio;
