import { Disclosure } from "@headlessui/react";
import React from "react";
import { HiChevronRight } from "react-icons/hi";
import Expandable from "../../components/widget/Expandable/Expandable";
import MyTable from "../../components/widget/table/myTable";
import { tableInfo, userTableHeaders } from "../Users/Users";

const Messages = () => {
  const ExpandableContent = [
    {
      name: "Today",
      length: tableInfo.length,
      Component: ({ rows, headers }) => (
        <MyTable rows={rows} headers={headers} />
      ),
    },
    {
      name: "Yesterday",
      length: tableInfo.length,
      Component: ({ rows, headers }) => (
        <MyTable rows={rows} headers={headers} />
      ),
    },
    {
      name: "Older",
      length: tableInfo.length,
      Component: ({ rows, headers }) => (
        <MyTable rows={rows} headers={headers} />
      ),
    },
  ];
  return (
    <div>
      <h2 className="pageTitle">Messages</h2>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable
            {...content}
            key={content.name}
            component={
              <Component rows={tableInfo} headers={userTableHeaders} />
            }
          />
        ))}
      </main>
    </div>
  );
};

export default Messages;
