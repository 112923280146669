import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import MyTable from "../../components/widget/table/myTable";
import Table from "../../components/widget/table/Table";
import { GET_APPOINTMENTS } from "../../store/actions/action_types/action.types";
import {
  deleteAction,
  editAction,
  getAction,
  postAction,
} from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { appointmentURL } from "../../utils/endpoint";
import { applicationOldData } from "../Jobs/job.data";
import { tableInfo, userTableHeaders } from "../Users/Users";
import {
  appointmentOldProcessData,
  appointmentProcessData,
} from "./appointment.data";

const AppointmentsTable = ({ tableData, processData }) => {
  const data = tableData.map(processData);
  return <Table tableData={data} no_option />;
};
const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const Appointments = ({
  appointment_data,
  getAction,
  deleteAction,
  token,
  ...props
}) => {
  const [appointmentData, setAppointmentData] = useState([]);
  const ExpandableContent = [
    {
      name: "Today",
      hasCount: appointmentData.filter(
        item => today.toDateString() === new Date(item?.time).toDateString()
      ).length,
      Component: () => (
        <AppointmentsTable
          processData={appointmentProcessData}
          tableData={appointmentData.filter(
            item => today.toDateString() === new Date(item?.time).toDateString()
          )}
        />
      ),
    },
    {
      name: "Yesterday",
      hasCount: appointmentData.filter(
        item => yesterday.toDateString() === new Date(item?.time).toDateString()
      ).length,
      Component: () => (
        <AppointmentsTable
          processData={appointmentProcessData}
          tableData={appointmentData.filter(
            item =>
              yesterday.toDateString() === new Date(item?.time).toDateString()
          )}
        />
      ),
    },
    {
      name: "Older",
      hasCount: appointmentData.length,
      Component: () => (
        <AppointmentsTable
          processData={appointmentOldProcessData}
          tableData={appointmentData}
        />
      ),
    },
  ];
  useEffect(() => {
    getAction(GET_APPOINTMENTS, appointmentURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (appointment_data) {
      console.log(appointment_data);
      setAppointmentData(appointment_data);
    }
  }, [appointment_data]);

  return (
    <div>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable
            {...content}
            key={content.name}
            component={<Component />}
          />
        ))}
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  openEdit: state.ui.modal?.open_edit,
  loading: state.appointments.appointmentList.loading,
  appointment_data: state.appointments.appointmentList.data,
  error_msg: state.appointments.appointmentList.error_msg,
});

export default connect(mapStateToProps, {
  getAction,
  openModal,
  deleteAction,
  editAction,
})(Appointments);
