import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { TextField } from "../widget/form/FormComponent";
import {
  getAction,
  postAction,
} from "../../store/actions/creators/request.actions";
import {
  ADD_SUBSCRIPTION_TEMPLATE,
  GET_SUBSCRIPTION_TEMPLATE,
} from "../../store/actions/action_types/action.types";
import { newsLetterSubURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";

const SubTemplate = ({ getAction, postAction, token, ...props }) => {
  const { handleSubmit, setValue, getValues, formState, register } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    getAction(GET_SUBSCRIPTION_TEMPLATE, newsLetterSubURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("subscription_template", props.data[0]?.subscription_template);
    }
  }, [props.data, setValue]);

  const submitHandler = data => {
    console.log(data);
    postAction(ADD_SUBSCRIPTION_TEMPLATE, newsLetterSubURL, data, token);
  };

  return (
    <div>
      <p className="font-light">You can copy this Link to the Ebook:</p>
      <a
        className="text-blue-400 font-light"
        href="https://files.gdl.com.ng/ebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://files.gdl.com.ng/ebook
      </a>
      <form className="mt-4" onSubmit={handleSubmit(submitHandler)}>
        <TextField
          label="Subscription Template*"
          name="subscription_template"
          error={formState.errors.subscription_template}
          {...register("subscription_template", { required: false })}
          type="textarea"
        />
        <div className="flex justify-end pt-5">
          <AppButton name={`Save`} loading={!props.loading} type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addSubscriberTemplate.loading,
  data: state.settings.subscriberTemplate.data,
  postData: state.settings.addSubscriberTemplate.data,
  error_msg: state.settings.addSubscriberTemplate.error_msg,
  fetch_error_msg: state.settings.subscriberTemplate.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(SubTemplate);
