import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import { AppButton } from "../../components/widget/button/Button";
import Expandable from "../../components/widget/Expandable/Expandable";
import { TextField } from "../../components/widget/form/FormComponent";
import { TestimonyContent } from "../../components/widget/Modal/ModalContents/TestimonialContent";
import { HomeTestimony } from "./SiteHome";
import { BarContent, MetadataContent } from "./Sites";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_TESTI_GENERAL, GET_SITE_META, GET_TESTIMONIAL, GET_TESTI_GENERAL } from "../../store/actions/action_types/action.types";
import { testiGeneralURL, testiMetaURL, testimonialURL, testimonyURL } from "../../utils/endpoint";
import { mapStateToPropsTesti } from "../../utils/helpers";
import { connect } from "react-redux";
import { useEffect } from "react";

const TestimonialGeneral = connect(mapStateToPropsTesti, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { testimonial_gen_data } = props.data;
  testimonial_gen_data = testimonial_gen_data && testimonial_gen_data[testimonial_gen_data.length - 1];

  const onSubmit = data => {
    props.postAction(ADD_TESTI_GENERAL, testiGeneralURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_testi_gen} />
        </div>
        <TextField label="Title" defaultValue={testimonial_gen_data?.title} name="title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={testimonial_gen_data?.description} name="description" type="textarea" {...register("description", { required: false })} />
        <TextField label="Call To Action (More)" defaultValue={testimonial_gen_data?.call_to_action} type="text" {...register("call_to_action", { required: false })} />
      </div>
    </form>
  );
});

const SiteTestimonial = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={testiMetaURL} />,
    },
    {
      name: "General",
      Component: props => <TestimonialGeneral {...props} />,
    },
    {
      name: "Testimonials",
      Component: props => <HomeTestimony {...props} />,
      hasCount: props.testimonial_data?.filter(content => content.show_testimonial == true)?.length,
      hasPlus: true,
      modalcontent: <TestimonyContent url={testimonyURL} />,
    },
  ];

  useEffect(() => {
    props.getAction(GET_SITE_META, testiMetaURL, token);
    props.getAction(GET_TESTI_GENERAL, testiGeneralURL, token);
    props.getAction(GET_TESTIMONIAL, testimonialURL, token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      {JSON.stringify(props.testimonies_data)}
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Testimonials" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  testimonial_gen_data: state.site.testimonial.get_testi_general.data,
  testimonial_data: state.site.home.get_testimonials.data,
});
export default connect(mapStateToProps, { getAction })(SiteTestimonial);
