import { Route, Routes, useNavigate } from "react-router-dom";
import AddAppointment from "../../pages/Appointments/AddAppointment";
import Appointments from "../../pages/Appointments/Appointments";

const AppointmentRoute = () => {
  let navigate = useNavigate();
  return (
    <div>
      <h1 className="pageTitle">Appointments</h1>
      <span
        onClick={() => navigate("/appointments/new")}
        className="text-blue-400 mb-4 inline-block cursor-pointer"
      >
        + Add Appointment
      </span>
      <Routes>
        <Route path="/" element={<Appointments />} />
        <Route path="/new" element={<AddAppointment />} />
      </Routes>
    </div>
  );
};

export default AppointmentRoute;
