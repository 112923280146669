import { useState } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";
import { LinkButton } from "../../components/widget/button/Button";
import { openModal } from "../../store/actions/creators/ui.actions";
import { MediaModal } from "../../pages/Media/media.content";
import { MediaPage } from "../../pages";

const Extras = ({ openModal }) => {
  return (
    <div className="flex items-center justify-start">
      <LinkButton name="Add Media +" onPress={() => openModal("OPEN_MODAL")} />
    </div>
  );
};

const PostRoute = ({ match, ...props }) => {
  return (
    <>
      <TestTab
        name="Media"
        content={<Extras {...props} />}
        modal_content={<MediaModal />}
        hideTabs
      />
      <Routes>
        <Route path="/" element={<MediaPage />} />
      </Routes>
    </>
  );
};

export default connect(null, { openModal })(PostRoute);
