import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { useGetJobApplicationQuery } from "../../services/jobApplication.services";
import { LoaderII } from "../../components/widget/Loader/Loader";

const ViewApplication = ({ id, openModal }) => {
  const { data = {}, isLoading } = useGetJobApplicationQuery(id, { skip: !id });

  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className={`max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto ${isLoading ? "pb-3" : "pb-0"}`}>
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <h1 className="mt-1 font-bold text-xl md:text-3xl mb-1">View Applicant</h1>
        </header>
        {isLoading ? (
          <LoaderII />
        ) : (
          <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
            <div className="w-full px-6">
              <h5 className="text-[#AE1931]">Candidate Name</h5>
              <p>{data?.candidate_name}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Job ID</h5>
              <p>{data?.id}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Email</h5>
              <p>{data?.email}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Location</h5>
              <p>{data?.location}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Curriculum Vitae (CV)</h5>
              <p>{data?.cv_link}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Portfolio URL</h5>
              <p>{data?.porfolio_url}</p>
            </div>
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Date of Application</h5>
              <p>{data?.application_date}</p>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  data: state.jobs.jobApplicationDetails.data?.data,
  loading: state.jobs.jobApplicationDetails.loading,
  error_msg: state.jobs.jobApplicationDetails.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(ViewApplication);
