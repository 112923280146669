import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { TextField, FileImageModalOpener, TextEditor, TextFieldTag, Toggler, RelatedPost, SelectField, CustomEditor } from "../../components/widget/form/FormComponent";
import { Link, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_POST, GET_CATEGORYS } from "../../store/actions/action_types/action.types";
import { postURL, categoryURL } from "../../utils/endpoint";
import { useEffect, useState } from "react";
import { calculateReadTime } from "../../utils/helpers";

const AddPostPage = ({ postAction, getAction, file, input_name, tags, name, relatedPosts, category_data, post_data, ...props }) => {
  const { formState, handleSubmit, register, control } = useForm({
    mode: "onChange",
    defaultValues: { save_as_draft: false, publish_later: false },
  });
  const [postData, setPostData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [text, setText] = useState("");

  const onSubmit = data => {
    data.featured_image_url = file.featured_image_url?.image_url;
    // console.log(data.time);
    const date_time = new Date(`${data.date}T${data.time}Z`);
    data.publish_later_date = date_time.toISOString();
    data.tags = tags[name];
    data.related_post = relatedPosts?.related_post;
    ///

    postAction(ADD_POST, postURL, {
      title: data.title,
      excerpt: data.excerpt,
      author: data.author,
      category: data.category.id,
      featured_image_url: data.featured_image_url,
      body: props.new_post.new_post || "",
      related_posts: data.related_post.length > 0 ? data.related_post : null,
      tags: JSON.stringify(data.tags),
      save_as_draft: data.save_as_draft,
      publish_later: data.publish_later,
      publish_later_date: data.publish_later_date,
      read_time: calculateReadTime(text),
    });
  };
  // const postCategory = [0, 1, 2];
  useEffect(() => {
    if (category_data) {
      setCategoryData(category_data);
    }
  }, [category_data]);

  useEffect(() => {
    if (post_data) {
      setPostData(post_data);
    }
  }, [post_data]);

  useEffect(() => {
    getAction(GET_CATEGORYS, categoryURL);
  }, [getAction]);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <section className="mx-auto mt-[1.5rem] mb-[2rem] w-full">
      <Link to={"/posts"} className="inline-flex items-center justify-start cursor-pointer text-[#992333] font-medium mt-6 hover:underline">
        <IoChevronBackOutline />
        <span className="ml-1 text-sm">Back</span>
      </Link>
      <header className="mb-2">
        <h2 className="text-[1.5rem] leading-[2rem] mt-[0.5rem] mb-[1rem]">New Post</h2>
      </header>
      <main className="my-4 md:my-8">
        <form onKeyDown={e => checkKeyDown(e)} className="" onSubmit={handleSubmit(onSubmit)}>
          <TextField label="Post Title*" name="title" type="text" error={formState.errors.title} {...register("title", { required: false })} />
          <TextField label="Excerpt/Caption*" name="excerpt" type="textarea" error={formState.errors.excerpt} {...register("excerpt", { required: false })} />
          <TextField label="Author" name="author" type="text" error={formState.errors.author} {...register("author", { required: false })} />
          <SelectField item={categoryData} label="Post Category*" control={control} mb="2xl:mb-6 lg:mb-3 mb-6" selected_item="category_name" name="category" error={formState.errors.category} {...register("category", { required: false })} />
          <FileImageModalOpener label="Featured Image" control={control} value={file[input_name]?.image_title} name="featured_image_url" {...register("featured_image_url", { required: false })} />
          <TextEditor label="Post Body*" name="body" control={control} getText={setText} {...register("body", { required: false })} />
          <RelatedPost postsData={postData} relatedPostData={[]} label="Related Posts" control={control} name="related_post" error={formState.errors.related_post} {...register("related_post", { required: false })} />
          <TextFieldTag label="Tags" control={control} name="tags" error={formState.errors.tags} {...register("tags", { required: false })} />
          <div className="mb-6">
            <label className="text-black text-opacity-80">Post Options</label>
            <div className="flex items-center justify-start mt-1">
              <Toggler name="save_as_draft" label={["Save As Draft"]} control={control} {...register("save_as_draft", { required: false })} />
              <Toggler name="publish_later" label={["Publish Later"]} control={control} {...register("publish_later", { required: false })} />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">
            <TextField label="Publish Date*" name="date" error={formState.errors.date} {...register("date", { required: false })} type="date" className="" />
            <TextField label="Publish Time*" name="time" error={formState.errors.time} {...register("time", { required: false })} type="time" />
          </div>
          <div className="flex items-center justify-end mt-6">
            <AppButton name="Publish Now" loading={props.loading} className="p-4 bg-[#992333] text-[#fff]" type="submit" />
          </div>
        </form>
      </main>
    </section>
  );
};

const mapStateToProps = state => ({
  file: state.ui.input_file,
  loading: state.post.addPost.loading,
  addPost_data: state.post.addPost.data,
  input_name: state.ui.input_file?.name,
  tags: state.ui.tag,
  name: state.ui.tag.name,
  relatedPosts: state.ui.relatedPost,
  new_post: state.ui.createNewPost,
  category_data: state.post.categoryList.data,
  post_data: state.post.postList.data,
});

export default connect(mapStateToProps, { postAction, getAction })(AddPostPage);
