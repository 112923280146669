import React, { useState } from "react";
import { Disclosure, Menu, Switch } from "@headlessui/react";
import { HiChevronRight } from "react-icons/hi";
import { CustomDisclosure } from "./expandable.style";
import { IoAddOutline } from "react-icons/io5";
import AppModal from "../Modal/Modal";
import Loader from "../Loader/Loader";

const Expandable = ({ component, className, name, length, showToggle, ...props }) => {
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Disclosure key={props.key}>
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <div className="bg-white border-b">
            <Disclosure.Button className="flex items-center w-full justify-between px-6 py-4 cursor-pointer">
              <div className="flex flex-1">
                <div className="flex justify-between items-center ">
                  <h2 className="text-lg md:text-xl font-normal flex Left">{name}</h2>
                  {(props.hasCount != null || length) && (
                    <span className="ml-[0.5rem] flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[0.5rem] text-[0.875rem] leading-[1.25rem] bg-[#c2cfd633]">{props.hasCount}</span>
                  )}
                </div>
              </div>
              <div className="flex-1">
                {props.loading && (
                  <div>
                    <Loader loading={props.loading} color="#ccc" />
                  </div>
                )}
              </div>
              <div className="flex-1 flex justify-end items-center">
                <div className="flex justify-between items-center">
                  {props.hasPlus && (
                    <div className="mx-3">
                      <IoAddOutline className="text-2xl text-black text-opacity-60 hover:text-opacity-100" onClick={() => setOpen(true)} />
                    </div>
                  )}
                  {showToggle && (
                    <div className="mr-5">
                      <Switch
                        checked={toggle}
                        onChange={setToggle}
                        className={`${toggle ? "bg-red-300" : "bg-[#f2f2f2]"}
          relative inline-flex w-[2.5rem] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${toggle ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-[1.25rem] w-[1.25rem] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>
                  )}
                  <HiChevronRight className={`${open ? "rotate-90 transform" : ""} text-lg font-thin`} />
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className={"px-6 py-4"}>{component}</Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      <AppModal open={open} onClose={() => setOpen(false)} content={props.modalcontent || <div>wassa</div>} />
    </>
  );
};

export default Expandable;
