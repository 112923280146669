import { useForm } from "react-hook-form";
import { IoGridOutline, IoImagesOutline } from "react-icons/io5";
import { TextField } from "../../components/widget/form/FormComponent";
import { AppButton } from "../../components/widget/button/Button";

export const MediaModal = () => {
  const { handleSubmit, formState, register } = useForm({ mode: "onChange" });

  const onSubmit = data => {};
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[95vw] min-h-[90vh] relative flex flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
          <div className="flex flex-col items-center justify-center text-center px-2">
            <IoImagesOutline className="text-6xl text-black text-opacity-20 mb-3 mt-3" />
            <div className="font-semibold text-xl md:text-3xl mb-1">
              Upload Media
            </div>
            <div className="text-black text-center text-opacity-50 text-sm">
              Choose the Images and Videos you wish to upload
            </div>
          </div>
        </header>
        <main className="py-3 border-opacity-0 mt-[1.5rem] h-[100%] w-[100%] overflow-y-auto overflow-x-hidden px-[1.5rem] transition-all duration-500 mx-h-[50vh]">
          <div className="w-full px-3">
            <div className="flex flex-col items-center justify-center">
              <AppButton
                name="Select Files"
                className="bg-gray-300 bg-opacity-30 text-black text-opacity-80 px-5 h-[50px]"
              />
              <p className="mt-3 text-black">Maximum File Size: 1MB</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
