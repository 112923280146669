import React, { useEffect } from "react";
import Expandable from "../../components/widget/Expandable/Expandable";
import { connect } from "react-redux";
import { deleteAction, editAction, getAction } from "../../store/actions/creators/request.actions";
import { jobProcessData } from "./job.data";
import Table from "../../components/widget/table/Table";
import AppModal from "../../components/widget/Modal/Modal";
import DeleteModal from "./DeleteModal";
import { openModal } from "../../store/actions/creators/ui.actions";
import { useDeleteJobMutation, useGetJobsQuery, useUpdateJobMutation } from "../../services/job.services";
import { LoaderII } from "../../components/widget/Loader/Loader";

const JobsTable = ({ tableData, formatData, loading }) => {
  const [updateJob, { isLoading }] = useUpdateJobMutation();
  const data = tableData.map(formatData);

  const publishHandler = (publish, id) => {
    updateJob({ id, published: !publish });
  };
  return loading || isLoading ? (
    <div className="flex justify-center items-center">
      <LoaderII />
    </div>
  ) : (
    <Table tableData={data} no_option no_view publish publishHandler={publishHandler} />
  );
};

const Jobs = ({ openModal, id, ...props }) => {
  const { data: jobsData = [], isLoading: jobsLoading } = useGetJobsQuery();
  const [deleteJob, { isLoading, isSuccess }] = useDeleteJobMutation();

  const publishedJobs = jobsData.filter(item => item.published === true);
  const unpublishedJobs = jobsData.filter(item => item.published === false);

  const ExpandableContent = [
    {
      name: "Published Jobs",
      hasCount: publishedJobs.length,
      Component: () => <JobsTable tableData={publishedJobs} formatData={jobProcessData} edit={props.editAction} />,
    },
    {
      name: "Unpublished Jobs",
      hasCount: unpublishedJobs.length,
      Component: () => <JobsTable tableData={unpublishedJobs} formatData={jobProcessData} edit={props.editAction} loading={jobsLoading} />,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      openModal("CLOSE_MODAL");
    }
  }, [isSuccess]);

  const handleDeleteJob = () => {
    if (id) {
      // console.log(id);
      deleteJob(id);
    }
  };
  return (
    <div>
      <main className="rounded-lg mt-8 overflow-hidden min-h-screen w-full lg:w-4/5 xl:w-full">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} key={content.name} component={<Component />} />
        ))}
      </main>
      <AppModal
        open={props.openDelete}
        onClose={() => {
          openModal("CLOSE_MODAL");
          // getAction(GET_JOBS, jobURL, token);
        }}
        content={<DeleteModal onClick={handleDeleteJob} loading={isLoading} id={id} />}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.jobs.jobList.loading,
  jobs_data: state.jobs.jobList.data,
  deleteData: state.jobs.deleteJob.data,
  editData: state.jobs.editJob.data,
  loading_delete: state.jobs.deleteJob.loading,
  error_msg: state.jobs.jobList.error_msg,
});

export default connect(mapStateToProps, {
  getAction,
  deleteAction,
  openModal,
  editAction,
})(Jobs);
