import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { AppButton } from "../../components/widget/button/Button";
import { TextEditor, TextField } from "../../components/widget/form/FormComponent";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { connect } from "react-redux";
import { privacyMetaURL, privacyPolicyURL } from "../../utils/endpoint";
import { mapStateToPropsPrivacy } from "../../utils/helpers";
import { ADD_PRIVACY_POLICY, GET_PRIVACY_POLICY, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { useEffect } from "react";

export const GeneralPrivacy = connect(null, { postAction })(({ checkKeyDown, ...props }) => {
  const { formState, control, register, handleSubmit } = useForm({ mode: "onChange" });

  const submitForm = data => {
    alert(JSON.stringify(data));
    // props.postAction(ADD_CONTACT, contactURL, data, props.token);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} onKeyDown={e => checkKeyDown(e)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" />
      </div>
      <TextField label="Title" name="title" type="text" {...register("title", { required: false })} />
    </form>
  );
});

const PrivacyPolicy = connect(mapStateToPropsPrivacy, { postAction })(({ checkKeyDown, ...props }) => {
  const { control, formState, register, handleSubmit } = useForm({ mode: "onChange" });

  let { privacy_data } = props.data;
  privacy_data = privacy_data && privacy_data[privacy_data.length - 1];

  const onSubmit = data => {
    props.postAction(ADD_PRIVACY_POLICY, privacyPolicyURL, data, props.token);
  };
  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" loading={props.sending_policy} />
        </div>
        <TextField label="Title" defaultValue={privacy_data?.title} name="title" type="text" {...register("title", { required: false })} />

        <TextEditor control={control} defaultValue={privacy_data?.privacy_policy} label="Privacy Policy" {...register("privacy_policy", { required: false })} />
      </div>
    </form>
  );
});

const ExpandableContent = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={privacyMetaURL} />,
  },
  // { name: "General", Component: props => <GeneralPrivacy {...props} /> },
  { name: "Privacy Policy", Component: props => <PrivacyPolicy {...props} /> },
];

const SitePrivacy = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getAction(GET_SITE_META, privacyMetaURL, token);
    props.getAction(GET_PRIVACY_POLICY, privacyPolicyURL, token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Privacy" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  privacy_data: state.site.privacy.get_privacy_policy.data,
});
export default connect(mapStateToProps, { getAction })(SitePrivacy);
