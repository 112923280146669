import { useState } from "react";
import { IoCloudOfflineOutline, IoPencilOutline, IoTrashBinOutline } from "react-icons/io5";
import { FaqButton } from "../button/Button";
import AppModal from "../Modal/Modal";
import { ContentViewDiv } from "./action.style";

const ViewContent = () => (
  <ContentViewDiv className="content relative flex flex-col items-center justify-center bg-white rounded-lg mid w-[200px]">
    <header className="relative flex items-center justify-center flex-col mt-4 px-6 pt-4 rounded-tl-lg rounded-tr-lg">
      <div className="flex flex-col justify-center items-center text-center px-2">
        <div className="font-semi-bold text-xl md:text-3xl mb-1">Solomon Ndifereke</div>
      </div>
    </header>
    <main className="border-t">wassa</main>
  </ContentViewDiv>
);

export const ActionView = ({ icon, id }) => {
  const [open, setOpen] = useState(false);
  const handleViewPress = () => {
    setOpen(true);
    // alert(id);
  };
  return (
    <>
      <div className="mt-2">
        <div className={`inline-flex w-auto ${icon && "bg-white"} items-center justify-center rounded-[0.5rem] border`}>
          <FaqButton type="first" icon={icon || <IoCloudOfflineOutline />} onPress={icon && handleViewPress} />
          <FaqButton icon={<IoPencilOutline color="black" />} />
          <FaqButton icon={<IoTrashBinOutline color="red" />} />
        </div>
      </div>
      <AppModal open={open} onClose={() => setOpen(false)} content={<ViewContent />} />
    </>
  );
};
