import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ADD_DEFAULT_USER_PASSWORD, GET_DEFAULT_USER_PASSWORD } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { securityPasswordURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { PasswordField } from "../widget/form/FormComponent";

const DefaultPassword = ({ token, getAction, postAction, ...props }) => {
  const { formState, register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    getAction(GET_DEFAULT_USER_PASSWORD, securityPasswordURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("default_password", props.data[0].name);
    }
  }, [props.data, setValue]);

  const submitHandler = data => {
    // data.hotline = "+2349099976980";
    console.log(data);
    postAction(ADD_DEFAULT_USER_PASSWORD, securityPasswordURL, data, token);
  };
  return (
    <div className="mb-4 xl:w-3/5">
      <p className="mb-4">Set Default Password for New User Accounts.</p>
      <form onSubmit={handleSubmit(submitHandler)}>
        <PasswordField label="Password" name="default_password" placeholder="*******" error={formState.errors.default_password} {...register("default_password", { required: true })} />
        <div className="flex justify-end pt-5">
          <AppButton loading={props.loading} name={`Save`} type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addDefaultPassword.loading,
  data: state.settings.defaultPassword.data,
  postData: state.settings.addDefaultPassword.data,
  error_msg: state.settings.addDefaultPassword.error_msg,
  fetch_error_msg: state.settings.addDefaultPassword.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(DefaultPassword);
