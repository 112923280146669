import { Route, Routes, useNavigate } from "react-router-dom";
import AddAttendance from "../../pages/Attendance/AddAttendance";
import Attendance from "../../pages/Attendance/Attendance";

const AttendanceRoute = () => {
  let navigate = useNavigate();
  return (
    <div>
      <h1 className="pageTitle">Attendance</h1>
      <span
        onClick={() => navigate("/attendance/new")}
        className="text-blue-400 mb-4 inline-block cursor-pointer"
      >
        + Add Attendance
      </span>
      <Routes>
        <Route path="/" element={<Attendance />} />
        <Route path="/new" element={<AddAttendance />} />
      </Routes>
    </div>
  );
};

export default AttendanceRoute;
