import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
};

const getCareerGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_CAREER_GENERAL}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CAREER_GENERAL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setCareerGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_CAREER_GENERAL}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_CAREER_GENERAL:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getCareerIntroReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_CAREER_INTRO}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CAREER_INTRO:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setCareerIntroReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_CAREER_INTRO}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_CAREER_INTRO:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getCareerPageContentReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_CAREER_PAGECONTENT}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CAREER_PAGECONTENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setCareerPageContentReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_CAREER_PAGECONTENT}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_CAREER_PAGECONTENT:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getJobOpeningReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_JOB_OPEN}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_JOB_OPEN:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setJobOpeningReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_JOB_OPEN}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_JOB_OPEN:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const careerReducer = combineReducers({
  get_career_gen: getCareerGeneralReducer,
  set_career_gen: setCareerGeneralReducer,
  get_career_intro: getCareerIntroReducer,
  set_career_intro: setCareerIntroReducer,
  get_career_content: getCareerPageContentReducer,
  set_career_content: setCareerPageContentReducer,
  get_job_open: getJobOpeningReducer,
  set_job_open: setJobOpeningReducer,
});
