import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  userToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser(state, action) {
      state.userDetails = action.payload;
    },
    setUserToken(state, action) {
      state.userToken = action.payload;
    },
    nullifyToken(state) {
      state.userToken = null;
    },
  },
});

export const { addUser, setUserToken, nullifyToken } = userSlice.actions;
export default userSlice.reducer;
