import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutes from "./app.routes";
import AuthRoute from "./auth.routes";
import { connect } from "react-redux";

const PrivateRoute = ({ children, ...props }) => {
  if (!props.token) {
    return <Navigate to="/auth/login" />;
  }
  return children;
};

const IndexRoute = props => {
  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <PrivateRoute {...props}>
              <AppRoutes />
            </PrivateRoute>
          }
        />
        <Route path="/auth/*" element={<AuthRoute />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </>
  );
};

const mapStateToProps = state => ({
  token: state.user.userToken,
});

export default connect(mapStateToProps, {})(IndexRoute);
