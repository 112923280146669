import { combineReducers } from "redux";
import * as type from "../../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../../actions/action_types/error.types";

const intitialState = {
  loading: false,
  sending: false,
  error: false,
  error_msg: null,
  data: null,
};

const getTestiGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_TESTI_GENERAL}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_TESTI_GENERAL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setTestiGeneralReducer = (state = intitialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_TESTI_GENERAL}`:
      return {
        ...state,
        sending: true,
      };
    case type.ADD_TESTI_GENERAL:
      return {
        ...state,
        sending: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const testimonialReducer = combineReducers({
  get_testi_general: getTestiGeneralReducer,
  set_testi_general: setTestiGeneralReducer,
});
