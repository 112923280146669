import * as type from "../actions/action_types/action.types";
import { combineReducers } from "redux";
import { ERROR_OCCURED, LOGIN_ERROR, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  login_loading: false,
  error: false,
  error_msg: null,
  token: null,
  successFlag: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
        error_msg: null,
      };
    case type.LOGIN:
      return {
        ...state,
        loading: false,
        token: action.payload.data.data.access_token,
        success: action.payload.data.success,
      };
    case type.VALIDATE_TOKEN:
      return {
        ...state,
        login_loading: false,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        login_loading: false,
        token: null,
        data: null,
      };
    case type.LOGOUT:
      return {
        ...state,
        loading: false,
        login_loading: false,
        token: null,
        data: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const resetReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.RESET:
      return {
        ...state,
        loading: true,
        error: false,
        error_msg: null,
      };
    case type.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        successFlag: action.payload?.data?.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const resetEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.RESETEMAIL:
      return {
        ...state,
        loading: true,
        error: false,
        successFlag: false,
        error_msg: null,
      };
    case type.RESETEMAIL:
      return {
        ...state,
        loading: false,
        error: false,
        successFlag: action.payload?.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        successFlag: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getIpAddress = (state = {}, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_IP:
      return {
        ...state,
        loading: true,
        login_loading: true,
      };
    case type.GET_IP:
      return {
        ...state,
        loading: false,
        login_loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        login_loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const authReducer = combineReducers({
  login: loginReducer,
  reset: resetReducer,
  resetEmail: resetEmailReducer,
  get_ip: getIpAddress,
});
