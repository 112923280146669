import React from "react";
import { Route, Routes } from "react-router-dom";
import Recover from "../pages/Auth/Recover";
import Reset from "../pages/Auth/Reset";
import { Login } from "../pages";

const AuthRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/recover-password" element={<Recover />} />
      <Route path="/reset-password" element={<Reset />} />
    </Routes>
  );
};

export default AuthRoute;
