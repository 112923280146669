import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../components/widget/button/Button";
import { openModal } from "../../store/actions/creators/ui.actions";

const DeleteModal = ({ loading, openModal, ...props }) => {
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <h1 className="my-2 font-bold text-xl">Are you sure you want to delete ?</h1>
        </header>
        <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
          <div className="flex justify-between px-6">
            <AppButton onPress={() => openModal("CLOSE_MODAL")} className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" name={"Cancel"} />
            <AppButton name={"Delete"} onPress={props.onClick} loading={loading} type="submit" />
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { openModal })(DeleteModal);
