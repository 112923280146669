import React, { useEffect, useState } from "react";
import { Checkbox, TextField } from "../../components/widget/form/FormComponent";
import { useForm } from "react-hook-form";
import { AppButton } from "../widget/button/Button";
import { IoCloseOutline, IoPersonOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { openModal } from "../../store/actions/creators/ui.actions";

const useCheckboxState = (allCheck = false, initialCheck = false) => {
  const [checked, setChecked] = useState(initialCheck);
  useEffect(() => {
    setChecked(allCheck);
  }, [allCheck]);

  return {
    checked,
    onChange: e => {
      setChecked(e.target.checked);
    },
  };
};

const roleForm = [
  {
    id: 1,
    label: "Analytics",
    options: [
      {
        id: 1,
        name: "analytics-view",
        label: "View",
      },
    ],
  },
  {
    id: 2,
    label: "Site",
    options: [
      {
        id: 2,
        name: "site-view",
        label: "View",
      },
      {
        id: 3,
        name: "site-update",
        label: "Update",
      },
    ],
  },
];

const checkBoxForm = [
  {
    id: 1,
    name: "anaytic-view",
  },
  {
    id: 2,
    name: "site-view",
  },
  {
    id: 3,
    name: "site-update",
  },
];

const checkBoxArr = ["site-view", "site-update", "analytics-view"];

const AddRoleModal = ({ openModal, ...props }) => {
  const { formState, register, control, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });
  // const [checkAll, setCheckAll] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  // useEffect(() => {
  //   setList(checkBoxForm);
  //   // console.log(roleForm.map(li => li.options.map(item => item.id)));
  // }, [list]);

  const handleSelectAll = isCheckAll => {
    // console.log(isCheckAll);
    setIsCheckAll(!isCheckAll);
    setIsCheck(checkBoxForm);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // useEffect(() => {
  //   console.log("select all");
  //   handleSelectAll(isCheckAll);
  // }, [isCheckAll]);

  const handleClick = e => {
    const { id, checked, name } = e.target;
    // console.log({ id, checked });
    setIsCheck([...isCheck, { id, name }]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item.id !== id));
    }
    // console.log(list);
  };

  // console.log("list", isCheck);

  const agreement = useCheckboxState();
  const submitForm = data => {
    data.accepted_val = isCheck.map(item => {
      return { [item.name]: true };
    });
    // alert(JSON.stringify(data));
    // alert(JSON.stringify(data), isCheck);
    // alert(isCheck);

    // console.log({ ...data, ...isCheck });
    // console.log(
    //   ...isCheck.map(item => {
    //     return { [item.name]: true };
    //   })
    // );
    console.log(isCheck);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="bg-white w-full rounded-lg md:w-full">
      <div className="relative flex items-center gap-2 flex-col my-4 px-6 pt-12">
        <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
        <IoPersonOutline className="text-[#D4D4D4]" size={50} />
        <h2 className="font-semibold text-xl md:text-3xl mt-2 mb-1">Add Role</h2>
        <p className="text-center">Create role and assign permissions accordingly.</p>
      </div>
      <div className="border-t border-b pb-10 max-h-[50vh] overflow-y-scroll px-6 border-grey py-3 border-opacity-0">
        <div className="w-full px-3">
          <TextField label="Name of Role" name="rolename" error={formState.errors["rolename"]} {...register("rolename")} type="text" />
          <h5>Permissions</h5>
          <p className="mt-2 text-black text-opacity-40">Select the permissions you want this role to have.</p>
          <div className="flex my-4 gap-2">
            <span
              onClick={() => {
                // setValue("", true);
                setIsCheckAll(false);
                handleSelectAll(false);
              }}
              className="text-red-400 cursor-pointer font-light"
            >
              Select All {JSON.stringify(isCheckAll)}
            </span>
            <span
              onClick={() => {
                setValue(false);
                setIsCheckAll(true);
                handleSelectAll(true);
              }}
              className="text-red-400 cursor-pointer font-light"
            >
              Deselect All
            </span>
          </div>
          {roleForm.map(item => (
            <div key={item.id}>
              <label htmlFor={item.label} className="">
                {item.label}
              </label>
              <div className="flex gap-2">
                {item.options.map(option => (
                  <Checkbox
                    key={option.id}
                    id={option.id}
                    name={option.name}
                    // value={isCheck}
                    // {...register(option.name)}
                    // control={control}
                    // checkAll={checkAll}
                    handleClick={handleClick}
                    isChecked={isCheck.find(val => val.id == option.id) ? true : false}
                    label={option.label}
                  />
                ))}

                {/* <Checkbox
                  name="site-update"
                  checkAll={checkAll}
                  control={control}
                  {...register("site-update")}
                  label={"Update"}
                /> */}
              </div>
            </div>
          ))}
          {/* <div>
            <label htmlFor="analytics" className="">
              Post
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState(checkAll)}
                name="post-view"
                {...register("post-view")}
                size="w-6 h-6"
                control={control}
                label={"Create"}
              />
              <Checkbox
                {...useCheckboxState(checkAll)}
                {...register("post-publish")}
                name="post-publish"
                control={control}
                size="w-6 h-6"
                label={"Publish"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Media
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Add"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Jobs & Positions
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Add"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Newsletters & Subscribers
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Create"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Messages
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Create"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Attendance & Appointments
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Add"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Support
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Chart"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Users & Roles
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Add"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Delete"}
              />
            </div>
          </div>
          <div>
            <label htmlFor="analytics" className="">
              Settings
            </label>
            <div className="flex gap-2">
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"View"}
              />
              <Checkbox
                {...useCheckboxState()}
                control={control}
                size="w-6 h-6"
                label={"Update"}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex justify-end p-4">
        <div className="flex gap-2">
          <AppButton name="Save" type="submit" />
          <AppButton onPress={() => openModal("CLOSE_MODAL")} name="Cancel" className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" />
        </div>
      </div>
    </form>
  );
};

export default connect(null, { openModal })(AddRoleModal);
