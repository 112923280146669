import { LOADING, SENDING } from "../action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED, LOGIN_ERROR } from "../action_types/error.types";
import { makeRequest } from "../../../api/request.api";
import { toast } from "sonner";

export const postAction =
  (type, url, app_data = null, token = null, fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: SENDING + "_" + type });
    try {
      const { data } = await fetchFn(url, app_data, token);
      if (type === "LOGIN" && token === null) {
        toast.success("Login Successfully");
      } else {
        toast.success("Added Successfully");
      }
      dispatch({ type, payload: { data } });
    } catch (error) {
      if (token == null && type == "LOGIN") {
        dispatch({ type: LOGIN_ERROR, payload: error.message });
      } else if (token == null && type == "RESET") {
        dispatch({ type: LOGIN_ERROR, payload: error.message });
      } else {
        dispatch({ type: POST_ERROR_OCCURED, payload: error.message });
      }
    }
  };

export const getAction =
  (type, url, token = null, noLoad = null, fetchFn = makeRequest) =>
  async dispatch => {
    if (noLoad == null || noLoad !== false) dispatch({ type: LOADING + "_" + type });

    try {
      const { data } = await fetchFn(url, null, token);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({ type: ERROR_OCCURED, payload: error.message });
    }
  };

export const editAction =
  (type, url, app_data = null, token = null, method_type = "PATCH", fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: SENDING });

    try {
      const { data } = await fetchFn(url, app_data, token, method_type);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({ type: ERROR_OCCURED, payload: error.message });
    }
  };

export const deleteAction =
  (type, url, token = null, method_type = "DELETE", fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: SENDING });
    try {
      const { data } = await fetchFn(url, null, token, method_type);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({ type: ERROR_OCCURED, payload: error });
    }
  };
