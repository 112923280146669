import { IoPencilSharp } from "react-icons/io5";
import { Route, Routes, useNavigate } from "react-router-dom";
import Newsletter from "../../pages/Newsletters/Newsletter";
import DraftNewsletter from "../../pages/Newsletters/DraftNewsletter";
import Subscriber from "../../pages/Newsletters/Subscribers";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";
import { LinkButton } from "../../components/widget/button/Button";
import { connect } from "react-redux";
import { openModal } from "../../store/actions/creators/ui.actions";
import AddSubscriber from "../../pages/Newsletters/AddSubscriber";

const tabNames = {
  Newsletter: "",
  Subscribers: "",
};

const Extras = ({ openModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-start">
      <LinkButton
        name="Draft Newsletter"
        icon=<IoPencilSharp />
        onPress={() => navigate("/newsletters/new")}
      />
      <LinkButton
        name="+ Add Attendance"
        onPress={() => openModal("OPEN_MODAL")}
      />
    </div>
  );
};

const NewsletterRoute = ({ ...props }) => {
  return (
    <div>
      <div className="mb-8 mt-11 sm:px-0">
        <TestTab
          hideHead
          tabs={tabNames}
          content={<Extras {...props} />}
          modal_content={<AddSubscriber />}
          name="Newsletters"
        />
      </div>
      <Routes>
        <Route path="/" element={<Newsletter />} />
        <Route path="/new" element={<DraftNewsletter />} />
        <Route path="/subscribers" element={<Subscriber />} />
      </Routes>
    </div>
  );
};

export default connect(null, { openModal })(NewsletterRoute);
