import { connect } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddRoleModal from "../../components/users/AddRoleModal";
import AddUserModal from "../../components/users/AddUserModal";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";
import Roles from "../../pages/Users/Roles";
import { LinkButton } from "../../components/widget/button/Button";
import { openModal } from "../../store/actions/creators/ui.actions";
import Users from "../../pages/Users/Users";

const tabNames = {
  Users: "",
  Roles: "",
};

const Extras = ({ openModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-start">
      <LinkButton name="+ Add User" onPress={() => openModal("OPEN_MODAL", 1)} />
      <LinkButton name="+ Add Roles" onPress={() => openModal("OPEN_MODAL", 2)} />
    </div>
  );
};

const UserRoute = ({ match, modal_data, ...props }) => {
  return (
    <div>
      <div className="mb-8 mt-11 sm:px-0">
        <TestTab tabs={tabNames} name="Users & Roles" content={<Extras {...props} />} modal_content={modal_data === 1 ? <AddUserModal /> : <AddRoleModal onClose={() => openModal("CLOSE_MODAL")} />} match={match} />
      </div>
      <Routes>
        <Route path="/" element={<Users />} />
        <Route path="roles" element={<Roles />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = state => ({
  modal_data: state.ui.modal.data,
});

export default connect(mapStateToProps, { openModal })(UserRoute);
