export const newsLetterProcessData = (data, idx) => ({
  "#": idx + 1,
  id: data.id,
  Subject: data.subject,
  Sent: data.body,
});

export const subscriberProcessData = (data, idx) => ({
  "#": idx + 1,
  Name: data.subscriber_name,
  Email: data.subscriber_email,
  Phone: data.subscriber_phone,
});
