import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";
import { LinkButton } from "../../components/widget/button/Button";
import AddPositions from "../../pages/Jobs/AddPositions";
import EditJobs from "../../pages/Jobs/EditJobs";
import JobApplications from "../../pages/Jobs/JobApplications";
import Jobs from "../../pages/Jobs/Jobs";
import NewJobs from "../../pages/Jobs/NewJobs";
import Positions from "../../pages/Jobs/Position";
import { openModal } from "../../store/actions/creators/ui.actions";

const tabNames = {
  "Jobs Application": "",
  Jobs: "",
  Positions: "",
};

const Extras = ({ openModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-start">
      <LinkButton name="+ Add Job" onPress={() => navigate("/jobs/new")} />
      <LinkButton name="+ Add Position" onPress={() => openModal("OPEN_MODAL")} />
    </div>
  );
};

const JobsRoute = ({ ...props }) => {
  const location = useLocation();

  const defaultRoute = location.pathname.split("/")[2];

  return (
    <div>
      <div className="mb-8 mt-11 sm:px-0">
        <TestTab name="Jobs" tabs={tabNames} defaultIndex={defaultRoute === "jobs" ? 1 : defaultRoute === "positions" ? 2 : 0} content={<Extras {...props} />} modal_content={<AddPositions />} />
      </div>
      <Routes>
        <Route path="/" element={<JobApplications />} />
        <Route path="/new" element={<NewJobs />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/:id/edit" element={<EditJobs />} />
        <Route path="/positions" element={<Positions />} />
      </Routes>
    </div>
  );
};

export default connect(null, { openModal })(JobsRoute);
