import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoBriefcaseOutline, IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";
import { editAction, getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { useGetPositionQuery, useUpdatePositionMutation } from "../../services/position.services";
import { toast } from "sonner";

const EditPosition = ({ id, getAction, token, editAction, ...props }) => {
  const { formState, register, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const { data: positionDetails = {} } = useGetPositionQuery(id, { skip: !id });
  const [updatePosition, { isLoading, isError }] = useUpdatePositionMutation();

  useEffect(() => {
    if (positionDetails) {
      setValue("title_of_position", positionDetails.title_of_position);
      setValue("job_description", positionDetails.job_description);
      setValue("job_responsibilities", positionDetails.job_responsibilities);
    }
  }, [positionDetails, setValue]);

  useEffect(() => {
    if (isError) {
      // console.log(isError);
      toast.error("Something went wrong!");
    }
  }, [isError]);

  const onSubmit = data => {
    updatePosition({ id, data });
  };
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex flex-col items-center justify-center bg-white rounded-lg h-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-white w-full rounded-lg md:w-full">
          <div className="relative flex items-center gap-2 flex-col my-4 px-6 pt-12">
            <IoCloseOutline onClick={() => props.openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
            <IoBriefcaseOutline className="text-[#D4D4D4]" size={50} />
            <h2 className="font-semibold text-xl md:text-3xl mt-2 mb-1">Edit Position</h2>
            <p className="text-center">What would you like to call the new position?</p>
          </div>
          <main>
            <div className="px-8">
              <TextField label="Title of Position" name="title_of_position" error={formState.errors["title_of_position"]} {...register("title_of_position", { required: true })} type="text" />
              <TextField label="Job Description" name="job_description" error={formState.errors["job_description"]} {...register("job_description", { required: true })} type="textarea" />
              <TextField label="Job Responsibilities" name="job_responsibilities" error={formState.errors["job_responsibilities"]} {...register("job_responsibilities", { required: true })} type="textarea" />
            </div>
          </main>
          <footer>
            <div className="flex justify-end p-4">
              <div className="flex gap-2">
                <AppButton name="Save" type="submit" loading={isLoading} disabled={isLoading} />
                <AppButton name="Cancel" onPress={() => props.openModal("CLOSE_MODAL")} className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" />
              </div>
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal?.open,
  // positionDetails: state.jobs.jobPositionDetails.data.data,
  // loading: state.jobs.jobPositionDetails.loading,
  // error_msg: state.jobs.jobPositionDetails.error_msg,
});

export default connect(mapStateToProps, { openModal, getAction, editAction })(EditPosition);
