import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import AppModal from "../../components/widget/Modal/Modal";
import MyTable from "../../components/widget/table/myTable";
import Table from "../../components/widget/table/Table";
import {
  DELETE_NEWSLETTER,
  GET_NEWSLETTERS,
} from "../../store/actions/action_types/action.types";
import {
  deleteAction,
  getAction,
  postAction,
} from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { newsletterURL } from "../../utils/endpoint";
import DeleteModal from "../Jobs/DeleteModal";
import { tableInfo, userTableHeaders } from "../Users/Users";
import { newsLetterProcessData } from "./newsletter.data";
import ViewNewsletter from "./ViewNewsletter";

const NewsLetterTable = ({ tableData }) => {
  const data = tableData.map(newsLetterProcessData);
  return <Table tableData={data} no_option />;
};

const Newsletter = ({
  getAction,
  deleteAction,
  newsletter_data,
  id,
  openModal,
  token,
  ...props
}) => {
  const [newsLetterData, setNewsLetterData] = useState([]);
  const ExpandableContent = [
    {
      name: "Sent",
      hasCount: newsLetterData.length,
      Component: () => <NewsLetterTable tableData={newsLetterData} />,
    },
    {
      name: "Scheduled",
      hasCount: newsLetterData.filter(item => item.publish_later === true)
        .length,
      Component: () => <NewsLetterTable tableData={newsLetterData} />,
    },
    {
      name: "Draft",
      hasCount: newsLetterData.filter(item => item.save_as_draft === true)
        .length,
      Component: () => (
        <NewsLetterTable
          tableData={newsLetterData.filter(item => item.save_as_draft === true)}
        />
      ),
    },
  ];

  useEffect(() => {
    getAction(GET_NEWSLETTERS, newsletterURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (newsletter_data) {
      console.log(newsletter_data);
      setNewsLetterData(newsletter_data);
    }
  }, [newsletter_data]);
  const deleteNewsLetter = () => {
    if (id) {
      const URL = `${newsletterURL}/${id}`;
      deleteAction(DELETE_NEWSLETTER, URL, props.token);
    }
  };

  return (
    <div>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable
            {...content}
            key={content.name}
            component={<Component />}
          />
        ))}
      </main>
      <AppModal
        open={props.open}
        onClose={() => props.openModal("CLOSE_MODAL")}
        content={<ViewNewsletter id={id} />}
      />
      <AppModal
        open={props.openDelete}
        onClose={() => props.openModal("CLOSE_MODAL")}
        content={<DeleteModal deleteHandler={deleteNewsLetter} id={id} />}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.newsletter.newsLetterList.loading,
  newsletter_data: state.newsletter.newsLetterList.data,
  error_msg: state.newsletter.newsLetterList.error_msg,
});

export default connect(mapStateToProps, { getAction, deleteAction, openModal })(
  Newsletter
);
