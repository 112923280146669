import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { ProductGeneralContent as DirectorContent } from "./SiteProduct";
import { ContactInfo as GeneralPrivacy } from "./SiteContact";
import { AppButton } from "../../components/widget/button/Button";
import { TextEditor } from "../../components/widget/form/FormComponent";

const Term = ({ checkKeyDown, ...props }) => {
  const { control, formState, register, handleSubmit } = useForm({ mode: "onChange" });

  const onSubmit = data => {
    alert(JSON.stringify(data));
  };
  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" />
        </div>
        <TextEditor control={control} label="Terms Of Use" {...register("terms_of_use", { required: false })} />
      </div>
    </form>
  );
};

const ExpandableContent = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} />,
  },
  { name: "General", Component: props => <GeneralPrivacy {...props} /> },
  { name: "Terms Of Use", Component: props => <Term {...props} /> },
];

const SiteTerm = props => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Terms" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
      ))}
    </div>
  );
};
export default SiteTerm;
