import { Toaster } from "sonner";
import "../src/components/editor-plugins/pluginStyles.css";
import "./App.css";
import Layout from "./components/Layout/Layout";
import IndexRoute from "./routes/index.routes";

function App() {
  return (
    <div>
      <Toaster richColors position="top-center" />
      <Layout>
        <IndexRoute />
      </Layout>
    </div>
  );
}

export default App;
