import React from "react";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import AppModal from "../../components/widget/Modal/Modal";
import Table from "../../components/widget/table/Table";
import { getAction } from "../../store/actions/creators/request.actions";
import { positionData } from "./job.data";
import { openModal } from "../../store/actions/creators/ui.actions";
import ViewPosition from "./ViewPosition";
import EditPosition from "./EditPosition";
import DeletePosition from "./DeletePosition";
import { useGetPositionsQuery } from "../../services/position.services";
import { LoaderII } from "../../components/widget/Loader/Loader";

const PositionTable = ({ tableData, formatData, loading }) => {
  const data = tableData.map(formatData);
  return loading ? (
    <div className="flex justify-center items-center">
      <LoaderII />
    </div>
  ) : (
    <Table tableData={data} no_option editModal />
  );
};

const Positions = ({ token, getAction, ...props }) => {
  const { data: positionListData = [], isLoading } = useGetPositionsQuery();

  const ExpandableContent = [
    {
      name: "All Positions",
      hasCount: positionListData.length,
      Component: () => <PositionTable tableData={positionListData} formatData={positionData} loading={isLoading} />,
    },
  ];

  return (
    <div>
      <main className="rounded-lg bg-white overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} key={content.name} component={<Component />} />
        ))}
      </main>
      <AppModal open={props.open} onClose={() => props.openModal("CLOSE_MODAL")} content={<ViewPosition id={props.id} />} />
      <AppModal open={props.openDelete} onClose={() => props.openModal("CLOSE_MODAL")} content={<DeletePosition id={props.id} />} />
      <AppModal open={props.openEdit} onClose={() => props.openModal("CLOSE_MODAL")} content={<EditPosition id={props.id} />} />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  openEdit: state.ui.modal?.open_edit,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.jobs.jobPositionList.loading,
  positionList: state.jobs.jobPositionList.data?.data,
  error_msg: state.jobs.jobPositionList.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(Positions);
