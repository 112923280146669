import React from "react";
import { SettingWrapper } from "./settings.style";
import ProfileComponent from "../../components/settings/ProfileComponent";
import SocialsComponent from "../../components/settings/SocialsComponent";
import NotificationComponent from "../../components/settings/NotificationComponent";
import Maintenance from "../../components/settings/Maintenance";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import DefaultPassword from "../../components/settings/DefaultPassword";
import NewTemplate from "../../components/settings/NewTemplate";
import SubTemplate from "../../components/settings/SubTemplate";
const Settings = () => {
  const ExpandableContent = [
    {
      name: "Profile",
      Component: ({ data }) => <ProfileComponent />,
    },
    {
      name: "Social Accounts",
      Component: ({ data }) => <SocialsComponent />,
    },
    {
      name: "Notifications",
      Component: ({ data }) => <NotificationComponent />,
    },
    {
      name: "New Template",
      Component: ({ data }) => <NewTemplate />,
    },
    {
      name: "Subscription Template",
      Component: ({ data }) => <SubTemplate />,
    },
    {
      name: "Default User Passoword",
      Component: ({ data }) => <DefaultPassword />,
    },
    {
      name: "Maintenace Mode",
      Component: ({ data }) => <Maintenance />,
    },
  ];
  return (
    <SettingWrapper>
      <h1 className="pageTitle">Settings</h1>
      <div className="mt-10">
        <>
          <MainPageTopPanel />
          <section className="rounded-lg w-full xl:w-4/5 overflow-hidden">
            {ExpandableContent.slice(0, 3).map(({ Component, ...content }) => (
              <Expandable
                key={content.name}
                {...content}
                component={<Component />}
              />
            ))}
          </section>
          <section>
            <h3 className="text-lg text-secondary font-medium mt-8 mb-3">
              Newsletter
            </h3>
            <div className="rounded-lg w-full xl:w-4/5 overflow-hidden">
              {ExpandableContent.slice(3, 5).map(
                ({ Component, ...content }) => (
                  <Expandable
                    key={content.name}
                    {...content}
                    component={<Component />}
                  />
                )
              )}
            </div>
          </section>
          <section>
            <h3 className="text-lg text-secondary font-medium mt-8 mb-3">
              Security
            </h3>
            <div className="rounded-lg w-full xl:w-4/5 overflow-hidden">
              {ExpandableContent.slice(5, 7).map(
                ({ Component, ...content }) => (
                  <Expandable
                    key={content.name}
                    {...content}
                    component={<Component />}
                  />
                )
              )}
            </div>
          </section>
        </>
      </div>
    </SettingWrapper>
  );
};

export default Settings;
