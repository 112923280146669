import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
};

const getUsersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const createUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SIGN_UP:
      return {
        ...state,
        loading: true,
      };
    case type.SIGN_UP:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const editUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_USERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_USERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const userReducer = combineReducers({
  getUsers: getUsersListReducer,
  getUserDetails: getUserDetailsReducer,
  createUser: createUserReducer,
  deleteAppointment: deleteUserReducer,
  editAppointment: editUserReducer,
});
