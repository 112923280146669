import React from "react";
import { IoCloudDone, IoCloudOffline, IoEye, IoPencilSharp, IoTrashBin, IoWarning } from "react-icons/io5";
import { LoaderII } from "../Loader/Loader";
import { TableContainer } from "./table.style";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../../store/actions/creators/ui.actions";
import { Tooltip } from "react-tooltip";

const Table = props => {
  let navigate = useNavigate();
  const key = props.tableData && props.tableData.length > 0 && Object.keys(props.tableData[0]);
  return (
    <>
      <TableContainer className={`${!props.isHome ? "" : ""} w-full flex flex-col justify-center overflow-auto border-gray-200 sm:rounded-lg`}>
        {props.tableData && props.tableData.length < 1 ? (
          <div className="flex justify-center items-center py-10 px-5">
            <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg bg-gray-50 text-gray-500">
              <IoWarning size={20} /> There are currently no available data for this service
            </span>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            {props.loading ? (
              <div className="flex justify-center items-center py-5">
                <LoaderII loading={props.loading} />
              </div>
            ) : (
              <>
                <thead className="bg-transparent">
                  <tr>
                    {props.tableData &&
                      Object.keys(props.tableData[0]).map(content => (
                        <th key={content} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {content != "publish" && content != "id" && content}
                        </th>
                      ))}
                    {!props.no_action && (
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    )}
                    {!props.no_option && (
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Option
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {props.tableData &&
                    props.tableData.map((content, index) => (
                      <tr key={index}>
                        {key.map(key_name => {
                          return (
                            <td key={key_name} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {key_name != "publish" && key_name != "id" && <>{content[key_name]}</>}
                            </td>
                          );
                        })}
                        {!props.no_action && (
                          <td className={`px-6 w-fit py-4 whitespace-nowrap text-sm text-gray-500 ${props.edit_only ? "" : "flex"}`}>
                            {!props.edit_only ? (
                              <div className="flex divide-x border rounded-lg">
                                <div className="p-2">
                                  {!props.no_view && (
                                    <>
                                      <IoEye
                                        className="cursor-pointer xl:text-xl"
                                        onClick={() => {
                                          props.openModal("VIEW_MODAL", content.id);
                                        }}
                                        data-tooltip-id="view-tooltip"
                                        data-tooltip-content={props.view_tooltip_content || "View"}
                                      />
                                      <Tooltip id="view-tooltip" className="tooltip" />
                                    </>
                                  )}
                                  {props.publish &&
                                    (!content.publish ? (
                                      <>
                                        <IoCloudDone
                                          className="cursor-pointer xl:text-xl"
                                          onClick={() => {
                                            props.publishHandler(content.publish, content.id);
                                          }}
                                          data-tooltip-id="publish-tooltip"
                                          data-tooltip-content={props.publish_tooltip_content || "Publish"}
                                        />
                                        <Tooltip id="publish-tooltip" className="tooltip" />
                                      </>
                                    ) : (
                                      <>
                                        <IoCloudOffline
                                          className="cursor-pointer xl:text-xl"
                                          onClick={() => {
                                            props.publishHandler(content.publish, content.id);
                                          }}
                                          data-tooltip-id="unpublish-tooltip"
                                          data-tooltip-content={props.unpublish_tooltip_content || "Unpublish"}
                                        />
                                        <Tooltip id="unpublish-tooltip" className="tooltip" />
                                      </>
                                    ))}
                                </div>

                                {!props?.no_edit && (
                                  <div className="p-2">
                                    <IoPencilSharp
                                      className="cursor-pointer xl:text-xl"
                                      onClick={() => {
                                        if (props.editModal) {
                                          props.openModal("EDIT_MODAL", content.id);
                                        } else if (props.edit_post) {
                                          navigate(`/posts/${content.id}/edit`, {
                                            state: { id: content.id },
                                          });
                                        } else {
                                          navigate(`/jobs/${content.id}/edit`, {
                                            state: { id: content.id },
                                          });
                                        }
                                      }}
                                      data-tooltip-id="edit-tooltip"
                                      data-tooltip-content={props.edit_tooltip_content || "Edit"}
                                    />
                                    <Tooltip id="edit-tooltip" className="tooltip" />
                                  </div>
                                )}
                                {!props?.no_delete && (
                                  <div className="p-2">
                                    <IoTrashBin
                                      className="cursor-pointer xl:text-xl text-red-500"
                                      onClick={() => {
                                        props.openModal("DELETE_MODAL", content.id);
                                      }}
                                      data-tooltip-id="delete-tooltip"
                                      data-tooltip-content={props.delete_tooltip_content || "Delete"}
                                    />
                                    <Tooltip id="delete-tooltip" className="tooltip" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="py-2 px-3 border border-gray-400 rounded-lg max-w-fit">
                                <IoPencilSharp
                                  className="cursor-pointer xl:text-xl"
                                  onClick={() => {
                                    if (props.editModal) {
                                      props.openModal("EDIT_MODAL", content.id);
                                    } else {
                                      props.openModal("EDIT_MODAL", content);
                                    }
                                  }}
                                  data-tooltip-id="edit-tooltip"
                                  data-tooltip-content={props.edit_tooltip_content || "Edit"}
                                />
                              </div>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </table>
        )}
      </TableContainer>
    </>
  );
};

export default connect(null, { openModal })(Table);
