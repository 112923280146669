import styled from "styled-components";

export const SideBarContainer = styled.div`
	height: 100vh;
	/* grid-column: ; */
  width: 15rem;
	display: ${props => props.display || "flex"};
	background-color: #FFFFFF;
  bottom: 0;
	position: fixed;
	overflow: hidden;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	overflow-y: scroll;


/* .sidebarWrapper {
  padding: 22px;
} */

${
  "" /* .navbarLink{
  @media (max-width){
    display: flex;
    justify-content: center;

  }
} */
}
.active .navbarLink{
  background-color: '#FFF1F4'
}

.sidebarMenu {
  margin-bottom: 10px;
}


/* .sidebarLogo {
  margin-top: -3px;
  margin-left: 15px;
} */

.sidebarList {
  list-style: none;
}

.sidebarListItem {
  display: flex;
  align-items: center;
  width: 100%;
}

/* .sidebarListItem.active, 
.sidebarListItem:hover {
    background-color: #992333;
    color: white;
} */

${
  "" /* .sidebarIcon {
  margin-bottom: 0.4em;
  /* margin-left: 15px;
    width: 0px; */
} */}
.active .sidebarIcon {
  color: #AE1931;
}

.active .sidebarText {
  color: #AE1931;
}


${
  "" /* .logo {
  padding: 15px;
  width: 60px;
  cursor: pointer;
} */
}

.lovers {
  height: 100rem;
}

@media (max-width: 768px) {
    width:3rem;
`;
