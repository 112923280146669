import React, { useEffect, useState } from "react";
import { PasswordField, SelectField, TextField, Toggler } from "../../components/widget/form/FormComponent";
import { useForm } from "react-hook-form";
import { AppButton } from "../widget/button/Button";
import { IoCloseOutline, IoPersonCircleOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { adminURL } from "../../utils/endpoint";
import { GET_USERS, SIGN_UP } from "../../store/actions/action_types/action.types";
import { openModal } from "../../store/actions/creators/ui.actions";

const roles = [
  { id: 1, name: "FrontDesk" },
  { id: 2, name: "Editor" },
  { id: 3, name: "Administrator" },
];
const AddUserModal = ({ loading, postAction, onClose, openModal, getAction, ...props }) => {
  const { formState, register, control, setValue, handleSubmit } = useForm({
    mode: "onChange",
  });
  // const [enabled, setEnabled] = useState(false);
  const [defaultPass, setDefaultPass] = useState("");
  useEffect(() => {
    if (props.data) {
      getAction(GET_USERS, adminURL);
      openModal("CLOSE_MODAL");
    }
  }, [props.data]);

  const submitForm = data => {
    data.roles = [data.roles.id];
    // console.log(JSON.stringify(data));
    // alert(JSON.stringify(data));
    // console.log(data);
    postAction(SIGN_UP, adminURL, data);
    setValue("");
  };
  return (
    <form onSubmit={handleSubmit(submitForm)} className="bg-white w-full rounded-lg md:w-full">
      <div className="relative flex items-center gap-2 flex-col my-4 px-6 pt-12">
        <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
        <IoPersonCircleOutline className="text-[#D4D4D4]" size={50} />
        <h2 className="font-semibold text-xl md:text-3xl mt-2 mb-1">Add User</h2>
        <p className="text-center">Create user with the role required to get things done.</p>
      </div>
      <div className="border-t border-b pb-10 max-h-[50vh] overflow-y-scroll px-6 border-grey py-3 border-opacity-0">
        <div className="w-full px-3">
          <div className="mb-3">
            <label htmlFor="" className="text-black font-light text-opacity-80">
              Block/Unblock User
            </label>
            <div className="flex mt-1">
              <Toggler name={"activationStatus"} label={["User is Blocked", "User is NOT Blocked"]} control={control} />
            </div>
          </div>
          <SelectField item={roles} label="Role*" control={control} mb="2xl:mb-6 xl:mb-3 lg:mb-2 mb-6" selected_item="name" name="roles" error={formState.errors.roles} {...register("roles", { required: false })} />
          <TextField label="First Name*" placeholder="Name" error={formState.errors["staff_first_name"]} {...register("staff_first_name", { required: false })} type="text" />
          <TextField label="Last Name*" placeholder="Name" error={formState.errors["staff_last_name"]} {...register("staff_last_name", { required: false })} type="text" />
          <TextField label="Email*" placeholder="Email" error={formState.errors["staff_email"]} {...register("staff_email", { required: false })} type="email" />
          <PasswordField label="Password" placeholder="*******" defaultValue={defaultPass} error={formState.errors.password} {...register("password", { required: false })} />
          <div className="mt-3 mb-6">
            {" "}
            <span onClick={() => setValue("password", "123456")} className="font-light cursor-pointer text-blue-300">
              Use default password
            </span>
          </div>
          <TextField label="Phone" placeholder="Phone" error={formState.errors["phone"]} {...register("phone", { required: false })} type="text" />
          <TextField label="Address" error={formState.errors["address"]} {...register("address", { required: false })} type="textarea" />
        </div>
      </div>
      <div className="flex justify-end p-4">
        <div className="flex gap-2">
          <AppButton loading={loading} name="Save" type="submit" />
          <AppButton onPress={() => openModal("CLOSE_MODAL")} name="Cancel" className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  loading: state.admin.createUser?.loading,
  data: state.admin.createUser.data,
  error_msg: state.admin.createUser?.error_msg,
  error: state.admin.createUser?.error,
});

export default connect(mapStateToProps, { postAction, openModal, getAction })(AddUserModal);
