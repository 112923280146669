import { useForm } from "react-hook-form";
import { IoStarHalfOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../button/Button";
import { FileImageModalOpener, TextField, Toggler } from "../../form/FormComponent";
import { postAction } from "../../../../store/actions/creators/request.actions";
import { ADD_TESTIMONIAL } from "../../../../store/actions/action_types/action.types";
import { testimonialURL } from "../../../../utils/endpoint";
import { useEffect } from "react";

const mapStateToProps = state => ({
  token: state.auth.login.token,
  file_name: state.ui.input_file,
  file_input_name: state.ui.input_file?.name,
  resp: state.site.home.set_testimonials.data,
  sending: state.site.home.set_testimonials.sending,
});

export const TestimonyContent = connect(mapStateToProps, { postAction })(props => {
  const { register, control, formState, handleSubmit, getValues } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (props.resp != null && getValues("name") != "") {
      alert("successful");
    }
  }, [props.resp]);

  const onSubmit = data => {
    data.show_testimonial = data.show_testimonial == "" ? false : data.show_testimonial;
    data.image_url = props.file_name["img_url"]?.image_url;

    // alert(JSON.stringify(data));
    props.postAction(ADD_TESTIMONIAL, testimonialURL, data, props.token);
  };
  return (
    <div className="relative flex items-center justify-center transition-all p-4 duration-300">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex flex-col items-center justify-center bg-white shadow-xl rounded-lg w-[520px] max-w-[95vw] h-auto">
          <a></a>
          <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
            <div className="flex flex-col justify-center items-center text-center px-2">
              <div className="text-6xl text-gray-300 mt-3 mb-2">
                <IoStarHalfOutline />
              </div>
              <div className="font-semibold text-xl md:text-3xl mb-1">Add Testimonials</div>
            </div>
          </header>
          <main className="py-3 max-h-[50vh] mt-[1.5rem] h-[100%] w-[100%] overflow-y-auto overflow-x-hidden px-[1.5rem] transition-all">
            <div className="w-full px-3">
              <div className="mb-6">
                <label className="text-black text-opacity-80">Show Testimonials?</label>
                <Toggler label={["This testimonial will show on the Testimonials page"]} control={control} {...register("show_testimonial", { required: false })} />
              </div>

              <FileImageModalOpener label="Image" control={control} name="img_url" {...register("img_url", { required: false })} />
              <TextField label="Name" type="text" error={formState.errors.name} {...register("name", { required: false })} />
              <TextField label="Portfolio" type="text" error={formState.errors.porfolio} {...register("porfolio", { required: false })} />
              <TextField label="Testimonial" type="textarea" error={formState.errors.testimonial} {...register("testimonial", { required: false })} />
            </div>
          </main>
          <footer className="w-full">
            <div className="bg-gray-50 w-full px-4 py-4 sm:px-9 sm:flex sm:flex-row-reverse">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Cancel
              </button>
              <AppButton name="Save" type="submit" loading={props.sending} />
            </div>
          </footer>
        </div>
      </form>
    </div>
  );
});
