import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";
import { ADD_APPOINTMENT } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { appointmentURL } from "../../utils/endpoint";

const AddAppointment = ({ token, postAction, ...props }) => {
  const { handleSubmit, formState, register, control } = useForm({
    mode: "onChange",
  });
  let navigate = useNavigate();
  const [value, onChange] = useState(new Date());

  const onSubmit = async data => {
    data.date_of_appointment = value.toISOString();
    console.log(data);
    postAction(ADD_APPOINTMENT, appointmentURL, {
      visitor_name: data.visitor_name,
      company: data.company,
      visitee: data.visitee,
      purpose_of_visit: data.purpose_of_visit,
      address: data.address,
      phone: data.phone,
      date_of_appointment: data.date_of_appointment,
      time_of_appointment: data.date_of_appointment,
    });
  };

  // console.log(props.loading);
  return (
    <div className="mt-10">
      <div onClick={() => navigate("/appointments")} className="flex gap-1 cursor-pointer items-center">
        <IoChevronBackOutline className="text-secondary" />
        <span className="text-secondary inline-block">Back</span>
      </div>
      <h1 className="subTitle">New Appointment</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        <TextField label="Visitor Name" name="visitor_name" error={formState.errors.visitor_name} {...register("visitor_name", { required: false })} type="text" />
        <TextField label="Company" name="company" error={formState.errors.company} {...register("company", { required: false })} type="text" />
        <TextField label="Visitee" name="visitee" error={formState.errors.visitee} {...register("visitee", { required: false })} type="text" />
        <TextField label="Purpose of Visit" name="purpose_of_visit" error={formState.errors["purpose_of_visit"]} {...register("purpose_of_visit", { required: false })} type="textarea" />
        <TextField label="Address" name="address" error={formState.errors["address"]} {...register("address", { required: false })} type="textarea" />
        <TextField label="Phone" name="phone" control={control} error={formState.errors.phone} {...register("phone", { required: false })} type="text" />

        <div className="flex flex-col gap-1">
          <p className="text-black text-opacity-80">Date of Appointment</p>
          <DateTimePicker onChange={onChange} value={value} />
        </div>
        {/* <TextField
          label="Date of Appointment"
          name="date_of_appointment"
          error={formState.errors.date_of_appointment}
          {...register("date_of_appointment", { required: false })}
          type="date"
        />
        <TextField
          label="Time of Appointment"
          name="time_of_appointment"
          error={formState.errors.time_of_appointment}
          {...register("time_of_appointment", { required: false })}
          type="time"
        /> */}
        <div className="flex justify-end pt-10">
          <AppButton loading={props.loading} name="Sumbit" type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  loading: state.appointments.addAppointment.loading,
  appointment_data: state.appointments.addAppointment.data,
  error_msg: state.appointments.addAppointment.error_msg,
});

export default connect(mapStateToProps, { postAction, getAction })(AddAppointment);
