import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { GET_USER } from "../../store/actions/action_types/action.types";
import { getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { adminURL } from "../../utils/endpoint";

const ViewUsers = ({ id, getAction, data, openModal, ...props }) => {
  useEffect(() => {
    const URL = `${adminURL}/${id}`;
    getAction(GET_USER, URL, props.token);
  }, [getAction, id, props.token]);

  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <h1 className="mt-1 font-bold text-xl md:text-3xl mb-1">{data?.staff_first_name}</h1>
        </header>
        <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
          <div className="w-full px-6">
            <h5 className="text-[#AE1931]">Role</h5>
            <p>{data?.role}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Email Address</h5>
            <p>{data?.staff_email}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Phone</h5>
            <p>{data?.staff_phone}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Address</h5>
            <p>{data?.staff_address}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Last Login</h5>
            <p>{DateTime.fromISO(data?.last_login).toLocaleString(DateTime.DATE_FULL)}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Created</h5>
            <p>{DateTime.fromISO(data?.createdAt).toLocaleString(DateTime.DATE_FULL)}</p>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  data: state.admin.getUserDetails.data?.data,
  loading: state.admin.getUserDetails.loading,
  error_msg: state.admin.getUserDetails.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(ViewUsers);
