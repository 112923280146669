import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  .Form {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
  }
  .FormTitle {
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
  }
  @media (width >= 640px) {
    border-radius: 24px;
    border-width: 0px;
    --tw-border-opacity: 1;
    border-color: rgba(194, 207, 214, var(--tw-border-opacity));
    --tw-border-opacity: 0.3;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding: 3rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    width: 420px;
  }
`;
