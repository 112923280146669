import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoCheckboxOutline, IoCheckmarkCircleOutline, IoCubeOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import { AppButton } from "../../components/widget/button/Button";
import Expandable from "../../components/widget/Expandable/Expandable";
import { FileImageModalOpener, TextEditor, TextField, TextFieldTag, Toggler } from "../../components/widget/form/FormComponent";
import AppModal from "../../components/widget/Modal/Modal";
import { TestimonyContent } from "../../components/widget/Modal/ModalContents/TestimonialContent";
import { ADD_PRODUCT, GET_HERO, GET_HOME_BLOG, GET_HOME_GET_STARTED, GET_HOME_PRODUCT, GET_SITE_META, GET_STATISTICS, GET_TESTIMONIAL, GET_USP, UPDATE_TESTIMONIAL } from "../../store/actions/action_types/action.types";
import { getAction, editAction } from "../../store/actions/creators/request.actions";
import { blogURL, heroURL, homeGetStartedURL, homeProdURL, productURL, siteMetaURL, statisticsURL, testimonialURL, testimonyURL, uspURL } from "../../utils/endpoint";
import { HeroContent, MetadataContent, ProductContent, StatisticContent, BarContent, HomeGetStartedContent, UniqueSaleContent, BlogContent } from "./Sites";
import { postAction } from "../../store/actions/creators/request.actions";

const mapStateToPropsTesti2 = state => ({
  testimonial_data: state.site.home.get_testimonials.data,
  testimonial_loading: state.site.home.get_testimonials.loading,
  token: state.auth.login.token,
});
const RightModalContent = connect(mapStateToPropsTesti2, { editAction, getAction })(props => {
  const [num, setNum] = useState(0);
  const [show, setShow] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data != null) {
      data.show_testimonial = !data.show_testimonial;
      // delete data.id;
      delete data.createdAt;
      delete data.updatedAt;
      // alert(JSON.stringify(data));
      props.editAction(UPDATE_TESTIMONIAL, `${testimonialURL}/${data.id}`, data, props.token);
    }
    const act_val = props.testimonial_data.filter(content => content.show_testimonial == true);
    // alert(JSON.stringify(act_val));
    setNum(act_val.length);
  }, [show, data]);

  const handleReq = info => {
    setData(info);
  };

  return (
    <section className="relative w-full h-full md:w-96 transition-all duration-300 p-4">
      <div className="bg-white shadow-xl rounded-lg h-full">
        <header className="relative rounded-tl-lg rounded-tr-lg px-4 pt-4 pb-1">
          <div className="flex items-center justify-between">
            <div className="font-normal text-lg">Select Testimonials</div>
          </div>
        </header>
        <hr className="border-opacity-30 mt-2 border-[rgba(194, 207, 214, var(--tw-border-opacity))]" />
        {props.testimonial_loading ? (
          <div className="flex justify-center p-5 items-center">loading...</div>
        ) : (
          <main className="overflow-y-auto pb-8 overscroll-contain">
            <div className="w-full border-b border-gray-300 border-opacity-50 py-2 px-3">
              <span>
                <div>
                  <label className="text-black text-opacity-80"></label>
                  <input type="text" placeholder="Search Testimonials" className="text-[0.875rem] outline-none leading-[1.25rem] mt-[0.25rem] w-full rounded-[0.5rem] bg-[#c2cfd633] bg-opacity-[0.2] px-[1rem] font-medium h-[50px]" />
                  <div className="mt-1 text-xs text-red-400 flex items-center gap-1 opacity-0">{/* <IoAlertCircleOutline /> Bad Input Request */}</div>
                </div>
              </span>
              <div className="flex justify-between items-center mt-2">
                <div className="">
                  {num} <span>of {props.testimonial_data?.length}</span> selected.
                </div>
                <button className="text-blue-500 text-opacity-80 font-normal text-base" onClick={() => alert(JSON.stringify(show))}>
                  <span className="font-medium">Done</span>
                </button>
              </div>
            </div>
            <div>
              {props.testimonial_data &&
                props.testimonial_data.map(content => (
                  <div
                    className="py-3 px-4 flex items-center justify-start hover:bg-gray-100 font-normal text-black"
                    onClick={() => {
                      setShow({ ...show, [content.id]: !show[content.id] });
                      handleReq(content);
                    }}
                  >
                    {content.show_testimonial == true && <IoCheckmarkCircleOutline className="mr-2 text-xl text-pink-400" />}
                    <span>{content.name}</span>
                  </div>
                ))}
            </div>
          </main>
        )}
      </div>
    </section>
  );
});

const TestimonialContent = ({ name, portfolio }) => (
  <div className="relative flex items-start justify-start rounded-[0.5rem] bg-opacity-[1] bg-gray-100">
    <div className="py-4 pr-4 pl-4">
      <div className="">
        <div className="font-medium">{name}</div>
        <div className="">{portfolio}</div>
      </div>
    </div>
  </div>
);

export const HomeTestimony = props => {
  const [open, setOpen] = useState(false);

  const { testimonial_data } = props.data;

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="px-[1.5rem] pt-[0.5rem] pb-[1rem] transition-all">
        <button className="flex items-center justify-center transition-all text-blue-500 text-opacity-80 font-normal text-base" onClick={handleOpenModal}>
          <span className="font-medium">Select Testimonials</span>
        </button>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-3 mb-6">{testimonial_data && testimonial_data.map(content => content.show_testimonial == true && <TestimonialContent {...content} />)}</div>
      </div>
      <AppModal open={open} end onClose={() => setOpen(false)} content={<RightModalContent />} />
    </>
  );
};

const mapStateToPropsProduct = state => ({
  token: state.auth.login.token,
  file_name: state.ui.input_file,
});

export const ProductModalContent = connect(mapStateToPropsProduct, { postAction })(props => {
  const { register, formState, handleSubmit, control, getValues } = useForm({
    mode: "onChange",
  });

  const { file_name } = props;

  const onSubmit = data => {
    // e.preventDefault();
    data.product_icon_url = file_name["product_icon_url"]?.image_url;
    data.product_image_url = file_name["product_image_url"]?.image_url;
    data.percente_Image_position_vertical = 20;
    data.percente_Image_position_horizontal = 20;
    props.postAction(ADD_PRODUCT, productURL, data, props.token);
  };

  return (
    <div className="relative flex items-center justify-center transition-all p-4 duration-300">
      <div className="relative flex flex-col items-center justify-center bg-white shadow-xl rounded-lg w-[520px] max-w-[95vw] h-auto">
        <a></a>
        <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
          <div className="flex flex-col justify-center items-center text-center px-2">
            <div className="text-6xl text-black text-opacity-20 mt-3 mb-2">
              <IoCubeOutline className="stroke-[16px]" />
            </div>
            <div className="font-bold text-xl md:text-3xl mb-1">Add Product</div>
            <div className="text-black text-center text-opacity-50 text-sm capitalize">Create a Product</div>
          </div>
        </header>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <main className="border-t border-b py-3 border-opacity-0 max-h-[50vh] mt-[1.5rem] h-full w-full overflow-y-auto overflow-x-hidden px-[1.5rem] transition-all duration-500">
            <div className="my-3 mb-5">
              <span>Publish Product?</span>
              <Toggler
                // name="publish_product"
                label={["This product will show on the Product page.", "This product will NOT show on the Product page."]}
                control={control}
                {...register("publish_product", { required: false })}
              />
            </div>
            <FileImageModalOpener label="Product Icon" control={control} name="product_icon_url" {...register("product_icon_url", { required: false })} />
            <FileImageModalOpener label="Product Image" control={control} name="product_image_url" {...register("product_image_url", { required: false })} />
            <TextField label="Product Name" type="text" name="product_name" error={formState.errors.product_name} {...register("product_name", { required: false })} />
            <TextField label="Product Snippet" type="textarea" name="product_snippet" error={formState.errors.product_snippet} {...register("product_snippet", { required: false })} />
            <TextEditor label="Product Description" name="product_description" control={control} {...register("product_description", { required: false })} />
            <TextField label="Link to form" type="text" placeholder="E.g. www.gdl.com.ng/" name="link_to_form" error={formState.errors.product_link} {...register("link_to_form", { required: false })} />
            <TextField label="Call To Action" type="text" error={formState.errors.product_link} {...register("call_to_action", { required: false })} />
            <section className=" mb-3">
              <h3 className="text-lg font-semibold mb-3">Payment link</h3>
              <div className="mb-6">
                <label className="text-black text-opacity-80">Show Link?</label>
                <div className="mt-1">
                  <Toggler name="show_link" label={["Payment link will show on this product's view page.", "Payment link will NOT show on this product's view page"]} control={control} {...register("show_payment_link", { required: false })} />
                </div>
              </div>
            </section>
            <TextField name="payment_link_call_to_action" label="Call To Action Link" type="text" error={formState.errors.payment_call_action} {...register("payment_link_call_to_action", { required: false })} />
            <TextField name="payment_link_url" label="Payment link" type="text" error={formState.errors.payment_link} {...register("payment_link_url", { required: false })} />
          </main>
          <footer className="">
            <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Cancel
              </button>
              <AppButton name="Save" type="submit" />
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
});

const SiteHome = props => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={"/pages/home/metadata"} />,
    },
    { name: "Hero", Component: props => <HeroContent {...props} /> },
    {
      name: "Statistics",
      Component: props => <StatisticContent {...props} />,
    },
    {
      name: "Products",
      hasCount: props.home_prod_data?.length,
      hasPlus: true,
      Component: props => <ProductContent {...props} />,
      modalcontent: <ProductModalContent />,
    },
    {
      name: "Get Started",
      Component: props => <HomeGetStartedContent {...props} />,
    },
    {
      name: "Unique Selling Propositions",
      Component: props => <UniqueSaleContent {...props} />,
    },
    {
      name: "Testimonials",
      Component: props => <HomeTestimony {...props} />,
      hasCount: props.testimonial_length,
      hasPlus: true,
      modalcontent: <TestimonyContent />,
    },
    // { name: "Media", Component: ({ data }) => <div>wassa</div>, hasToggle: true },
    { name: "Blog", Component: props => <BlogContent {...props} /> },
  ];

  // useEffect(() => {
  //   if (props.update_testimonial_data != null) {
  //     props.getAction(GET_TESTIMONIAL, testimonialURL, props.token);
  //   }
  // }, [props.update_testimonial_data]);

  useEffect(() => {
    props.getAction(GET_SITE_META, "/pages/home/metadata", props.token);
    props.getAction(GET_HERO, heroURL, props.token);
    props.getAction(GET_STATISTICS, statisticsURL, props.token);
    props.getAction(GET_HOME_GET_STARTED, homeGetStartedURL, props.token);
    props.getAction(GET_USP, uspURL, props.token);
    props.getAction(GET_TESTIMONIAL, testimonialURL, props.token);
    props.getAction(GET_HOME_BLOG, blogURL, props.token);
    props.getAction(GET_HOME_PRODUCT, homeProdURL, props.token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Home" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  hero_data: state.site.home.get_hero.data,
  stats_data: state.site.home.get_statistics.data,
  get_started_data: state.site.home.get_home_started.data,
  usp_data: state.site.home.get_usp.data,
  testimonial_data: state.site.home.get_testimonials.data,
  blog_data: state.site.home.get_blog.data,
  file_name: state.ui.input_file,
  file_input_name: state.ui.input_file?.name,
  home_prod_data: state.site.home.get_home_product.data,
  testimonial_length: state.site.home.get_testimonials.total,
  update_testimonial_data: state.site.home.update_testimonials.success,
});

export default connect(mapStateToProps, { getAction })(SiteHome);
