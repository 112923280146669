import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
  data: null,
};

const uploadImageReducer = (state = {}, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.UPLOAD_CLOUDINARY}`:
      return {
        ...state,
        loading: true,
      };
    case type.UPLOAD_CLOUDINARY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        loading: false,
        data: null,
      };
    case type.ADD_MEDIA:
      return {
        ...state,
        loading: false,
        data: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const getProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PROFILE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_PROFILE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const getSocialAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SOCIAL_ACCOUNT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addSocialAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_SOCIAL_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_SOCIAL_ACCOUNT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const getNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_NOTIFICATION:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NEWSLETTER_TEMPLATE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addNewsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_NEWSLETTER_TEMPLATE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_NEWSLETTER_TEMPLATE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const getSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SUBSCRIPTION_TEMPLATE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addSubscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_SUBSCRIPTION_TEMPLATE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_SUBSCRIPTION_TEMPLATE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const getDefaultPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_DEFAULT_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addDefaultPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_DEFAULT_USER_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_DEFAULT_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};
const getMaintenanceModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_MAINTENANCE_MODE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addMaintenanceModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_MAINTENANCE_MODE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_MAINTENANCE_MODE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const settingsReducer = combineReducers({
  profileLinks: getProfileReducer,
  addProfileLinks: addProfileReducer,
  socialsLinks: getSocialAccountReducer,
  addSocialsLinks: addSocialAccountReducer,
  notificationLinks: getNotificationReducer,
  addNotificationLinks: addNotificationReducer,
  newsletterTemplate: getNewsLetterReducer,
  addNewsletterTemplate: addNewsLetterReducer,
  subscriberTemplate: getSubscriberReducer,
  addSubscriberTemplate: addSubscriberReducer,
  defaultPassword: getDefaultPasswordReducer,
  addDefaultPassword: addDefaultPasswordReducer,
  maintenanceMode: getMaintenanceModeReducer,
  addMaintenanceMode: addMaintenanceModeReducer,
  img_upload: uploadImageReducer,
});
