import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../api/request.api";
import { jobApplicationURL } from "../utils/endpoint";

export const jobApplicationApi = createApi({
  reducerPath: "jobApplicationApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseURL}` }),
  tagTypes: ["JobApplications", "JobApplication"],
  endpoints: builder => ({
    getJobApplications: builder.query({
      query: () => `${jobApplicationURL}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["JobApplications"],
    }),
    getJobApplication: builder.query({
      query: id => `${jobApplicationURL}/${id}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["JobApplication"],
    }),
    updateJobApplication: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${jobApplicationURL}/${id}`,
        method: "PATCH",
        body: patch,
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["JobApplications"],
    }),
    deleteJobApplication: builder.mutation({
      query: id => ({
        url: `${jobApplicationURL}/${id}`,
        method: "DELETE",
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["JobApplications"],
    }),
  }),
});

export const { useGetJobApplicationsQuery, useGetJobApplicationQuery, useUpdateJobApplicationMutation, useDeleteJobApplicationMutation } = jobApplicationApi;

export const { getJobApplication, getJobApplications, updateJobApplication, deleteJobApplication } = jobApplicationApi.endpoints;
