import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { ProductGeneralContent as ManagementContent } from "./SiteProduct";
import { managementGenURL, managementMetaURL } from "../../utils/endpoint";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.actions";
import { GET_SITE_GENERAL, GET_SITE_META } from "../../store/actions/action_types/action.types";

const ExpandableContent = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={managementMetaURL} />,
  },
  { name: "General", Component: props => <ManagementContent {...props} url={managementGenURL} /> },
];

const SiteManagement = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getAction(GET_SITE_META, managementMetaURL, token);
    props.getAction(GET_SITE_GENERAL, managementGenURL, token);
  }, []);

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Management" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable
          {...content}
          component={<Component data={props} checkKeyDown={checkKeyDown} />}
          showToggle={content.hasToggle}
          hasCount={content.hasCount}
          modalcontent={content.modalcontent}
          hasPlus={content.hasPlus}
          loading={props.meta_loading}
        />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  general_data: state.site.main.get_site_general.data,
});
export default connect(mapStateToProps, { getAction })(SiteManagement);
