import { connect } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import TestTab from "../../components/DetailsTabComponent/DetailsTab";
import { LinkButton } from "../../components/widget/button/Button";
import { openModal } from "../../store/actions/creators/ui.actions";
import CategoryModal from "../../pages/Posts/category.content";
import { PostPage, AddPostPage, PostCategory } from "../../pages";
import EditPost from "../../pages/Posts/EditPost";

const tabNames = {
  Posts: "",
  Category: "",
};

const Extras = ({ openModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-start">
      <LinkButton name="Add Post +" onPress={() => navigate(`/posts/new`)} />
      <LinkButton name="Add Category +" onPress={() => openModal("OPEN_MODAL")} />
    </div>
  );
};

const PostRoute = ({ match, ...props }) => {
  return (
    <>
      <TestTab tabs={tabNames} name="Posts" content={<Extras {...props} />} modal_content={<CategoryModal />} match={match} />
      <Routes>
        <Route path="/" element={<PostPage />} />
        <Route path="/new" element={<AddPostPage />} />
        <Route path="/:id/edit" element={<EditPost />} />
        <Route path="/category" element={<PostCategory />} />
      </Routes>
    </>
  );
};

export default connect(null, { openModal })(PostRoute);
