import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
  data: null,
};

const getPostListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_POSTS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_POSTS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_POST:
      return {
        ...state,
        loading: true,
      };
    case type.GET_POST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_POST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_POST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const editPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_POST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deletePostReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_POST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_CATEGORYS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CATEGORYS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const editCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const postReducer = combineReducers({
  postList: getPostListReducer,
  postDetails: getPostReducer,
  addPost: addPostReducer,
  deletePost: deletePostReducer,
  editPost: editPostReducer,
  categoryList: getCategoryListReducer,
  categoryDetails: getCategoryReducer,
  addCategory: addCategoryReducer,
  deleteCategory: deleteCategoryReducer,
  editCategory: editCategoryReducer,
});
