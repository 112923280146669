export const adminURL = "/admin";
export const loginURL = "/admin/auth/login";
export const resetURL = "/admin/auth/password/reset";
export const resetPasswordURL = "/admin/auth/password/new/create";
export const resetEmailURL = "/admin/auth/forgot/password";
export const ipURL = "http://ipapi.co/json";
export const validateToken = "/admin/auth/validate/token";
export const validateTokenURL = "/admin/auth/validate/token";
const siteBaseURL = "/site";
const homeBaseURL = "/pages/home";
const aboutBaseURL = "/pages/about";
const testimonialBaseURL = "/pages/testimonials";
const careerBaseURL = "/pages/careers";
const faqBaseURL = "/pages/faqs";
export const contactURL = "/pages/contact";
const managementURL = "/pages/management";
export const directorURL = "/pages/directors";
const privacyURL = "/pages/privacy";
export const termsURL = "/pages/terms";
const resourceURL = "/pages/resources";
export const productURL = "/pages/products/s";
const productCaseURL = "/pages/product";

//JOBS ENDPOINT
export const jobURL = "/jobs";
export const positionURL = "/job/positions";
export const jobApplicationURL = "/job/applications";

// SITE ENDPOINTS
export const headerURL = `${siteBaseURL}/header`;
export const siteMetaURL = `${siteBaseURL}/metadata`;
export const adSectionURL = `${siteBaseURL}/ad/section`;
export const getStartedURL = `${siteBaseURL}/get/started/section`;
export const siteNewsLetterURL = `${siteBaseURL}/news/letter/section`;
export const ebookURL = `${siteBaseURL}/free/ebook/section`;
export const footerURL = `${siteBaseURL}/footer`;

// HOME ENDPOINTS
export const heroURL = `${homeBaseURL}/hero`;
export const statisticsURL = `${homeBaseURL}/statistics`;
export const homeGetStartedURL = `${homeBaseURL}/get/started`;
export const uspURL = `${homeBaseURL}/unique/selling/proposition`;
export const testimonialURL = `${testimonialBaseURL}/s`;
export const blogURL = `${homeBaseURL}/blog`;
export const homeProdURL = `${homeBaseURL}/products`;

// ABOUT ENDPOINT
export const aboutMetaURL = `${aboutBaseURL}/metadata`;
export const visionURL = `${aboutBaseURL}/our/vision`;
export const missionURL = `${aboutBaseURL}/our/mission`;
export const aboutGenURL = `${aboutBaseURL}/general`;
export const managementAbtURL = `${aboutBaseURL}/management/team`;
export const boardAbtURL = `${aboutBaseURL}/board/of/directors`;

// PRODUCT ENDPOINT
export const productMetaURL = `/pages/products/metadata`;
export const productGenURL = `/pages/products/general`;
export const useCaseURL = `/pages/products/use/cases`;
export const productUseCaseURL = `/pages/products/product/usecases`;

// RESOURCE ENPOINT
export const resourceBlogMetaURL = `${resourceURL}/bp/metadata`;
export const resourceBlogGenURL = `${resourceURL}/bp/general`;
export const resourceMediaMetaURL = `${resourceURL}/mp/metadata`;
export const resourceMediaGenURL = `${resourceURL}/mp/general`;
export const resourceResearchPaperMetaURL = `${resourceURL}/rpp/metadata`;
export const researchPaperGenURL = `${resourceURL}/rp/general`;
export const resourceAllMediaURL = `${resourceURL}/mp/all/media`;
export const researchPaperSecURL = `${resourceURL}/rp/research/papers/section`;
export const researchPaperURL = `${resourceURL}/rp/research/papers`;
// export const

//TESTIMONIAL ENDPOINT
export const testiMetaURL = `${testimonialBaseURL}/metadata`;
export const testiGeneralURL = `${testimonialBaseURL}/general`;
export const testimonyURL = `${testimonialBaseURL}/s`;

//CAREER ENDPOINT
export const careerMetaURL = `${careerBaseURL}/metadata`;
export const careerGeneralURL = `${careerBaseURL}/general`;
export const careerIntroURL = `${careerBaseURL}/intro`;
export const careerPageContentURL = `${careerBaseURL}/page/content`;
export const jobOpeningURL = `${careerBaseURL}/job/openings`;

//FAQs ENDPOINT
export const faqsMetaURL = `${faqBaseURL}/metadata`;
export const faqsGeneralURL = `${faqBaseURL}/general`;
export const faqsCategoryURL = `${faqBaseURL}/categories`;
export const faqURL = `${faqBaseURL}/s`;

//CONTACT ENDPOINT
export const contactMetaURL = `${contactURL}/metadata`;
export const contactGeneralURL = `${contactURL}/general`;
export const contactFormURL = `${contactURL}/form`;

//MANAGEMENT ENDPOINT
export const managementMetaURL = `${managementURL}/metadata`;
export const managementGenURL = `${managementURL}/general`;

// DIRECTOR ENDPOINT
export const directorMetaURL = `${directorURL}/metadata`;
export const directorGenURL = `${directorURL}/general`;

export const privacyMetaURL = `${privacyURL}/metadata`;
export const privacyPolicyURL = `${privacyURL}/policy`;

// TERMS ENDPOINT
// export const termsMetaURL = `${}`

//Settings Endpoint
const settingsBase = "/settings";
export const profileURL = `${settingsBase}/profile`;
export const smSettingsURL = `${settingsBase}/social/accounts`;
export const notificationURL = `${settingsBase}/notifications`;
export const newsLetterURL = `${settingsBase}/newsletter/new/template`;
export const newsLetterSubURL = `${settingsBase}/newsletter/subscription/template`;
export const securityPasswordURL = `${settingsBase}/security/default/user/password`;
export const securityMaintenaceURL = `${settingsBase}/security/maintenance/mode`;

//Appointments Endpoint
export const appointmentURL = "/appointments";

//NewsLetter Endpoint

export const newsletterURL = "/newsletters";
export const subscriberURL = "/newsletters/subscribers";

//Media Endpoint
export const mediaURL = "/media";
// export const

//Post Endpoint
export const postURL = "/posts/s";
export const categoryURL = "/post/categories";
