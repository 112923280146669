import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import Table from "../../components/widget/table/Table";
import { processData } from "./post.data";
import { getAction } from "../../store/actions/creators/request.actions";
import { GET_CATEGORYS } from "../../store/actions/action_types/action.types";
import { categoryURL } from "../../utils/endpoint";
import AppModal from "../../components/widget/Modal/Modal";
import { openModal } from "../../store/actions/creators/ui.actions";
import EditCategory from "./EditCategory";

const CategoryDrop = ({ tableData }) => {
  const data = tableData.map(processData);
  return <Table tableData={data} no_option edit_only />;
};

const Category = ({ category_data, getAction, openModal, editData, ...props }) => {
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    getAction(GET_CATEGORYS, categoryURL);
  }, [getAction]);

  useEffect(() => {
    if (category_data) {
      console.log(category_data);
      setCategoryData(category_data);
    }
  }, [category_data]);

  const ExpandableContent = [
    {
      key: 1,
      name: "All Categories",
      Component: () => <CategoryDrop tableData={categoryData} />,
    },
  ];

  return (
    <div className="">
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component />} noplus />
        ))}
      </main>
      <AppModal open={props.open} onClose={() => openModal("CLOSE_MODAL")} content={<EditCategory editData={editData} />} />
    </div>
  );
};

const mapStateToProps = state => ({
  category_data: state.post.categoryList.data,
  open: state.ui.modal?.open_edit,
  editData: state.ui.modal?.data,
  error_msg: state.post.categoryList.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(Category);
