import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import Table from "../../components/widget/table/Table";
import { GET_SUBSCRIBERS } from "../../store/actions/action_types/action.types";
import { getAction } from "../../store/actions/creators/request.actions";
import { subscriberURL } from "../../utils/endpoint";
import { subscriberProcessData } from "./newsletter.data";

const SubscriberTable = ({ tableData }) => {
  const data = tableData.map(subscriberProcessData);
  return <Table tableData={data} no_option />;
};

const Subscriber = ({ token, openModal, getAction, subscribers_data, ...props }) => {
  const [subscriberData, setSubscribersData] = useState([]);

  const ExpandableContent = [
    {
      name: "Subscribers",
      hasCount: subscriberData.length,
      Component: () => <SubscriberTable tableData={subscriberData} />,
    },
  ];

  useEffect(() => {
    getAction(GET_SUBSCRIBERS, subscriberURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (subscribers_data) {
      console.log(subscribers_data);
      setSubscribersData(subscribers_data);
    }
  }, [subscribers_data]);

  return (
    <div>
      <main className="rounded-lg bg-white mt-8 overflow-hidden w-full xl:w-4/5">
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} key={content.name} component={<Component />} />
        ))}
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal?.open_view,
  id: state.ui.modal?.data,
  openDelete: state.ui.modal?.open_delete,
  loading: state.newsletter.subscriberList.loading,
  subscribers_data: state.newsletter.subscriberList.data,
  error_msg: state.newsletter.subscriberList.error_msg,
});

export default connect(mapStateToProps, { getAction })(Subscriber);
