import { DateTime } from "luxon";
import { formatTime, getISODate } from "../../utils/helpers";

export const jobProcessData = (data, idx) => ({
  "#": idx + 1,
  id: data.id,
  Position: data.position,
  Closes: DateTime.fromISO(data.lasts_till).toLocaleString(DateTime.DATE_FULL),
  Published: DateTime.fromISO(data.createdAt).toLocaleString(DateTime.DATE_FULL),
  publish: data.published,
});

export const positionData = (data, idx) => ({
  "#": idx + 1,
  id: data.id,
  Title: data.title_of_position,
  Description: data.job_description,
});

export const applicationData = (data, idx) => ({
  "#": idx + 1,
  id: data.id,
  Applicant: data.candidate_name,
  "Job ID": data.id,
  // time: data.application_date,
  time: formatTime(new Date(data.application_date)),
});

export const applicationOldData = (data, idx) => ({
  "#": idx + 1,
  id: data.id,
  Applicant: data.candidate_name,
  "Job ID": data.id,
  date: DateTime.fromISO(data.application_date).toLocaleString(DateTime.DATE_FULL),
  // Time: formatTime(new Date(data.application_date)),
});
