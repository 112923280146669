import React from 'react';
import {
	IoBarChartOutline,
	IoBookOutline,
	IoChatboxEllipsesOutline,
	IoChatbubblesOutline,
	IoHomeOutline,
	IoOpenOutline,
	IoPulseOutline,
} from 'react-icons/io5';

const SupportLinks = [
	{
		title: 'Overview',
		icons: <IoHomeOutline size={20} />,
	},
	{
		title: 'Monitoring',
		icons: <IoPulseOutline size={20} />,
	},
	{
		title: 'Active Chats',
		icons: <IoChatbubblesOutline size={20} />,
	},
	{
		title: 'Messaging',
		icons: <IoChatboxEllipsesOutline size={20} />,
	},
	{
		title: 'Knowledge Base',
		icons: <IoBookOutline size={20} />,
	},
	{
		title: 'Reporting',
		icons: <IoBarChartOutline size={20} />,
	},
];

const Support = () => {
	return (
		<div>
			<h2 className='pageTitle'>Support</h2>
			<div className=' mt-10 gap-6 w-full'>
				<div className='bg-white md:w-1/2 rounded-lg'>
					<div className='p-6 mb-3'>
						<img src='/Images/tawkto.png' alt='' className='h-12' />
					</div>
					{SupportLinks.map((item) => (
						<div
							className='flex cursor-pointer justify-between py-3 px-4  items-center border-t'
							key={item.title}
						>
							<div className='flex items-center gap-3'>
								{item.icons}
								<span>{item.title}</span>
							</div>
							<IoOpenOutline size={15} className='text-[#3B82f6]' />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Support;
