import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { FileImageModalOpener, SelectField, TextEditor, TextField } from "../../components/widget/form/FormComponent";
import { GET_CATEGORYS } from "../../store/actions/action_types/action.types";
import { getAction } from "../../store/actions/creators/request.actions";
import { categoryURL, postURL } from "../../utils/endpoint";
import { useGetPostQuery, useUpdatePostMutation } from "../../services/post.services";
import { toast } from "sonner";

const EditPost = props => {
  const { formState, handleSubmit, register, control, setValue } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const { state } = useLocation();
  const URL = `${postURL}/${state?.id}`;
  const { data: postDetails, error } = useGetPostQuery(state?.id);
  const file = useSelector(state => state.ui.input_file);
  const input_name = useSelector(state => state.ui.input_file?.name);

  const [updatePost, { data, isSuccess, isLoading }] = useUpdatePostMutation();

  // const [postData, setPostData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    dispatch(getAction(GET_CATEGORYS, categoryURL));
  }, [URL, dispatch]);

  useEffect(() => {
    if (postDetails) {
      setValue("title", postDetails?.title);
      setValue("excerpt", postDetails?.excerpt);
      setValue("author", postDetails?.author);
      setValue("category", postDetails?.category?.id);
      setValue("body", postDetails?.body);
      setValue("tags", JSON.parse(postDetails?.tags));
      setValue("featured_image_url", postDetails?.featured_image_url);
      setValue("save_as_draft", postDetails?.save_as_draft);
      setValue("publish_later", postDetails?.publish_later);
      setValue("publish_later_date", postDetails?.publish_later_date);
    }
  }, [postDetails, setValue]);

  useEffect(() => {
    if (props.category_data) {
      setCategoryData(props.category_data);
    }
  }, [props.category_data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Post Edited Successfully");
    }
  }, [isSuccess]);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };

  const id = postDetails?.title.toLowerCase().replace(/\s+/g, "-");

  const onSubmit = data => {
    data.featured_image_url = file.featured_image_url?.image_url;
    // data.tags = tags[name];
    // data.related_post = relatedPosts?.related_post;
    if (!data.featured_image_url) {
      data.featured_image_url = postDetails?.featured_image_url;
    }

    updatePost({
      id: state?.id,
      title: data.title,
      excerpt: data.excerpt,
      author: data.author,
      category: data.category,
      featured_image_url: data.featured_image_url,
      body: props.edit_post,
    });
  };

  return (
    <div className="mt-10">
      <Link to={"/posts"}>
        <div className="flex gap-1 cursor-pointer items-center">
          <IoChevronBackOutline className="text-secondary" />
          <span className="text-secondary">Back</span>
        </div>
      </Link>
      <h1 className="subTitle">Edit Post</h1>
      <div>
        <p className="my-1 text-black text-opacity-80 font-extralight">Post URL</p>
        <Link to={id} target="_blank" className="my-1 text-secondary hover:underline">
          https://gdl.com.ng/blog/{id}
        </Link>
      </div>
      <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)} className="mt-10">
        <TextField label="Post Title*" name="title" type="text" error={formState.errors.title} {...register("title", { required: false })} />
        <TextField label="Excerpt/Caption*" name="excerpt" type="textarea" error={formState.errors.excerpt} {...register("excerpt", { required: false })} />
        <TextField label="Author" name="author" type="text" error={formState.errors.author} {...register("author", { required: false })} />
        <SelectField item={categoryData} label="Post Category*" defaultValue={postDetails?.category?.category_name} control={control} mb="2xl:mb-6 lg:mb-3 mb-6" name="category" selected_item="category_name" error={formState.errors.category} {...register("category", { required: false })} />
        <FileImageModalOpener label="Featured Image" defaultValue={postDetails?.featured_image_url} control={control} value={file[input_name]?.image_title} name="featured_image_url" {...register("featured_image_url", { required: false })} />
        <TextEditor label="Post Body*" defaultValue={postDetails?.body} name="body" control={control} {...register("body", { required: false })} />
        <div className="flex justify-end pt-10">
          <AppButton name={`Save`} type="submit" loading={isLoading} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  category_data: state.post.categoryList.data,
  edit_post: state.ui.createNewPost?.new_post || "",
});

export default connect(mapStateToProps)(EditPost);
