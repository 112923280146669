import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Expandable from "../../components/widget/Expandable/Expandable";
import AppModal from "../../components/widget/Modal/Modal";
import Table from "../../components/widget/table/Table";
import { openModal } from "../../store/actions/creators/ui.actions";
import DeleteModal from "../Jobs/DeleteModal";
import { postProcessData } from "./post.data";
import { useDeletePostMutation, useGetPostsQuery } from "../../services/post.services";
import { toast } from "sonner";

const TypePost = ({ tableData, loading }) => {
  if (loading) {
    return <p>Loading...</p>;
  }
  const data = tableData.map(postProcessData);
  return <Table tableData={data} no_option edit_post />;
};

const Posts = () => {
  const dispatch = useDispatch();
  const openDelete = useSelector(state => state.ui.modal?.open_delete);
  const postId = useSelector(state => state.ui.modal?.data);
  const { data: postData, error, isLoading } = useGetPostsQuery();
  const [deletePostAction, { isLoading: isDeletingPost, isSuccess }] = useDeletePostMutation();

  if (error) {
    console.log(error);
  }

  const ExpandableContent = [
    {
      key: 1,
      name: "Published Posts",
      Component: () => <TypePost tableData={postData} loading={isLoading} />,
      hasCount: isLoading ? 0 : postData?.length,
    },
    {
      key: 2,
      name: "Unpublished Posts",
      Component: () => <TypePost tableData={postData.filter(item => item.publish_later)} />,
      hasCount: 0,
    },
    {
      key: 3,
      name: "Scheduled Posts",
      Component: () => <TypePost tableData={postData.filter(item => item.publish_later)} />,
      hasCount: 0,
    },
    {
      key: 4,
      name: "Drafts",
      Component: () => <TypePost tableData={postData.filter(item => item.save_as_draft)} />,
      hasCount: 0,
    },
  ];

  const deletePost = () => {
    if (postId) {
      console.log({ postId });
      deletePostAction(postId);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Post Deleted Successfully");
      dispatch(openModal("CLOSE_MODAL"));
    }
  }, [isSuccess]);

  return (
    <div className="">
      <main>
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} noplus />
        ))}
      </main>
      <AppModal
        open={openDelete}
        onClose={() => {
          openModal("CLOSE_MODAL");
        }}
        content={<DeleteModal onClick={deletePost} id={postId} loading={isDeletingPost} />}
      />
    </div>
  );
};

export default Posts;
