export const LOADING = "LOADING";
export const SENDING = "SENDING";
export const SIGN_UP = "SIGN_UP";
export const LOGIN = "LOGIN";
// export const RECOVER = 'RECOVER'
export const RESET = "RESET";
export const LOGOUT = "LOGOUT";
export const GET_IP = "GET_IP";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const UPLOAD_CLOUDINARY = "UPLOAD_CLOUDINARY";
export const RESETEMAIL = "RESETEMAIL";

//HEADERS TYPES
export const GET_HEADER = "GET_HEADER";
export const ADD_HEADER = "ADD_HEADER";
export const GET_MAIN_SITE_META = "GET_MAIN_SITE_META";
export const ADD_MAIN_SITE_META = "ADD_MAIN_SITE_META";

// // METADATA TYPES
// export const GET_SITE_METADATA = "GET_SITE_METADATA";
// export const ADD_SITE_METADATA = "ADD_SITE_METADATA";

//MEDIA ACTION TYPES
export const GET_MEDIA = "GET_MEDIA";
export const ADD_MEDIA = "ADD_MEDIA";

// META ACTION TYPES FOR SITE
export const GET_SITE_META = "GET_SITE_META";
export const ADD_SITE_META = "ADD_SITE_META";

// GENERAL ACTION TYPES FOR SITE
export const GET_SITE_GENERAL = "GET_SITE_GENERAL";
export const ADD_SITE_GENERAL = "ADD_SITE_GENERAL";

// ACTION TYPES FOR AD SECTION
export const GET_AD_SECTION = "GET_AD_SECTION";
export const ADD_AD_SECTION = "ADD_AD_SECTION";

// ACTION TYPES FOR SITE GET STARTED
export const GET_SITE_GET_STARTED = "GET_SITE_GET_STARTED";
export const ADD_SITE_GET_STARTED = "ADD_SITE_GET_STARTED";

// ACTION TYPES FOR NEWS LETTER
// export const GET_NEWSLETTER = "GET_NEWSLETTER";
// export const ADD_NEWSLETTER = "ADD_NEWSLETTER";

// ACTION TYPES FOR EBOOK
export const GET_EBOOK = "GET_EBOOK";
export const ADD_EBOOK = "ADD_EBOOK";

//ACTION FOR SITE FOOTER
export const GET_SITE_FOOTER = "GET_SITE_FOOTER";
export const ADD_SITE_FOOTER = "ADD_SITE_FOOTER";
// Admin Endpoint Types

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USERS = "EDIT_USERS";
export const DELETE_USERS = "DELETE_USERS";

// JOBS ENDPOINT TYPES

export const GET_JOBS = "GET_JOBS";
export const GET_JOB = "GET_JOB";
export const ADD_JOB = "ADD_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const DELETE_JOB = "DELETE_JOB";

export const GET_JOB_APPLICATIONS = "GET_JOBS_APPLICATION";
export const GET_JOB_APPLICATION = "GET_JOB_APPLICATION";
export const ADD_JOB_APPLICATION = "ADD_JOB_APPLICATION";
export const EDIT_JOB_APPLICATION = "EDIT_JOB_APPLICATION";
export const DELETE_JOB_APPLICATION = "DELETE_JOB_APPLICATION";

export const GET_JOB_POSITIONS = "GET_JOBS_POSITION";
export const GET_JOB_POSITION = "GET_JOB_POSITION";
export const ADD_JOB_POSITION = "ADD_JOB_POSITION";
export const EDIT_JOB_POSITION = "EDIT_JOB_POSITION";
export const DELETE_JOB_POSITION = "DELETE_JOB_POSITION";

// SETTINGS ENDPOINT TYPES

export const GET_PROFILE = "GET_PROFILE";
export const ADD_PROFILE = "ADD_PROFILE";

export const GET_SOCIAL_ACCOUNT = "GET_SOCIAL_ACCOUNT";
export const ADD_SOCIAL_ACCOUNT = "ADD_SOCIAL_ACCOUNT";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export const GET_NEWSLETTER_TEMPLATE = "GET_NEWSLETTER_TEMPLATE";
export const ADD_NEWSLETTER_TEMPLATE = "ADD_NEWSLETTER_TEMPLATE";

export const GET_SUBSCRIPTION_TEMPLATE = "GET_SUBSCRIPTION_TEMPLATE";
export const ADD_SUBSCRIPTION_TEMPLATE = "ADD_SUBSCRIPTION_TEMPLATE";

export const GET_DEFAULT_USER_PASSWORD = "GET_DEFAULT_USER_PASSWORD";
export const ADD_DEFAULT_USER_PASSWORD = "ADD_DEFAULT_USER_PASSWORD";

export const GET_MAINTENANCE_MODE = "GET_MAINTENANCE_MODE";
export const ADD_MAINTENANCE_MODE = "ADD_MAINTENANCE_MODE";

// APPOINTMENT ENDPOINT TYPES

export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";

// NEWSLETTER ENDPOINT TYPES

export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const ADD_NEWSLETTER = "ADD_NEWSLETTER";
export const EDIT_NEWSLETTER = "EDIT_NEWSLETTER";
export const DELETE_NEWSLETTER = "DELETE_NEWSLETTER";

export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const GET_SUBSCRIBER = "GET_SUBSCRIBER";
export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER";
export const EDIT_SUBSCRIBER = "EDIT_SUBSCRIBER";
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";

// ACTION TYPES FOR SITE HOME
export const GET_HERO = "GET_HERO";
export const ADD_HERO = "ADD_HERO";
export const GET_STATISTICS = "GET_STATISTICS";
export const ADD_STATISTICS = "ADD_STATISTICS";
export const GET_HOME_GET_STARTED = "GET_HOME_GET_STARTED";
export const ADD_HOME_GET_STARTED = "ADD_HOME_GET_STARTED";
export const GET_USP = "GET_USP";
export const ADD_USP = "ADD_USP";
export const GET_TESTIMONIAL = "GET_TESTIMONIALS";
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL";
export const GET_HOME_BLOG = "GET_HOME_BLOG";
export const ADD_HOME_BLOG = "ADD_HOME_BLOG";
export const GET_HOME_PRODUCT = "GET_HOME_PRODUCT";
export const ADD_HOME_PRODUCT = "ADD_HOME_PRODUCT";

// ACTION TYPES FOR SITE ABOUT
export const GET_ABOUT_GEN = "GET_ABOUT_GENERAL";
export const ADD_ABOUT_GEN = "ADD_ABOUT_GENERAL";
export const GET_VISION = "GET_VISION";
export const ADD_VISION = "SET_VISION";
export const GET_MISSION = "GET_MISSION";
export const ADD_MISSION = "ADD_MISSION";
export const GET_MANAGEMNT_MEMBER = "GET_MANAGEMENT_MEMBER";
export const GET_BOARD_MEMBER = "GET_BOARD_MEMBER";
export const ADD_MEMBER = "ADD_MEMBER";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_GENERAL = "GET_PRODUCT_GENERAL";
export const GET_USE_CASE = "GET_USE_CASE";
export const ADD_USE_CASE = "ADD_USE_CASE";
export const GET_PRODUCT_USE_CASE = "GET_PRODUCT_USE_CASE";
export const ADD_PRODCUT_USE_CASE = "ADD_PRODUCT_USE_CASE";

// ACTION TYPES FOR RESOURCES
export const GET_RES_BLOG_META = "GET_RESOURCE_BLOG_META";
export const ADD_RES_BLOG_META = "ADD_RESOURCE_BLOG_META";
export const GET_RES_ALL_MEDIA = "GET_RES_ALL_MEDIA";
export const ADD_RES_ALL_MEDIA = "ADD_RES_ALL_MEDIA";
export const GET_RPS = "GET_RESEARCH_PAPERS_SECTION";
export const ADD_RPS = "ADD_RESEARCH_PAPERS_SECTION";
export const GET_RP = "GET_RESEARCH_PAPERS";
export const ADD_RP = "ADD_RESEARCH_PAPERS";

//ACTION TYPES FOR TESTIMONIALS
export const GET_TESTI_GENERAL = "TESTI_GENERAL";
export const ADD_TESTI_GENERAL = "TESTI_GENERAL";

//ACTION TYPES FOR CAREERS
export const GET_CAREER_GENERAL = "GET_CAREER_GENERAL";
export const ADD_CAREER_GENERAL = "ADD_CAREER_GENERAL";
export const GET_CAREER_INTRO = "GET_CAREER_INTRO";
export const ADD_CAREER_INTRO = "ADD_CAREER_INTRO";
export const GET_CAREER_PAGECONTENT = "GET_CAREER_PAGECONTENT";
export const ADD_CAREER_PAGECONTENT = "ADD_CAREER_PAGECONTENT";
export const GET_JOB_OPEN = "GET_JOB_OPENING";
export const ADD_JOB_OPEN = "ADD_JOB_OPENING";

//ACTION TYPES FOR FAQs
export const GET_FAQ_GENERAL = "GET_FAQ_GENERAL";
export const ADD_FAQ_GENERAL = "SET_FAQ_GENERAL";
export const GET_FAQ_CATEGORY = "GET_FAQ_CATEGORIES";
export const ADD_FAQ_CATEGORY = "ADD_FAQ_CATEGORIES";
export const GET_FAQ = "GET_FAQ";
export const ADD_FAQ = "ADD_FAQ";

// ACTION TYPES FOR CONTACT
export const GET_CONTACT = "GET_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const GET_CONTACT_FORM = "GET_CONTACT_FORM";
export const ADD_CONTACT_FORM = "ADD_CONTACT_FORM";

export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const ADD_PRIVACY_POLICY = "GET_PRIVACY_POLICY";

export const GET_TERMS = "GET_TERMS";
export const ADD_TERMS = "ADD_TERMS";
// POST ENDPOINT TYPES

export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const ADD_POST = "ADD_POST";
export const DELETE_POST = "DELETE_POST";
export const EDIT_POST = "EDIT_POST";

export const GET_CATEGORYS = "GET_CATEGORYS";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
