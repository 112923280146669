import styled from "styled-components";

export const PageLayout = styled.div`
  display: flex;
  /* @media screen only (max-width: 540px) {
    grid-column: repeat(12, 5rem);
  } */
`;

export const Main = styled.div`
  /* overflow-y: auto; */
  /* @media (max-width: 768px) {
    grid-column: 2/13;
  } */
`;

export const PageContent = styled.div`
  /* background-color: transparent; */
  scrollbar-width: thin;
  overflow-y: auto;
  .pageTitle {
    font-size: 36px;
    margin: 20px 0px 24px;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .subTitle {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    .subTitle {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .PageTitle {
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }
  }
`;
