import styled from "styled-components";

export const TabDiv = styled.div`
  .tab-default {
    background-color: rgba(194, 207, 214, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.1;
  }
  .selected {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 220, var(--tw-bg-opacity));
    font-weight: 500;
  }
`;
