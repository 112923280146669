export function pasteContents(text, type) {
  type = type || "plain"; // Set default value for 'type' if not provided
  if (type === "html") {
    document.execCommand("insertHTML", false, text);
  } else {
    document.execCommand("insertText", false, text);
  }
}

export function PasteListener(e) {
  e.preventDefault();
  e.stopPropagation();

  const whiteListedDomains = [];

  var clipboardData = e.clipboardData;

  var pasteHTML = clipboardData?.getData("text/html");

  if (pasteHTML) {
    var parser = new DOMParser();
    var sanitizedPaste = parser.parseFromString(pasteHTML, "text/html");

    var validURLRecognizer = new RegExp("^https?://(www\\.)?(" + whiteListedDomains.join("|") + ")");

    var imgTags = sanitizedPaste.getElementsByTagName("img");

    var toRemoveImages = [];
    for (var i = 0; i < imgTags.length; i++) {
      var img = imgTags[i];
      var src = img.getAttribute("src");
      if (src && !validURLRecognizer.test(src)) {
        toRemoveImages.push(img);
      }
    }
    for (var j = 0; j < toRemoveImages.length; j++) {
      toRemoveImages[j].remove();
    }

    var toRemoveLinks = [];
    var aTags = sanitizedPaste.getElementsByTagName("a");
    for (var k = 0; k < aTags.length; k++) {
      var a = aTags[k];
      var href = a.getAttribute("href");
      if (href && !validURLRecognizer.test(href)) {
        toRemoveLinks.push(a);
      }
    }
    for (var l = 0; l < toRemoveLinks.length; l++) {
      toRemoveLinks[l].removeAttribute("href");
    }

    var divTags = sanitizedPaste.getElementsByTagName("div");
    for (var m = 0; m < divTags.length; m++) {
      var div = divTags[m];
      var className = div.getAttribute("class");
      if (className && className.match(/Typography__Paragraph/)) {
        var newDiv = document.createElement("div");
        newDiv.innerHTML = div.innerHTML + "<p/>";
        div.parentNode.replaceChild(newDiv, div);
      }
    }

    var transformedPasteHTML = sanitizedPaste.body.innerHTML
      .replace(/<br *\/?>/g, "<p/>")
      .replace(/\[[0-9]+\](?!( *(<\/sup>)? *<\/a>))/g, "")
      .replace("\n\x3C!--StartFragment-->", "")
      .replace("<!--EndFragment-->\n\n", "");

    pasteContents(transformedPasteHTML, "html");
  } else {
    var pasteText = clipboardData?.getData("text/plain");
    if (pasteText) {
      pasteContents(pasteText);
    }
  }
}

// Make sure to bind the listener to the 'paste' event somewhere in your code
document.addEventListener("paste", PasteListener);
