import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AppButton } from "../../components/widget/button/Button";
import { TextField } from "../../components/widget/form/FormComponent";
import { connect } from "react-redux";
import { postAction } from "../../store/actions/creators/request.actions";
import { ADD_SUBSCRIBER } from "../../store/actions/action_types/action.types";
import { subscriberURL } from "../../utils/endpoint";

const AddSubscriber = ({ postAction, token, ...props }) => {
  const { formState, register, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue("subscriber_name", "");
    setValue("subscriber_email", "");
    setValue("subscriber_phone", "");
  }, []);

  const onSubmit = data => {
    console.log(data);
    postAction(ADD_SUBSCRIBER, subscriberURL, data, token);
  };
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex flex-col items-center justify-center bg-white rounded-lg h-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <header className="relative flex items-center justify-center flex-col rounded-tl-lg rounded-tr-lg mt-4 px-6 pt-4">
            <div className="flex flex-col items-center justify-center text-center px-2">
              <IoPersonCircleOutline className="text-[#D4D4D4] font-light" size={50} />
              <div className="font-bold text-xl md:text-3xl mb-1">Add Subscriber</div>
              <div className="text-black text-center text-opacity-50 text-sm">This subscriber will receive every Newsletter you send.</div>
            </div>
          </header>
          <main className="border-t border-[rgba(194, 207, 214, var(--tw-border-opacity))] py-3 border-opacity-0 max-h-[50vh] mt-[1.5rem] h-full w-full overflow-y-auto overflow-x-hidden transition-all">
            <div className="px-8">
              <TextField label="Name" name="subscriber_name" error={formState.errors["subscriber_name"]} {...register("subscriber_name", { required: true })} type="text" />
              <TextField label="Email" name="subscriber_email" error={formState.errors["subscriber_email"]} {...register("subscriber_email", { required: true })} type="email" />
              <TextField label="Phone" name="subscriber_phone" error={formState.errors["subscriber_phone"]} {...register("subscriber_phone", { required: true })} type="text" />
            </div>
          </main>
          <footer className="flex justify-end">
            <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex gap-2">
              <AppButton name="Save" type="submit" />
              <AppButton name="Cancel" className="bg-[#c2cfd64d] text-black text-opacity-80 px-4" />
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.newsletter.addSubscriber.loading,
  subscriber: state.newsletter.addSubscriber.data,
  error_msg: state.newsletter.addSubscriber.error_msg,
});

export default connect(mapStateToProps, { postAction })(AddSubscriber);
