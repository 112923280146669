import { useForm } from "react-hook-form";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import Expandable from "../../components/widget/Expandable/Expandable";
import { BarContent, MetadataContent } from "./Sites";
import { ProductGeneralContent as DirectorContent } from "./SiteProduct";
import { researchPaperGenURL, researchPaperSecURL, researchPaperURL, resourceAllMediaURL, resourceBlogGenURL, resourceBlogMetaURL, resourceMediaGenURL, resourceMediaMetaURL, resourceResearchPaperMetaURL } from "../../utils/endpoint";
import { connect } from "react-redux";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { AppButton } from "../../components/widget/button/Button";
import { SelectField, TextField } from "../../components/widget/form/FormComponent";
import { ADD_RES_ALL_MEDIA, ADD_RP, ADD_RPS } from "../../store/actions/action_types/action.types";

const mapStateToPropsAllMedia = state => ({
  token: state.auth.login.token,
  all_media_sending: state.site.resource.set_res_all_media.sending,
});
const AllMediaContent = connect(mapStateToPropsAllMedia, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  // let { job_data } = props.data;
  // job_data = job_data && job_data[job_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_RES_ALL_MEDIA, resourceAllMediaURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.all_media_sending} />
      </div>
      <div className="">
        <SelectField item={["MP4", "MP3"]} control={control} {...register("type", { required: false })} />
        <TextField label="Embed Code" type="text" {...register("embed_code", { required: false })} />
        <TextField label="Thumbnail URL" type="text" {...register("thumbnail_url", { required: false })} />
        <TextField label="Title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" type="textarea" {...register("description", { required: false })} />
      </div>
    </form>
  );
});

const mapStateToPropsResearchPaper = state => ({
  token: state.auth.login.token,
  rps_sending: state.site.resource.set_rps.sending,
  rp_sending: state.site.resource.set_rp.sending,
});

const ResearchPaperSection = connect(mapStateToPropsResearchPaper, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  // let { job_data } = props.data;
  // job_data = job_data && job_data[job_data.length - 1];

  const onSubmit = data => {
    props.postAction(ADD_RPS, researchPaperSecURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.rps_sending} />
      </div>
      <div className="">
        <TextField label="Title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" type="textarea" {...register("description", { required: false })} />
        <TextField label="Call To Action" type="text" {...register("call_to_action", { required: false })} />
        <TextField label="Number of Papers" control={control} type="number" {...register("number_of_papers", { required: false })} />
      </div>
    </form>
  );
});

const ResearchPaper = connect(mapStateToPropsResearchPaper, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  // let { job_data } = props.data;
  // job_data = job_data && job_data[job_data.length - 1];

  const onSubmit = data => {
    props.postAction(ADD_RP, researchPaperURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.rp_sending} />
      </div>
      <div className="">
        <TextField label="Paper Name" type="text" {...register("paper_name", { required: false })} />
        <TextField label="Paper URL" type="text" {...register("paper_url", { required: false })} />
      </div>
    </form>
  );
});

const ExpandableContent = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={resourceBlogMetaURL} />,
  },
  { name: "General", Component: props => <DirectorContent {...props} url={resourceBlogGenURL} /> },
];

const ExpandableContentMedia = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={resourceMediaMetaURL} />,
  },
  { name: "General", Component: props => <DirectorContent {...props} url={resourceMediaGenURL} /> },
  { name: "All Media", Component: props => <AllMediaContent {...props} /> },
];

const ExpandableContentResearch = [
  // {
  //   name: "Metadata (SEO)",
  //   Component: props => <MetadataContent {...props} />,
  // },
  // { name: "General", Component: props => <DirectorContent {...props} /> },
  { name: "Research Papers Section", Component: props => <ResearchPaperSection {...props} /> },
  { name: "Research Papers", Component: props => <ResearchPaper {...props} /> },
];

const ExpandableContentResearchPaper = [
  {
    name: "Metadata (SEO)",
    Component: props => <MetadataContent {...props} url={resourceResearchPaperMetaURL} />,
  },
  { name: "General", Component: props => <DirectorContent {...props} url={researchPaperGenURL} /> },
];

const Resources = props => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const submitForm = data => {
    alert(JSON.stringify(data));
  };

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent type="res" name={["Blog", "Media", "Research"]} />} />
      <div className="">
        <h3 className="mt-[3rem] mb-[0.75rem] text-[1.125rem] leading-[1.75rem] font-medium text-opacity-[0.7] text-gray-600">Blog Page</h3>
        {ExpandableContent.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
        ))}
      </div>
      <div className="">
        <h3 className="mt-[3rem] mb-[0.75rem] text-[1.125rem] leading-[1.75rem] font-medium text-opacity-[0.7] text-gray-600">Media Page</h3>
        {ExpandableContentMedia.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
        ))}
      </div>
      <div className="">
        <h3 className="mt-[3rem] mb-[0.75rem] text-[1.125rem] leading-[1.75rem] font-medium text-opacity-[0.7] text-gray-600">Research Page</h3>
        {ExpandableContentResearch.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
        ))}
      </div>
      <div className="">
        <h3 className="mt-[3rem] mb-[0.75rem] text-[1.125rem] leading-[1.75rem] font-medium text-opacity-[0.7] text-gray-600">Research Paper Page</h3>
        {ExpandableContentResearchPaper.map(({ Component, ...content }) => (
          <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} />
        ))}
      </div>
    </div>
  );
};
export default Resources;
