import React, { useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import {
  GET_JOB_APPLICATION,
  GET_NEWSLETTER,
} from "../../store/actions/action_types/action.types";
import { getAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { newsletterURL } from "../../utils/endpoint";

const ViewNewsletter = ({ id, getAction, data, openModal, ...props }) => {
  useEffect(() => {
    console.log(id);
    const URL = `${newsletterURL}/${id}`;
    getAction(GET_NEWSLETTER, URL, props.token);
  }, [getAction, id, props.token]);

  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]);

  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline
            onClick={() => openModal("CLOSE_MODAL")}
            className="absolute top-2 cursor-pointer right-2 text-2xl"
          />
          <h1 className="mt-1 font-bold text-xl md:text-3xl mb-1">
            View Applicant
          </h1>
        </header>
        <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
          <div className="w-full px-6">
            <h5 className="text-[#AE1931]">Title</h5>
            <p>{data?.subject}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Body</h5>
            <p>{data?.body}</p>
          </div>
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Scheduled</h5>
            <p>{data?.schedule_time ? "Yes" : "No"}</p>
          </div>
          {data?.schedule_time && (
            <div className="w-full mt-4 px-6">
              <h5 className="text-[#AE1931]">Scheduled</h5>
              <p>{data?.schedule_time}</p>
            </div>
          )}
          <div className="w-full mt-4 px-6">
            <h5 className="text-[#AE1931]">Draft</h5>
            <p>{data?.save_as_draft ? "Yes" : "No"}</p>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  data: state.newsletter.newsLetterDetails.data?.data,
  loading: state.newsletter.newsLetterDetails.loading,
  error_msg: state.newsletter.newsLetterDetails.error_msg,
});

export default connect(mapStateToProps, { getAction, openModal })(
  ViewNewsletter
);
