import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../api/request.api";
import { jobURL } from "../utils/endpoint";

export const jobApi = createApi({
  reducerPath: "jobApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseURL}` }),
  tagTypes: ["Jobs", "Job"],
  endpoints: builder => ({
    getJobs: builder.query({
      query: () => `${jobURL}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Jobs"],
    }),
    getJob: builder.query({
      query: id => `${jobURL}/${id}`,
      transformResponse: responseData => {
        return responseData?.data;
      },
      providesTags: ["Job"],
    }),
    updateJob: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${jobURL}/${id}`,
        method: "PATCH",
        body: patch,
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["Jobs"],
    }),
    deleteJob: builder.mutation({
      query: id => ({
        url: `${jobURL}/${id}`,
        method: "DELETE",
      }),
      transformResponse: responseData => {
        return responseData?.data;
      },
      invalidatesTags: ["Jobs"],
    }),
  }),
});

export const { useGetJobsQuery, useGetJobQuery, useUpdateJobMutation, useDeleteJobMutation } = jobApi;

export const { getJob, getJobs, updateJob, deleteJob } = jobApi.endpoints;
