import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
};

const addMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${type.SENDING}_${type.ADD_MEDIA}`:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_MEDIA:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${type.LOADING}_${type.GET_MEDIA}`:
      return {
        ...state,
        loading: true,
      };
    case type.GET_MEDIA:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.success,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

export const mediaReducer = combineReducers({
  get_media: getMediaReducer,
  add_media: addMediaReducer,
});
