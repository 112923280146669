import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { MainPageTopPanel } from "../../components/topBar/TopBar";
import { AppButton } from "../../components/widget/button/Button";
import Expandable from "../../components/widget/Expandable/Expandable";
import { FileImageModalOpener, RadioFieldOne, TextField } from "../../components/widget/form/FormComponent";
import { careerGeneralURL, careerIntroURL, careerMetaURL, careerPageContentURL, jobOpeningURL, siteMetaURL } from "../../utils/endpoint";
import { mapStateToPropsCareer } from "../../utils/helpers";
import { BarContent, MetadataContent } from "./Sites";
import { postAction, getAction } from "../../store/actions/creators/request.actions";
import { ADD_CAREER_GENERAL, ADD_CAREER_INTRO, ADD_CAREER_PAGECONTENT, ADD_JOB_OPEN, GET_CAREER_GENERAL, GET_CAREER_INTRO, GET_CAREER_PAGECONTENT, GET_JOB_OPEN, GET_SITE_META } from "../../store/actions/action_types/action.types";
import { useEffect } from "react";

const CareerGeneral = connect(mapStateToPropsCareer, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { career_data } = props.data;
  career_data = career_data && career_data[career_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_CAREER_GENERAL, careerGeneralURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex justify-end items-center mb-2">
          <AppButton name="save" type="submit" loading={props.sending_career_gen} />
        </div>
        <TextField label="Title" defaultValue={career_data?.title} name="title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={career_data?.description} name="description" type="textarea" {...register("description", { required: false })} />
        <TextField label="Call To Action" defaultValue={career_data?.call_to_action} type="text" {...register("call_to_action", { required: false })} />
      </div>
    </form>
  );
});

const CareerIntro = connect(mapStateToPropsCareer, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { file_name, intro_data } = props.data;
  intro_data = intro_data && intro_data[intro_data.length - 1];

  const onSubmit = data => {
    data.video_thumbnail_url = file_name["intro_thumbnail_img"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_CAREER_INTRO, careerIntroURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_career_intro} />
      </div>
      <div className="">
        <TextField label="Title" defaultValue={intro_data?.title} name="title" type="text" {...register("title", { required: false })} />
        <TextField label="Description" defaultValue={intro_data?.description} name="description" type="textarea" {...register("description", { required: false })} />
        <RadioFieldOne control={control} name="media_type" data={["embed", "url"]} label="Media Type" />
        <TextField label="Embed Code / Video URL" defaultValue={intro_data?.embed_code_video_url} name="embed_code_video_url" type="textarea" {...register("embed_code_video_url", { required: false })} />
        <FileImageModalOpener label="Video Thumbnail" defaultValue={intro_data?.video_thumbnail_url} control={control} name="intro_thumbnail_img" {...register("intro_thumbnail_img", { required: false })} />
      </div>
    </form>
  );
});

const CareerPageContent = connect(mapStateToPropsCareer, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { file_name, page_content_data } = props.data;
  page_content_data = page_content_data && page_content_data[page_content_data.length - 1];

  const onSubmit = data => {
    data.image_url = file_name["img_url"]?.image_url;
    // alert(JSON.stringify(data));
    props.postAction(ADD_CAREER_PAGECONTENT, careerPageContentURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" loading={props.sending_career_content} type="submit" />
      </div>
      <div className="">
        <section className="mb-4">
          <h3 className="text-[1.125rem] leading-[1.75rem] font-light">Item</h3>
          <FileImageModalOpener control={control} defaultValue={page_content_data?.image_url} label="Image" {...register(`img_url`, { required: false })} />
          <TextField label="Title" defaultValue={page_content_data?.title} type="text" {...register(`title`, { required: false })} />
          <TextField label="Description" defaultValue={page_content_data?.description} name={`description`} type="textarea" {...register(`description`, { required: false })} />
        </section>
      </div>
    </form>
  );
});

const CareerJobOpening = connect(mapStateToPropsCareer, { postAction })(({ checkKeyDown, ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  let { job_data } = props.data;
  job_data = job_data && job_data[job_data.length - 1];

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    props.postAction(ADD_JOB_OPEN, jobOpeningURL, data, props.token);
  };

  return (
    <form onKeyDown={e => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-end items-center mb-2">
        <AppButton name="save" type="submit" loading={props.sending_job_open} />
      </div>
      <div className="">
        <TextField label="Title" defaultValue={job_data?.title} name="title" type="text" {...register("title", { required: false })} />
        <TextField label="No Vacancy Message" defaultValue={job_data?.no_vacancy_message} name="no_vacancy_message" type="textarea" {...register("no_vacancy_message", { required: false })} />
      </div>
    </form>
  );
});

const SiteCareer = ({ token, ...props }) => {
  const { handleSubmit, ...formData } = useForm({
    mode: "onChange",
  });

  const ExpandableContent = [
    {
      name: "Metadata (SEO)",
      Component: props => <MetadataContent {...props} url={careerMetaURL} />,
    },
    {
      name: "General",
      Component: props => <CareerGeneral {...props} />,
    },
    { name: "Intro", Component: props => <CareerIntro {...props} /> },
    {
      name: "Page Content",
      Component: props => <CareerPageContent {...props} />,
    },
    {
      name: "Job Openings",
      Component: props => <CareerJobOpening {...props} />,
      hasToggle: true,
      hasCount: props.job_data?.length,
    },
  ];

  useEffect(() => {
    props.getAction(GET_SITE_META, careerMetaURL, token);
    props.getAction(GET_CAREER_GENERAL, careerGeneralURL, token);
    props.getAction(GET_CAREER_INTRO, careerIntroURL, token);
    props.getAction(GET_CAREER_PAGECONTENT, careerPageContentURL, token);
    props.getAction(GET_JOB_OPEN, jobOpeningURL, token);
  }, []);

  const checkKeyDown = e => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div className="">
      <MainPageTopPanel btnName="Publish" content={<BarContent name="Careers" />} />
      {ExpandableContent.map(({ Component, ...content }) => (
        <Expandable {...content} component={<Component data={props} checkKeyDown={checkKeyDown} />} showToggle={content.hasToggle} hasCount={content.hasCount} modalcontent={content.modalcontent} hasPlus={content.hasPlus} loading={props.meta_loading} />
      ))}
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.login.token,
  meta_data: state.site.main.get_site_meta.data,
  meta_loading: state.site.main.get_site_meta.loading,
  header_data: state.site.main.get_header.data,
  file_name: state.ui.input_file,
  file_input_name: state.ui.input_file?.name,
  career_data: state.site.career.get_career_gen.data,
  intro_data: state.site.career.get_career_intro.data,
  page_content_data: state.site.career.get_career_content.data,
  job_data: state.site.career.get_job_open.data,
});
export default connect(mapStateToProps, { getAction })(SiteCareer);
