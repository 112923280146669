import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoCloseOutline, IoLockClosedOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../components/widget/button/Button";
import { PasswordField } from "../../components/widget/form/FormComponent";
import { postAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { useResetPasswordMutation } from "../../services/auth.services";
import { toast } from "sonner";

const ChangePassword = ({ email, openModal }) => {
  const { formState, register, setValue, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [resetPassword, { isLoading, error }] = useResetPasswordMutation();
  // let navigate = useNavigate();

  useEffect(() => {
    setValue("email", email);
  });

  useEffect(() => {
    if (error) {
      toast.error(error.data?.message);
    }
  }, [error]);

  const submitForm = async data => {
    data.email = email;
    // console.log({ email });
    // console.log(JSON.stringify(data));
    // postAction(RESET, resetURL, data);
    await resetPassword(data);
    openModal("CLOSE_MSG_MODAL");
    // console.log(response);
    setValue("");
  };
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <IoLockClosedOutline className="text-[70px] text-[#b2b3b3] mt-[40px]" />
          <h1 className="mt-1 font-bold text-xl md:text-3xl mb-1">Change Password</h1>
          <p className="text-center text-[#b2b3b3] ">Your account currently uses default password. You must change your password before you continue</p>
        </header>
        <main className="border-t border-b w-full border-grey py-3 border-opacity-0">
          <form onSubmit={handleSubmit(submitForm)} className="mb-6 px-8 mt-3">
            <div className="mb-4">
              <PasswordField label="Your Old Password" error={formState.errors.old_password} my="mb-6" {...register("old_password", { required: true })} />
              <PasswordField label="Your New Password" error={formState.errors.new_password} {...register("new_password", { required: true })} />
            </div>
            <AppButton name="Update" type="submit" className="bg-[#AE1931] py-3 mb-4 rounded-lg text-white px-4 w-full" loading={isLoading} />
          </form>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  success: state.auth.reset.succesFlag,
});

export default connect(mapStateToProps, { postAction, openModal })(ChangePassword);
