import { toast } from "sonner";

export const baseURL = "https://site.housemoni.net"; // suppose to come from the env file
const IP_ADD_KEY = "lQmYqrLLVX1rrNySCMkmCKFkr11UkUtiK1zOXyQsCJJKM4MZpf";

export const makeRequest = async (url, data = null, token = null, method_type = null) => {
  const response = await fetch(`${baseURL}${url}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: method_type != null ? method_type : data == null ? "GET" : "POST",
    body: data == null ? null : JSON.stringify(data),
  });

  if (response.ok) {
    const data = await response.json();
    // console.log(data);
    return { data };
  } else {
    const { response_description, message } = await response.json();
    // alert(response_description);
    toast.error(
      message ||
        message.map(content => (
          <ul className="">
            <li>{content}</li>
          </ul>
        ))
    );
    throw new Error(response_description);
  }
};

export const ipRequest = async url => {
  alert(url);
  // const response = await axios.get(`${url}/?key=${IP_ADD_KEY}`)
  // const response = await fetch(`${url}/?key=${IP_ADD_KEY}`);
  // if (response.ok) {
  //   const data = await response.json();
  //   return { data };
  // } else {
  //   const error = await response.json();
  //   throw new Error(error);
  // }
};

export const fileUpload = async (url = null, data, token = null) => {
  const response = await fetch("https://api.cloudinary.com/v1_1/dbkiemdqb/image/upload", {
    method: "POST",
    body: data,
  });

  if (response.ok) {
    const data = await response.json();
    return { data };
  } else {
    const error = await response.json();
    throw new Error(error);
  }
};
