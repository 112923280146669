import { Disclosure, Tab } from '@headlessui/react';
import React, { Fragment } from 'react';
import { HiChevronRight } from 'react-icons/hi';
import { IoEye, IoLockClosed, IoPencilSharp } from 'react-icons/io5';
import MyTable from '../../components/widget/table/myTable';

const tableInfo = [
	{
		id: 1,
		name: 'Aliu',
		user: 'asalaudeen',
		role: 'Administrator',
		created: '31/10/2022',
	},
	{
		id: 2,
		name: 'Aliu',
		user: 'asalaudeen',
		role: 'Administrator',
		created: '31/9/2022',
	},
	{
		id: 3,
		name: 'Aliu',
		user: 'asalaudeen',
		role: 'Administrator',
		created: '21/9/2022',
	},
	{
		id: 4,
		name: 'Aliu',
		user: 'asalaudeen',
		role: 'Administrator',
		created: '21/10/2022',
	},
];

const userTableHeaders = [
	{ title: 'id', render: (row) => `${row.id}` },
	{ title: 'First Name', render: (row) => `${row.name}` },
	{ title: 'Last Name', render: (row) => `${row.user}` },
	{ title: 'Email', render: (row) => `${row.role}` },
	{
		title: 'Created At',
		render: (row) => `${row.role}`,
	},
	{
		title: 'Action',
		render: (row) => (
			<div className='flex justify-center items-center rounded-lg border border-gray-400'>
				<button className='pr-3'>
					<IoEye size={20} />
				</button>
				<button className='px-3 py-2 border-x border-gray-400'>
					<IoPencilSharp size={20} />
				</button>
				<button className='pl-3'></button>
				<IoLockClosed size={20} />
			</div>
		),
	},
];

const Roles = ({ ...props }) => {
	return (
		<div>
			<section className='rounded-lg bg-white overflow-hidden w-full xl:w-4/5'>
				<Disclosure>
					{({ open }) => (
						/* Use the `open` state to conditionally change the direction of an icon. */
						<div className='bg-white border-b'>
							<Disclosure.Button className='flex items-center w-full justify-between px-6 py-4 cursor-pointer'>
								<h2 className='text-lg md:text-xl font-normal capitalize flex Left'>
									All Roles
								</h2>
								<HiChevronRight className={open ? 'rotate-90 transform' : ''} />
							</Disclosure.Button>
							<Disclosure.Panel className={'px-6 py-0'}>
								<MyTable rows={tableInfo} headers={userTableHeaders} />
							</Disclosure.Panel>
						</div>
					)}
				</Disclosure>
			</section>
		</div>
	);
};

export default Roles;
