import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { AppButton } from "../../components/widget/button/Button";
import { DELETE_JOB_POSITION } from "../../store/actions/action_types/action.types";
import { deleteAction } from "../../store/actions/creators/request.actions";
import { openModal } from "../../store/actions/creators/ui.actions";
import { positionURL } from "../../utils/endpoint";

const DeletePosition = ({ id, deleteAction, openModal, ...props }) => {
  const DeletePosition = () => {
    const URL = `${positionURL}/${id}`;
    deleteAction(DELETE_JOB_POSITION, URL, props.token);
  };
  return (
    <div className="relative flex items-center justify-center transition-all duration-300 p-4">
      <div className="max-w-[95vw] w-[24rem] relative flex overflow-hidden flex-col items-center justify-center bg-white rounded-lg h-auto">
        <header className="relative flex items-center gap-2 w-full flex-col py-4 px-6">
          <IoCloseOutline onClick={() => openModal("CLOSE_MODAL")} className="absolute top-2 cursor-pointer right-2 text-2xl" />
          <h1 className="mt-1 font-bold text-xl md:text-2xl mb-1">Are you sure you want to delete ?</h1>
        </header>
        <main className="border-t border-b w-full border-grey p-3 border-opacity-0">
          <div className="flex justify-between">
            <AppButton name={"Cancel"} onPress={() => openModal("CLOSE_MODAL")} className="bg-[#cccccc] text-white px-4" />
            <AppButton name={"Delete"} onPress={DeletePosition} type="submit" />
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  data: state.jobs.jobPositionDetails.data.data,
  loading: state.jobs.jobPositionDetails.loading,
  error_msg: state.jobs.jobPositionDetails.error_msg,
});

export default connect(mapStateToProps, { deleteAction, openModal })(DeletePosition);
