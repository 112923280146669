import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ADD_NOTIFICATION, GET_NOTIFICATION } from "../../store/actions/action_types/action.types";
import { getAction, postAction } from "../../store/actions/creators/request.actions";
import { notificationURL } from "../../utils/endpoint";
import { AppButton } from "../widget/button/Button";
import { TextField, Toggler } from "../widget/form/FormComponent";

const NotificationComponent = ({ token, getAction, postAction, ...props }) => {
  const { formState, register, handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    getAction(GET_NOTIFICATION, notificationURL, token);
  }, [getAction, token]);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setValue("receive_notifications", props.data[0].receive_notifications);
      setValue("general_notification_email", props.data[0].general_notification_email);
      setValue("job_notification_email", props.data[0].job_notification_email);
      setValue("newsletter_notifications_email", props.data[0].newsletter_notifications_email);
    }
  }, [props.data, setValue]);

  const submitHandler = data => {
    // data.hotline = "+2349099976980";
    console.log(data);
    postAction(ADD_NOTIFICATION, notificationURL, data, token);
  };
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="mb-8">
        <label className="text-black font-light text-opacity-80">Receive Notifications</label>
        <Toggler name={"receive_notifications"} label={["Yes", "No"]} control={control} />
      </div>
      <TextField
        label="General Notifications (Separate emails using ;)"
        name="general_notification_email"
        placeholder="General Notification emails"
        error={formState.errors["general_notification_email"]}
        {...register("general_notification_email", { required: true })}
        type="text"
      />
      <TextField
        label="Job Notifications (Separate emails using ;)"
        name="job_notification_email"
        placeholder="Job Notification emails"
        error={formState.errors["job_notification_email"]}
        {...register("job_notification_email", { required: true })}
        type="text"
      />
      <TextField
        label="Newsletter Notifications (Separate emails using ;)"
        name="newsletter_notifications_email"
        placeholder="Newsletter Notification emails"
        error={formState.errors["newsletter_notifications_email"]}
        {...register("newsletter_notifications_email", { required: true })}
        type="text"
      />
      <div className="flex justify-end pt-5">
        <AppButton loading={props.loading} name={`Save`} type="submit" />
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.settings.addNotificationLinks.loading,
  data: state.settings.notificationLinks.data,
  postData: state.settings.addNotificationLinks.data,
  error_msg: state.settings.addNotificationLinks.error_msg,
  fetch_error_msg: state.settings.notificationLinks.error_msg,
});

export default connect(mapStateToProps, { getAction, postAction })(NotificationComponent);
