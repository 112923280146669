import { DateTime } from "luxon";
import { formatDate } from "../../utils/helpers";

export const processData = (data, idx) => ({
  "#": idx + 1,
  name: data.category_name,
  "Created On": DateTime.fromISO(data.createdAt).toLocaleString(DateTime.DATE_FULL),
});

export const postProcessData = (data, idx) => ({
  "#": idx + 1,
  title: data.title,
  id: data.id,
  category: data.category.category_name,
  author: data.author,
  created: formatDate(data.createdAt),
});
