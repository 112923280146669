import styled from 'styled-components';

export const SettingWrapper = styled.section`
	.pageOption {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 0.5rem;
		--tw-bg-opacity: 1;
		background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		--tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
		box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
			var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	}
`;
