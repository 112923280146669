import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/widget/button/Button";
import { TextField, Toggler } from "../../components/widget/form/FormComponent";
import { postAction } from "../../store/actions/creators/request.actions";
import { ADD_NEWSLETTER } from "../../store/actions/action_types/action.types";
import { newsletterURL } from "../../utils/endpoint";

const DraftNewsletter = ({ newsletter, token, postAction, ...props }) => {
  const { handleSubmit, formState, register, control } = useForm({
    mode: "onChange",
    defaultValues: { publish_later: false, save_as_draft: false },
  });

  let navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [toggle, setToggle] = useState(false);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  const onSubmit = async data => {
    // if (data.publish_later === undefined) {
    //   data.publish_later = false;
    // }
    // if (data.save_as_draft === undefined) {
    //   data.save_as_draft = false;
    // }

    // alert(JSON.stringify(data.publish_later));

    if (data.publish_later === false) {
      console.log({
        body: data.body,
        subject: data.subject,
        publish_later: data.publish_later,
        save_as_draft: data.save_as_draft,
      });

      postAction(ADD_NEWSLETTER, newsletterURL, {
        body: data.body,
        subject: data.subject,
        publish_later: data.publish_later,
        save_as_draft: data.save_as_draft,
      });
    }
    //  else if (data.publish_later === false && data.save_as_draft === true) {
    //   console.log({
    //     body: data.body,
    //     subject: data.subject,
    //     publish_later: data.publish_later,
    //     save_as_draft: data.save_as_draft,
    //   });
    //   postAction(ADD_NEWSLETTER, newsletterURL, {
    //     body: data.body,
    //     subject: data.subject,
    //     publish_later: data.publish_later,
    //     save_as_draft: data.save_as_draft,
    //   });
    // }
    else {
      // if (
      //   data.schedule_date !== undefined &&
      //   data.schedule_time !== undefined
      // ) {
      //   data.schedule_date = new Date(`${data.schedule_date}`).toISOString();
      //   data.schedule_time = new Date(`${data.schedule_date}`).toISOString();
      // }
      console.log(dateIsValid(`2023-02-25 12:00 UTC`));
      console.log(`${data.schedule_date} ${data.schedule_time} UTC`);
      // console.log(`${data.schedule_date} ${data.schedule_time} UTC`);

      if (dateIsValid(`${data.schedule_date} ${data.schedule_time} UTC`)) {
        data.schedule_date = new Date(`${data.schedule_date} ${data.schedule_time} UTC`).toISOString();
        data.schedule_time = data.schedule_date;
        console.log(data);
      }
      // // data.schedule_date = new Date(`${data.schedule_date} ${data.schedule_time} UTC`).toISOString();
      // // data.schedule_time = data.schedule_date;
      // // console.log(new Date(`${data.schedule_date}`).toISOString());
      console.log(data);
      postAction(ADD_NEWSLETTER, newsletterURL, data);
    }
  };

  // console.log(props.loading);
  // console.log(props.toggle_input);

  return (
    <div className="mt-10">
      <div onClick={() => navigate("/newsletters")} className="flex gap-1 cursor-pointer items-center">
        <IoChevronBackOutline className="text-secondary" />
        <span className="text-secondary">Back</span>
      </div>
      <h1 className="subTitle">New Newsletter</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
        <TextField label="Subject" name="subject" error={formState.errors.subject} {...register("subject", { required: true })} type="text" />
        <TextField label="Body" name="body" error={formState.errors["body"]} {...register("body", { required: true })} type="textarea" />
        <div className="flex gap-6">
          <Toggler name={"save_as_draft"} label={["Save As Draft"]} control={control} disabled={props.toggle_input[0] === "publish_later"} />
          <Toggler name={"publish_later"} label={["Send Later"]} control={control} disabled={props.toggle_input[0] === "save_as_draft"} />
        </div>

        {/* {toggle && (
          
        )} */}
        <div className="grid grid-cols-2 mt-4 gap-8">
          <TextField label="Schedule Date*" name="schedule_date" error={formState.errors.schedule_date} {...register("schedule_date", { required: false })} type="date" />
          <TextField label="Schedule Time*" name="schedule_time" error={formState.errors.schedule_time} {...register("schedule_time", { required: false })} type="time" />
        </div>
        <div className="flex justify-end pt-10">
          <AppButton loading={props.loading} name={`${enabled ? "Save As Draft" : toggle ? "Send Later" : "Send Now"}`} type="submit" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  toggle_input: state.ui.toggler?.toggle_input || [],
  loading: state.newsletter.newsLetterDetails.loading,
  newsletter: state.newsletter.newsLetterDetails.data,
  error_msg: state.newsletter.newsLetterDetails.error_msg,
});

export default connect(mapStateToProps, { postAction })(DraftNewsletter);
